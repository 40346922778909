import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { $fetch } from 'ohmyfetch';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

const ScryfallContext = createContext(null);

export const ScryfallProvider = ({ children }) => {
  const router = useRouter();
  const searchQuery = router.query.s ? router.query.s : '';
  const pageQuery = router.query.page ? parseInt(router.query.page) : 1;
  const orderQuery = router.query.sortBy ? router.query.sortBy : 'name';
  const dirQuery = router.query.dir ? router.query.dir : 'asc';
  const [dataLoading, setDataLoading] = useState(true);
  const [sets, setSets] = useState({});
  const [tokens, setTokens] = useState({});
  const [emblems, setEmblems] = useState({});
  const [cards, setCards] = useState({});

  const getSets = async (page, orderBy, dir, search) => {
    try {
      setDataLoading(true);
      const response = await $fetch('/api/scryfall/sets');
      setSets(response);
    } catch (error) {
      setSets({});
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  const getTokens = async (page, orderBy, dir, search, language) => {
    try {
      setDataLoading(true);
      const response = await $fetch('/api/scryfall/tokens', { params: { page, orderBy, dir, search, language } });
      setTokens(response);
    } catch (error) {
      setTokens({});
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  const getEmblems = async (orderBy, dir, search, language) => {
    try {
      setDataLoading(true);
      const response = await $fetch('/api/scryfall/emblems', { params: { orderBy, dir, search, language } });
      setEmblems(response);
    } catch (error) {
      setEmblems({});
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  // const getSets = async () => {
  //   setDataLoading(true);
  //   const response = await $fetch('/api/scryfall/sets');
  //   setSets(response);
  //   setDataLoading(false);
  // };

  // const getCardsFromSet = async (set) => {
  //   try {
  //     setDataLoading(true);
  //     const response = await $fetch('/api/scryfall/sets', { params: { set } });
  //     setCards(response);
  //   } catch (error) {
  //     setCards({});
  //     console.error(error);
  //   } finally {
  //     setDataLoading(false);
  //   }
  // };

  const getCardsFromSearch = async (page, orderBy, dir, search, language) => {
    try {
      setDataLoading(true);
      const response = await $fetch('/api/scryfall/search', {
        params: {
          page,
          orderBy,
          dir,
          search,
          language,
        },
      });
      setCards(response);
    } catch (error) {
      setCards({});
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceGetTokens = useCallback(debounce(getTokens, 1000), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceGetEmblems = useCallback(debounce(getEmblems, 1000), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceGetCards = useCallback(debounce(getCardsFromSearch, 1000), []);

  useEffect(() => {
    getSets();
    getTokens(pageQuery, orderQuery, dirQuery, searchQuery, router.query.language);
    getEmblems(orderQuery, dirQuery, searchQuery, router.query.language);

    return () => {
      setDataLoading(true);
      setCards({});
      setTokens({});
      setEmblems({});
    };
  }, []);

  useEffect(() => {
    if (router.pathname === '/tokens') {
      debounceGetTokens(pageQuery, orderQuery, dirQuery, searchQuery, router.query.language);
    }
    if (router.pathname === '/emblems') {
      debounceGetEmblems(orderQuery, dirQuery, searchQuery, router.query.language);
    }
    if (router.pathname === '/search') {
      debounceGetCards(pageQuery, orderQuery, dirQuery, searchQuery, router.query.language);
    }
  }, [pageQuery, orderQuery, dirQuery, searchQuery, router.query.language]);

  return (
    <ScryfallContext.Provider
      value={{
        getTokens,
        sets,
        cards,
        tokens,
        emblems,
        dataLoading,
      }}
    >
      {children}
    </ScryfallContext.Provider>
  );
};

export const useScryfall = () => useContext(ScryfallContext);
