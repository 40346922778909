const translations = {
  common: {
    search: 'Search',
    signIn: 'Sign in',
    register: 'Register',
    myAccount: 'My Account',
    support: 'Support',
    followUs: 'Follow us on X/Twitter',
    proMembership: 'Pro Membership',
    new: 'New',
    proMember: 'Pro Member',
    upgrade: 'Upgrade',
    subscribe: 'Subscribe',
    manage: 'Manage',
    cancel: 'Cancel',
    close: 'Close',
    or: 'Or',
    email: 'Email address',
    continue: 'Continue',
    submit: 'Submit',
    billing: 'Billing',
    sort: 'Sort',
    language: 'Language',
    selectLanguage: 'Select Language',
    searchPlaceholder: 'Search...',
    cards: 'Cards',
    previous: 'Previous',
    next: 'Next',
    pagination: 'Pagination',
    back: 'Back',
    backToSets: 'Back to Sets',
    error: 'An error occurred. Please try again.',
    home: 'Home',
    sets: 'Sets',
  },
  languages: {
    default: 'Default',
    any: 'Any',
    english: 'English',
    spanish: 'Spanish',
    french: 'French',
    german: 'German',
    italian: 'Italian',
    portuguese: 'Portuguese',
    japanese: 'Japanese',
    korean: 'Korean',
    russian: 'Russian',
    simplified_chinese: 'Simplified Chinese',
    traditional_chinese: 'Traditional Chinese',
    hebrew: 'Hebrew',
    latin: 'Latin',
    ancient_greek: 'Ancient Greek',
    arabic: 'Arabic',
    sanskrit: 'Sanskrit',
    phyrexian: 'Phyrexian',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Show Pro Filters',
    hide: '✨ Hide Pro Filters',
  },
  search: {
    showing_start: 'Showing',
    showing_to: 'to',
    showing_of: 'of',
    showing_results: 'results',
    noResults: 'No results found.',
    useSearchTip: 'Use the search at the top of the page to look up a card.',
  },
  sort: {
    nameAsc: 'Name (A to Z)',
    nameDesc: 'Name (Z to A)',
    cmcAsc: 'Mana Value (Low to High)',
    cmcDesc: 'Mana Value (High to Low)',
    usdAsc: 'Price (Low to High)',
    usdDesc: 'Price (High to Low)',
    rarityAsc: 'Rarity (Common to Mythic)',
    rarityDesc: 'Rarity (Mythic to Common)',
    releasedDesc: 'Release Date (Newest First)',
    releasedAsc: 'Release Date (Oldest First)',
  },
  pages: {
    search: {
      heading: 'Search',
    },
    sets: {
      heading: 'Card Sets',
      latestSets: 'Latest Sets',
      upcomingSets: 'Upcoming Sets',
      allSets: 'All Sets',
      noResults: 'No results found.',
    },
    set: {
      notFound: 'Set not found.',
      noCards: 'No cards found in this set.',
      addAll: 'Add {{count}} Cards to Print List',
      addedToList: 'Added {{count}} cards to Print List',
    },
    tokens: {
      heading: 'Tokens',
    },
    emblems: {
      heading: 'Emblems',
    },
    import: {
      heading: 'Import cards to Print List',
      description: 'Input the names of all the cards you want to add to the Print List.',
    },
    topProxies: {
      heading: 'Top Proxies',
      description: 'Here are the top 50 most popular Magic: The Gathering proxy cards on MTG Proxies.',
    },
  },
  theme: {
    light: 'Light',
    dark: 'Dark',
    toggle: 'Toggle theme',
  },
  cta: {
    title: 'Ready to dive in?',
    subtitle: 'Start proxying cards today.',
    searchByCard: 'Search by card',
    searchBySet: 'Search by set',
  },
  nav: {
    proxyCards: 'Proxy Cards',
    sets: 'Sets',
    tokens: 'Tokens',
    emblems: 'Emblems',
    import: 'Import',
    topProxies: 'Top Proxies',
    customCards: 'Custom Cards',
    deckBuilder: 'Deck Builder',
    buildDeck: 'Build a Deck',
    publicDecks: 'Public Decks',
    favoriteDeck: 'My Favorite Decks',
    articles: 'Articles',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Proxy Magic: The Gathering cards',
      description: 'Access a vast library of Magic: The Gathering proxy cards. Easily view and print MTG card images for casual play. Start building the deck of your dreams now!',
    },
    sets: {
      title: 'Card Sets - MTG Proxies',
      description: 'Browse through every MTG card set and print proxies for your deck.',
    },
    setDetail: {
      title: '{{setName}} - MTG Proxies',
      description: 'Browse through all the cards in the set and print proxies for your deck.',
    },
    tokens: {
      title: 'Tokens - MTG Proxies',
      description: 'Enhance your MTG games with a variety of proxy tokens.',
    },
    emblems: {
      title: 'Emblems - MTG Proxies',
      description: 'Print MTG Emblem proxy cards for your deck.',
    },
    search: {
      title: 'Search MTG Cards - MTG Proxies',
      description: 'Effortlessly find and print MTG proxy cards. Use our search tool to access an extensive database and enhance your Magic: The Gathering deck with proxies.',
    },
    support: {
      title: 'Support - MTG Proxies',
      description: 'If you have any questions, feedback, or want a token/emblem added, please send us an email.',
    },
    articles: {
      title: 'Articles - MTG Proxies',
      description: 'Articles written about Magic: The Gathering and proxy cards.',
    },
    import: {
      title: 'Import - MTG Proxies',
      description: 'Import a list of cards to print your Magic: The Gathering proxies.',
    },
    topProxies: {
      title: 'Top Proxies - MTG Proxies',
      description: 'View the most popular Magic: The Gathering proxy cards.',
    },
    customCards: {
      title: 'Custom Cards - MTG Proxies',
      description: 'Create your own custom Magic: The Gathering proxy cards.',
    },
    login: {
      title: 'Sign in - MTG Proxies',
      description: 'Sign in to your account to manage your custom cards, deck lists, and more.',
    },
    register: {
      title: 'Register - MTG Proxies',
      description: 'Register for an account to manage your custom cards, deck lists, and more.',
    },
    termsOfService: {
      title: 'Terms of Service - MTG Proxies',
      description: 'Read the terms of service for the site.',
    },
    privacyPolicy: {
      title: 'Privacy Policy - MTG Proxies',
      description: 'Read the privacy policy for the site.',
    },
    account: {
      title: 'Account - MTG Proxies',
      description: 'Manage your account.',
    },
    proMembership: {
      title: 'Pro Membership - MTG Proxies',
      description: 'Subscribe to the Pro Membership to get access to all of the features of the site.',
    },
    deckBuilder: {
      title: 'Deck Builder - MTG Proxies',
      description: 'Create and manage your Magic: The Gathering decks to easily print proxies.',
    },
    deckEditor: {
      title: 'Deck Editor - MTG Proxies',
      description: 'Create and edit your Magic: The Gathering decks to easily print proxies.',
    },
    publicDecks: {
      title: 'Public Decks - MTG Proxies',
      description: 'Browse and explore public MTG decks shared by the community.',
    },
  },
  pro: {
    pricing: {
      title: 'Unlock Pro MTG Card Proxying',
      subtitle: 'Upgrade to a Pro Membership for ad-free card proxying and access to all of our features and benefits.',
      monthlyPlan: 'Monthly Pro Membership',
      yearlyPlan: 'Yearly Pro Membership',
      monthlyPrice: '$5.00 per month',
      yearlyPrice: '$40.00 per year',
      subscribeMonthly: 'Subscribe Monthly',
      subscribeYearly: 'Subscribe Yearly',
      signInToSubscribe: 'Sign in to Subscribe',
      manageSubscription: 'Manage Subscription',
      nextBillingDate: 'Next billing date',
      benefitsEndDate: 'Benefits will end on',
      viewInvoice: 'View Invoice',
      changePlan: 'Change Plan',
      reactivate: 'Reactivate',
      active: 'Active',
      cancelled: 'Cancelled',
    },
    benefits: {
      title: 'Why MTG Proxies Pro Membership?',
      subtitle: 'Unlock premium features and enjoy a seamless card proxying experience with our Pro membership.',
      noAds: {
        title: 'No Advertisements',
        description: 'Browse and proxy cards without any distracting ads, banners, or pop-ups. Enjoy a clean, focused experience.',
      },
      darkMode: {
        title: 'Dark Mode',
        description: 'Reduce eye strain with our elegant dark interface. Perfect for late-night browsing and low-light environments.',
      },
      advancedSearch: {
        title: 'Advanced Search Filters',
        description: 'Access powerful search filters to find exactly the cards you need. Filter by color identity, rarity, and card type.',
      },
      multipleDecks: {
        title: 'Manage Multiple Decks',
        description: 'Create and manage multiple decks with the Deck Builder. Save all of your favorite decks for quick access.',
      },
      futureFeatures: {
        title: 'Future Pro Features',
        description: "Get immediate access to all new premium features as they're released. Pro members always get first access.",
      },
      cancelAnytime: {
        title: 'Cancel Anytime',
        description: 'No long-term commitment required. You have complete flexibility to cancel your subscription at any time.',
      },
      prioritySupport: {
        title: 'Priority Support',
        description: 'Skip the queue with dedicated priority support. Your questions and concerns are addressed first and with extra care.',
      },
    },
    features: {
      unlimitedDecks: 'Create unlimited decks',
      noAds: 'No advertisements',
      prioritySupport: 'Priority support',
      darkMode: 'Dark mode access',
      advancedSearch: 'Advanced search filters',
      futureFeatures: 'Future pro features',
      cancelAnytime: 'Cancel anytime',
    },
    promotion: {
      title: 'Upgrade to Pro to Create More Decks',
      description: 'Pro members can create unlimited decks. Upgrade now to unlock this feature!',
      customizeTitle: 'Pro Feature: AI Card Customization',
      customizeDescription: 'Upgrade to Pro to create custom card variations using AI. Pro members can generate up to 5 custom cards per day.',
    },
    account: {
      title: 'Pro Membership',
      description: 'Manage your subscription details',
      subscribeToPro: 'Subscribe to Pro Membership',
    },
  },
  auth: {
    signIn: {
      title: 'Sign in to your account',
      withGoogle: 'Continue with Google',
      withEmail: 'Or continue with',
      sendMagicLink: 'Send Magic Link',
      noAccount: "Don't have an account?",
      checkEmail: 'Check your email for a link to sign in',
      errors: {
        failed: 'Failed to sign in',
        failedGoogle: 'Failed to sign in with Google',
        failedEmail: 'Failed to sign in with email',
        noEmail: 'No email found',
      },
      terms: 'By logging in, I confirm that I have read and agree to the',
    },
    register: {
      title: 'Create your account',
      haveAccount: 'Already have an account?',
      terms: 'By creating an account, I confirm that I have read and agree to the',
    },
    common: {
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      and: 'and',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['cards', 'tokens', 'emblems', 'planeswalkers', 'commanders', 'lands', 'creatures', 'artifacts', 'enchantments', 'instants'],
      description: 'MTG Proxies helps you proxy Magic: The Gathering cards to use in your casual decks completely free!',
      search: {
        cards: {
          tab: 'Cards',
          placeholder: 'Search for any Magic card...',
          error: 'Failed to fetch random cards',
        },
        sets: {
          tab: 'Sets',
          placeholder: 'Search for any Magic set...',
        },
        tokens: {
          tab: 'Tokens',
          placeholder: 'Search for any Magic token...',
        },
        button: 'Search',
        hint: {
          cards: 'Press Enter or click Search to find your cards',
          sets: 'Press Enter or click Search to find your sets',
          tokens: 'Press Enter or click Search to find your tokens',
        },
        aria: {
          searchIcon: 'Search icon',
          searchInput: {
            cards: 'Search for cards',
            sets: 'Search for sets',
            tokens: 'Search for tokens',
          },
        },
      },
      images: {
        cardAlt: 'Magic card image',
      },
    },
    features: {
      section1: {
        badge: 'Print Proxies',
        title: 'Print MTG Proxy Cards',
        subtitle: 'Add cards to your print list, print them out, and use them in your decks today!',
        features: [
          {
            name: 'Add card to your print list',
            description: 'Search for any card using the Search page or browse by set and add the cards you want to your print list.',
          },
          {
            name: 'Print your cards',
            description: 'Once you have added all the cards you want to your print list, you can print them out and use them in your decks.',
          },
          {
            name: 'Use them in your decks',
            description: 'Once you have printed out your cards, you can use them in your decks. Cut them out and tape them to a basic land or use a sleeve with a basic land inside.',
          },
        ],
        images: {
          cartScreenshot: 'Cart screenshot showing how to add cards to your print list',
        },
      },
      section2: {
        badge: 'Deck Builder',
        title: 'Build and manage Magic: The Gathering Decks',
        subtitle: 'Create and manage your own decks, or browse public decks created by the community.',
        features: [
          {
            name: 'Manage your MTG Decks',
            description: 'All users can create and manage one MTG deck. Pro members can create an unlimited number of decks.',
          },
          {
            name: 'Print your cards',
            description: 'Once you have added all the cards you want to your deck, you can add them to your Print List all at once.',
          },
          {
            name: 'Browse Public Decks',
            description: 'Add entire decks made by the community to your Print List so you can easily play test new decks.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Deck Builder interface showing how to manage your decks',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, All rights reserved.',
    disclaimer: 'MTG Proxies is not produced, endorsed, supported, or affiliated with Wizards of the Coast.',
    affiliate: 'As a TCGplayer affiliate partner, MTG Proxies may earn a commission on qualifying purchases.',
    builtBy: 'Built by',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    support: 'Support',
  },
  advancedFilters: {
    colors: 'Colors',
    rarity: 'Rarity',
    cardTypes: 'Card Types',
    format: 'Format',
    manaValue: 'Mana Value',
    cardName: 'Card Name',
    cardText: 'Card Text',
    specialCharacteristics: 'Special Characteristics',
    activeFilters: 'Active Filters',
    clearAll: 'Clear All',
    applyFilters: 'Apply Filters',
    searchCardName: 'Search card name...',
    searchCardText: 'Search card text...',
    namePrefix: 'Name: ',
    manaPrefix: 'Mana: ',
    textPrefix: 'Text: ',
    removeFromFilter: '✕',
    selectLanguage: 'Select language',
  },
  card: {
    addToPrintList: 'Add to Print List',
    removeFromPrintList: 'Remove from Print List',
    cardsInPrintList: 'card in Print List',
    cardsInPrintListPlural: 'cards in Print List',
    buyOn: 'Buy on TCGplayer',
    buyFor: 'Buy for ',
    viewDetails: 'View Details',
    type: 'Type',
    manaCost: 'Mana Cost',
    rarity: 'Rarity',
    artist: 'Artist',
    collectorNumber: 'Collector Number',
    oracleText: 'Oracle Text',
    youMightAlsoLike: 'You might also like...',
    noRecommendations: 'No recommendations available at this moment.',
    details: 'Details',
    rulings: 'Rulings',
    legality: 'Legality',
    share: 'Share',
    favorite: 'Favorite',
    noRulings: 'No rulings available for this card.',
    noLegalityInfo: 'No legality information available for this card.',
    published: 'Published',
    legal: 'Legal',
    restricted: 'Restricted',
    banned: 'Banned',
    format: 'Format',
    status: 'Status',
    viewMore: 'View More',
    viewLess: 'View Less',
    customizeWithAI: 'Customize with AI',
    otherVersions: 'Unique Prints',
    showUniqueVersions: 'Show Unique Prints',
    hideUniqueVersions: 'Hide Unique Prints',
  },
  cart: {
    title: 'Print List',
    closePanel: 'Close panel',
    empty: 'No proxies in the print list.',
    addPadding: 'Add padding',
    paperSize: 'Paper size',
    scale: 'Scale',
    clearCardList: 'Clear card list',
    print: 'Print',
    success: 'Success!',
  },
  setsList: {
    showing: 'Showing',
    to: 'to',
    of: 'of',
    results: 'results',
    searchPlaceholder: 'Search...',
  },
  customizeCardModal: {
    title: 'Customize Card with AI',
    description: 'Describe how you want to customize this card. The AI will generate a new version while maintaining the card frame and structure.',
    buttonText: 'Customize with AI',
    placeholder: 'Describe your custom card variation...',
    generateButton: 'Generate Custom Card',
    generatingButton: 'Generating...',
    addToPrintListButton: 'Add to Print List',
    successMessage: 'Added custom card to print list',
    remainingGenerationsOne: '{{count}} generation remaining today',
    remainingGenerationsOther: '{{count}} generations remaining today',
    error: {
      failed: 'Failed to generate custom card',
    },
  },
  // Add more translation keys as needed
};

export default translations;
