const translations = {
  common: {
    search: '搜索',
    signIn: '登录',
    register: '注册',
    myAccount: '我的账户',
    support: '支持',
    followUs: '在X/Twitter上关注我们',
    proMembership: 'Pro会员',
    new: '新',
    proMember: 'Pro会员',
    upgrade: '升级',
    subscribe: '订阅',
    manage: '管理',
    cancel: '取消',
    close: '关闭',
    or: '或',
    email: '电子邮件地址',
    continue: '继续',
    submit: '提交',
    billing: '账单',
    sort: '排序',
    language: '语言',
    selectLanguage: '选择语言',
    searchPlaceholder: '搜索...',
    cards: '卡牌',
    previous: '上一页',
    next: '下一页',
    pagination: '分页',
    back: '返回',
    backToSets: '返回系列',
    error: '发生错误。请重试。',
    home: '首页',
    sets: '系列',
  },
  theme: {
    light: '浅色',
    dark: '深色',
    toggle: '切换主题',
  },
  cta: {
    title: '准备好开始了吗？',
    subtitle: '今天就开始代理卡牌。',
    searchByCard: '按卡牌搜索',
    searchBySet: '按系列搜索',
  },
  nav: {
    proxyCards: '代理卡牌',
    sets: '系列',
    tokens: '衍生物',
    emblems: '徽记',
    import: '导入',
    topProxies: '热门代理',
    customCards: '自定义卡牌',
    deckBuilder: '套牌构建器',
    buildDeck: '构建套牌',
    publicDecks: '公开套牌',
    favoriteDeck: '我的收藏套牌',
    articles: '文章',
  },
  seo: {
    home: {
      title: 'MTG Proxies - 万智牌代理卡牌',
      description: '访问庞大的万智牌代理卡牌库。轻松查看和打印MTG卡牌图片用于休闲游戏。现在就开始构建你梦想中的套牌！',
    },
    sets: {
      title: '卡牌系列 - MTG Proxies',
      description: '浏览所有MTG系列并打印套牌用的代理卡牌。',
    },
    tokens: {
      title: '衍生物 - MTG Proxies',
      description: '使用各种代理衍生物增强你的MTG游戏体验。',
    },
    emblems: {
      title: '徽记 - MTG Proxies',
      description: '打印套牌用的MTG徽记代理卡牌。',
    },
    search: {
      title: 'MTG卡牌搜索 - MTG Proxies',
      description: '轻松查找和打印MTG代理卡牌。使用搜索工具访问庞大的数据库，用代理卡牌增强你的万智牌套牌。',
    },
    support: {
      title: '支持 - MTG Proxies',
      description: '如果你有问题、反馈或想要添加衍生物/徽记，请发送电子邮件给我们。',
    },
    articles: {
      title: '文章 - MTG Proxies',
      description: '关于万智牌和代理卡牌的文章。',
    },
    import: {
      title: '导入 - MTG Proxies',
      description: '导入卡牌列表以打印万智牌代理卡牌。',
    },
    topProxies: {
      title: '热门代理 - MTG Proxies',
      description: '查看最受欢迎的万智牌代理卡牌。',
    },
    customCards: {
      title: '自定义卡牌 - MTG Proxies',
      description: '创建你自己的万智牌代理卡牌。',
    },
    login: {
      title: '登录 - MTG Proxies',
      description: '登录你的账户以管理自定义卡牌、套牌列表等。',
    },
    register: {
      title: '注册 - MTG Proxies',
      description: '注册账户以管理自定义卡牌、套牌列表等。',
    },
    termsOfService: {
      title: '服务条款 - MTG Proxies',
      description: '阅读网站的服务条款。',
    },
    privacyPolicy: {
      title: '隐私政策 - MTG Proxies',
      description: '阅读网站的隐私政策。',
    },
    account: {
      title: '账户 - MTG Proxies',
      description: '管理你的账户。',
    },
    proMembership: {
      title: 'Pro会员 - MTG Proxies',
      description: '订阅Pro会员以访问网站的所有功能。',
    },
    deckBuilder: {
      title: '套牌构建器 - MTG Proxies',
      description: '创建和管理你的万智牌套牌。',
    },
    deckEditor: {
      title: '套牌编辑器 - MTG Proxies',
      description: '创建和编辑你的万智牌套牌。',
    },
    publicDecks: {
      title: '公开套牌 - MTG Proxies',
      description: '浏览和探索社区分享的MTG公开套牌。',
    },
  },
  pro: {
    pricing: {
      title: '解锁Pro MTG卡牌代理制作',
      subtitle: '升级到Pro会员以获得无广告的卡牌代理制作和所有功能与福利。',
      monthlyPlan: '月度Pro会员',
      yearlyPlan: '年度Pro会员',
      monthlyPrice: '$5.00/月',
      yearlyPrice: '$40.00/年',
      subscribeMonthly: '月度订阅',
      subscribeYearly: '年度订阅',
      signInToSubscribe: '登录以订阅',
      manageSubscription: '管理订阅',
      nextBillingDate: '下次账单日期',
      benefitsEndDate: '福利结束日期',
      viewInvoice: '查看发票',
      changePlan: '更改计划',
      reactivate: '重新激活',
      active: '活跃',
      cancelled: '已取消',
    },
    benefits: {
      title: '为什么需要MTG Proxies Pro会员',
      subtitle: '解锁高级功能，享受Pro会员带来的流畅卡牌代理制作体验。',
      noAds: {
        title: '无广告',
        description: '浏览和制作代理卡牌时没有烦人的广告、横幅和弹窗。享受干净、专注的体验。',
      },
      darkMode: {
        title: '深色模式',
        description: '使用优雅的深色界面减少眼睛疲劳。完美适合夜间浏览和低光环境。',
      },
      advancedSearch: {
        title: '高级搜索过滤器',
        description: '访问强大的搜索过滤器以精确找到你需要的卡牌。按颜色身份、稀有度和卡牌类型过滤。',
      },
      multipleDecks: {
        title: '多套牌管理',
        description: '使用套牌构建器创建和管理多个套牌。保存所有收藏的套牌以快速访问。',
      },
      futureFeatures: {
        title: '未来Pro功能',
        description: '在新的高级功能发布时立即访问。Pro会员总是优先获得访问权。',
      },
      cancelAnytime: {
        title: '随时取消',
        description: '无需长期承诺。你可以灵活地随时取消订阅。',
      },
      prioritySupport: {
        title: '优先支持',
        description: '使用专属优先支持跳过等待队列。你的问题和疑虑将优先得到特别关注。',
      },
    },
    features: {
      unlimitedDecks: '无限套牌创建',
      noAds: '无广告',
      prioritySupport: '优先支持',
      darkMode: '深色模式访问',
      advancedSearch: '高级搜索过滤器',
    },
    promotion: {
      title: '升级到Pro以创建更多套牌',
      description: 'Pro会员可以创建无限数量的套牌。立即升级以解锁此功能！',
      customizeTitle: 'Pro功能：AI卡牌定制',
      customizeDescription: '升级到Pro以使用AI创建自定义卡牌变体。Pro会员每天可以生成最多5张自定义卡牌。',
    },
    account: {
      title: 'Pro会员',
      description: '管理订阅详情',
      subscribeToPro: '订阅Pro会员',
    },
  },
  auth: {
    signIn: {
      title: '登录你的账户',
      withGoogle: '使用Google继续',
      withEmail: '或使用以下方式继续',
      sendMagicLink: '发送魔法链接',
      noAccount: '没有账户？',
      checkEmail: '检查你的电子邮件中的登录链接',
      errors: {
        failed: '登录失败',
        failedGoogle: 'Google登录失败',
        failedEmail: '电子邮件登录失败',
        noEmail: '未找到电子邮件',
      },
      terms: '登录即表示你同意：',
    },
    register: {
      title: '创建账户',
      haveAccount: '已有账户？',
      terms: '创建账户即表示你同意：',
    },
    common: {
      termsOfService: '服务条款',
      privacyPolicy: '隐私政策',
      and: '和',
    },
  },
  home: {
    hero: {
      title: '代理卡',
      rotatingWords: ['卡牌', '衍生物', '徽记', '鹏洛客', '指挥官', '地', '生物', '神器', '结界', '瞬间'],
      description: 'MTG Proxies帮助您免费制作万智牌代理卡，供您在休闲套牌中使用！',
      search: {
        cards: {
          tab: '卡牌',
          placeholder: '搜索万智牌卡牌...',
          error: '加载随机卡牌失败',
        },
        sets: {
          tab: '系列',
          placeholder: '搜索万智牌系列...',
        },
        tokens: {
          tab: '衍生物',
          placeholder: '搜索万智牌衍生物...',
        },
        button: '搜索',
        hint: {
          cards: '按回车键或点击搜索以查找卡牌',
          sets: '按回车键或点击搜索以查找系列',
          tokens: '按回车键或点击搜索以查找衍生物',
        },
        aria: {
          searchIcon: '搜索图标',
          searchInput: {
            cards: '搜索卡牌',
            sets: '搜索系列',
            tokens: '搜索衍生物',
          },
        },
      },
      images: {
        cardAlt: '万智牌卡牌图片',
      },
    },
    features: {
      section1: {
        badge: '打印代理卡',
        title: '打印MTG代理卡',
        subtitle: '将卡牌添加到打印列表，打印出来，今天就可以在您的套牌中使用！',
        features: [
          {
            name: '添加卡牌到打印列表',
            description: '使用搜索页面查找任何卡牌，或浏览系列并将您想要的卡牌添加到打印列表。',
          },
          {
            name: '打印卡牌',
            description: '将所有想要的卡牌添加到打印列表后，您可以打印它们并在套牌中使用。',
          },
          {
            name: '在套牌中使用',
            description: '打印卡牌后，您可以在套牌中使用它们。将它们剪下并贴在基本地上，或使用装有基本地的卡套。',
          },
        ],
        images: {
          cartScreenshot: '展示如何将卡牌添加到打印列表的购物车截图',
        },
      },
      section2: {
        badge: '套牌构建器',
        title: '构建和管理万智牌套牌',
        subtitle: '创建和管理您自己的套牌，或浏览社区创建的公开套牌。',
        features: [
          {
            name: '管理您的MTG套牌',
            description: '所有用户都可以创建和管理一副MTG套牌。Pro会员可以创建无限数量的套牌。',
          },
          {
            name: '打印卡牌',
            description: '将所有想要的卡牌添加到套牌后，您可以一次性将它们全部添加到打印列表。',
          },
          {
            name: '浏览公开套牌',
            description: '将社区创建的完整套牌添加到您的打印列表，轻松测试新套牌。',
          },
        ],
        images: {
          deckBuilderScreenshot: '展示如何管理套牌的套牌构建器界面',
        },
      },
    },
  },
  sort: {
    nameAsc: '名称 (A 到 Z)',
    nameDesc: '名称 (Z 到 A)',
    cmcAsc: '法术力费用 (从低到高)',
    cmcDesc: '法术力费用 (从高到低)',
    usdAsc: '价格 (从低到高)',
    usdDesc: '价格 (从高到低)',
    rarityAsc: '稀有度 (普通到神话)',
    rarityDesc: '稀有度 (神话到普通)',
    releasedDesc: '发行日期 (最新)',
    releasedAsc: '发行日期 (最早)',
  },
  pages: {
    search: {
      heading: '搜索',
    },
    sets: {
      heading: '卡牌系列',
      latestSets: '最新系列',
      upcomingSets: '即将发布系列',
      allSets: '所有系列',
      noResults: '未找到结果。',
    },
    set: {
      notFound: '未找到系列。',
      noCards: '未在此系列中找到卡牌。',
      addAll: '将{{count}}张卡牌添加到打印列表',
      addedToList: '已将{{count}}张卡牌添加到打印列表',
    },
    tokens: {
      heading: '衍生物',
    },
    emblems: {
      heading: '徽记',
    },
    import: {
      heading: '导入卡牌到打印列表',
      description: '输入你想添加到打印列表的所有卡牌名称。',
    },
    topProxies: {
      heading: '热门代理',
      description: '以下是MTG Proxies上最受欢迎的50张万智牌代理卡牌。',
    },
  },
  languages: {
    default: '默认',
    any: '任意',
    english: '英语',
    spanish: '西班牙语',
    french: '法语',
    german: '德语',
    italian: '意大利语',
    portuguese: '葡萄牙语',
    japanese: '日语',
    korean: '韩语',
    russian: '俄语',
    simplified_chinese: '简体中文',
    traditional_chinese: '繁体中文',
    hebrew: '希伯来语',
    latin: '拉丁语',
    ancient_greek: '古希腊语',
    arabic: '阿拉伯语',
    sanskrit: '梵语',
    phyrexian: '非瑞克西亚语',
    quenya: '昆雅语',
  },
  filters: {
    show: '✨ 显示专业版筛选',
    hide: '✨ 隐藏专业版筛选',
  },
  search: {
    showing_start: '显示第',
    showing_to: '到第',
    showing_of: '条，共',
    showing_results: '条结果',
    noResults: '未找到结果。',
    useSearchTip: '使用页面顶部的搜索来查找卡牌。',
  },
  footer: {
    copyright: 'MTG Proxies，保留所有权利。',
    disclaimer: 'MTG Proxies 并非由 Wizards of the Coast 制作、认可、支持或附属。',
    affiliate: '作为 TCGplayer 的联盟合作伙伴，MTG Proxies 可能会从合格购买中获得佣金。',
    builtBy: '开发者',
    minnerLabs: 'Minner Labs',
    privacyPolicy: '隐私政策',
    termsOfService: '服务条款',
    support: '支持',
  },
  advancedFilters: {
    colors: '颜色',
    rarity: '稀有度',
    cardTypes: '卡牌类型',
    format: '赛制',
    manaValue: '法术力值',
    cardName: '卡牌名称',
    cardText: '卡牌文本',
    specialCharacteristics: '特殊特性',
    activeFilters: '激活的筛选条件',
    clearAll: '全部清除',
    applyFilters: '应用筛选',
    searchCardName: '搜索卡牌名称...',
    searchCardText: '搜索卡牌文本...',
    namePrefix: '名称：',
    manaPrefix: '法术力：',
    textPrefix: '文本：',
    removeFromFilter: '✕',
    selectLanguage: '选择语言',
  },
  card: {
    addToPrintList: '添加到打印列表',
    removeFromPrintList: '从打印列表中移除',
    cardsInPrintList: '张卡牌在打印列表中',
    cardsInPrintListPlural: '张卡牌在打印列表中',
    buyOn: '在TCGplayer上购买',
    buyFor: '购买价格：',
    viewDetails: '查看详情',
    type: '类型',
    manaCost: '法术力费用',
    rarity: '稀有度',
    artist: '画师',
    collectorNumber: '收藏编号',
    oracleText: '神谕文本',
    youMightAlsoLike: '你可能也喜欢...',
    noRecommendations: '暂无推荐。',
    details: '详情',
    rulings: '规则',
    legality: '合法性',
    share: '分享',
    favorite: '收藏',
    noRulings: '此卡牌暂无规则。',
    noLegalityInfo: '此卡牌暂无合法性信息。',
    published: '发布',
    legal: '合法',
    restricted: '受限',
    banned: '禁用',
    format: '赛制',
    status: '状态',
    viewMore: '查看更多',
    viewLess: '收起',
    customizeWithAI: '使用AI自定义',
    otherVersions: '独特印刷',
    showUniqueVersions: '显示独特印刷',
    hideUniqueVersions: '隐藏独特印刷',
  },
  cart: {
    title: '打印列表',
    closePanel: '关闭面板',
    empty: '打印列表中没有代理卡。',
    addPadding: '添加边距',
    paperSize: '纸张大小',
    scale: '缩放',
    clearCardList: '清空列表',
    print: '打印',
    success: '成功！',
  },
  setsList: {
    showing: '显示',
    to: '至',
    of: '/',
    results: '个结果',
    searchPlaceholder: '搜索...',
  },
};

export default translations;
