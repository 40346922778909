const translations = {
  common: {
    search: 'Pesquisar',
    signIn: 'Entrar',
    register: 'Registrar',
    myAccount: 'Minha Conta',
    support: 'Suporte',
    followUs: 'Siga-nos no X/Twitter',
    proMembership: 'Assinatura Pro',
    new: 'Novo',
    proMember: 'Membro Pro',
    upgrade: 'Melhorar',
    subscribe: 'Assinar',
    manage: 'Gerenciar',
    cancel: 'Cancelar',
    close: 'Fechar',
    or: 'Ou',
    email: 'Endereço de e-mail',
    continue: 'Continuar',
    submit: 'Enviar',
    billing: 'Faturamento',
    sort: 'Ordenar',
    language: 'Idioma',
    selectLanguage: 'Selecionar Idioma',
    searchPlaceholder: 'Pesquisar...',
    cards: 'Cartas',
    previous: 'Anterior',
    next: 'Próximo',
    pagination: 'Paginação',
    back: 'Voltar',
    backToSets: 'Voltar para Coleções',
    error: 'Ocorreu um erro. Por favor, tente novamente.',
    home: 'Início',
    sets: 'Coleções',
  },
  theme: {
    light: 'Claro',
    dark: 'Escuro',
    toggle: 'Alternar tema',
  },
  cta: {
    title: 'Pronto para começar?',
    subtitle: 'Comece a criar proxies hoje mesmo.',
    searchByCard: 'Pesquisar por carta',
    searchBySet: 'Pesquisar por conjunto',
  },
  nav: {
    proxyCards: 'Cartas Proxy',
    sets: 'Coleções',
    tokens: 'Fichas',
    emblems: 'Emblemas',
    import: 'Importar',
    topProxies: 'Proxies Populares',
    customCards: 'Cartas Personalizadas',
    deckBuilder: 'Construtor de Deck',
    buildDeck: 'Construir Deck',
    publicDecks: 'Decks Públicos',
    favoriteDeck: 'Meus Decks Favoritos',
    articles: 'Artigos',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Cartas Proxy de Magic: The Gathering',
      description: 'Acesse uma vasta biblioteca de cartas proxy de Magic: The Gathering. Visualize e imprima facilmente imagens de cartas de MTG para jogo casual. Comece a construir o deck dos seus sonhos hoje!',
    },
    sets: {
      title: 'Coleções de Cartas - MTG Proxies',
      description: 'Explore todas as coleções de MTG e imprima proxies para seu deck.',
    },
    tokens: {
      title: 'Fichas - MTG Proxies',
      description: 'Melhore seu jogo de MTG com várias fichas proxy.',
    },
    emblems: {
      title: 'Emblemas - MTG Proxies',
      description: 'Imprima cartas proxy de emblemas de MTG para seu deck.',
    },
    search: {
      title: 'Pesquisa de Cartas MTG - MTG Proxies',
      description: 'Encontre e imprima cartas proxy de MTG facilmente. Use as ferramentas de pesquisa para acessar um vasto banco de dados e melhore seus decks de Magic: The Gathering com proxies.',
    },
    support: {
      title: 'Suporte - MTG Proxies',
      description: 'Envie-nos um e-mail se você tiver dúvidas, feedback ou quiser adicionar fichas/emblemas.',
    },
    articles: {
      title: 'Artigos - MTG Proxies',
      description: 'Artigos sobre Magic: The Gathering e cartas proxy.',
    },
    import: {
      title: 'Importar - MTG Proxies',
      description: 'Importe uma lista de cartas para imprimir proxies de Magic: The Gathering.',
    },
    topProxies: {
      title: 'Proxies Populares - MTG Proxies',
      description: 'Veja as cartas proxy mais populares de Magic: The Gathering.',
    },
    customCards: {
      title: 'Cartas Personalizadas - MTG Proxies',
      description: 'Crie suas próprias cartas proxy de Magic: The Gathering.',
    },
    login: {
      title: 'Entrar - MTG Proxies',
      description: 'Entre em sua conta para gerenciar cartas personalizadas, listas de deck e mais.',
    },
    register: {
      title: 'Registrar - MTG Proxies',
      description: 'Registre uma conta para gerenciar cartas personalizadas, listas de deck e mais.',
    },
    termsOfService: {
      title: 'Termos de Serviço - MTG Proxies',
      description: 'Leia os termos de serviço do site.',
    },
    privacyPolicy: {
      title: 'Política de Privacidade - MTG Proxies',
      description: 'Leia a política de privacidade do site.',
    },
    account: {
      title: 'Conta - MTG Proxies',
      description: 'Gerencie sua conta.',
    },
    proMembership: {
      title: 'Assinatura Pro - MTG Proxies',
      description: 'Assine a assinatura Pro para acessar todos os recursos do site.',
    },
    deckBuilder: {
      title: 'Construtor de Deck - MTG Proxies',
      description: 'Crie e gerencie seus decks de Magic: The Gathering.',
    },
    deckEditor: {
      title: 'Editor de Deck - MTG Proxies',
      description: 'Crie e edite seus decks de Magic: The Gathering.',
    },
    publicDecks: {
      title: 'Decks Públicos - MTG Proxies',
      description: 'Navegue e explore decks públicos de MTG compartilhados pela comunidade.',
    },
  },
  pro: {
    pricing: {
      title: 'Desbloqueie a Criação de Cartas Proxy Pro MTG',
      subtitle: 'Atualize para a assinatura Pro para criação de cartas proxy sem anúncios e acesso a todos os recursos e benefícios.',
      monthlyPlan: 'Assinatura Pro Mensal',
      yearlyPlan: 'Assinatura Pro Anual',
      monthlyPrice: 'R$25,00/mês',
      yearlyPrice: 'R$200,00/ano',
      subscribeMonthly: 'Assinar Mensal',
      subscribeYearly: 'Assinar Anual',
      signInToSubscribe: 'Entre para Assinar',
      manageSubscription: 'Gerenciar Assinatura',
      nextBillingDate: 'Próxima Data de Cobrança',
      benefitsEndDate: 'Data de Término dos Benefícios',
      viewInvoice: 'Ver Fatura',
      changePlan: 'Alterar Plano',
      reactivate: 'Reativar',
      active: 'Ativa',
      cancelled: 'Cancelada',
    },
    benefits: {
      title: 'Por que Você Precisa da Assinatura Pro MTG Proxies',
      subtitle: 'Desbloqueie recursos premium e desfrute de uma experiência suave de criação de cartas proxy com a assinatura Pro.',
      noAds: {
        title: 'Sem Anúncios',
        description: 'Navegue e crie proxies sem anúncios irritantes, banners e pop-ups. Desfrute de uma experiência limpa e focada.',
      },
      darkMode: {
        title: 'Modo Escuro',
        description: 'Reduza o cansaço visual com uma interface escura elegante. Perfeito para navegação noturna e ambientes com pouca luz.',
      },
      advancedSearch: {
        title: 'Filtros de Pesquisa Avançados',
        description: 'Acesse filtros de pesquisa poderosos para encontrar exatamente as cartas que você precisa. Filtre por identidade de cor, raridade e tipos de carta.',
      },
      multipleDecks: {
        title: 'Gerenciamento de Múltiplos Decks',
        description: 'Crie e gerencie múltiplos decks com o construtor de deck. Salve todos os seus decks favoritos para acesso rápido.',
      },
      futureFeatures: {
        title: 'Recursos Pro Futuros',
        description: 'Obtenha acesso instantâneo a novos recursos premium quando forem lançados. Membros Pro sempre têm acesso primeiro.',
      },
      cancelAnytime: {
        title: 'Cancele a Qualquer Momento',
        description: 'Sem compromissos de longo prazo. Você pode cancelar sua assinatura de forma flexível a qualquer momento.',
      },
      prioritySupport: {
        title: 'Suporte Prioritário',
        description: 'Pule a fila com suporte prioritário dedicado. Suas perguntas e preocupações são tratadas primeiro, com atenção especial.',
      },
    },
    features: {
      unlimitedDecks: 'Criação Ilimitada de Decks',
      noAds: 'Sem Anúncios',
      prioritySupport: 'Suporte Prioritário',
      darkMode: 'Acesso ao Modo Escuro',
      advancedSearch: 'Filtros de Pesquisa Avançados',
    },
    promotion: {
      title: 'Atualize para Pro para Criar Mais Decks',
      description: 'Membros Pro podem criar um número ilimitado de decks. Atualize agora para desbloquear este recurso!',
      customizeTitle: 'Recurso Pro: Personalização de Cartas com IA',
      customizeDescription: 'Atualize para Pro para criar variantes personalizadas de cartas usando IA. Membros Pro podem gerar até 5 cartas personalizadas por dia.',
    },
    account: {
      title: 'Assinatura Pro',
      description: 'Gerenciar Detalhes da Assinatura',
      subscribeToPro: 'Assinar Pro',
    },
  },
  auth: {
    signIn: {
      title: 'Entre em sua Conta',
      withGoogle: 'Continuar com Google',
      withEmail: 'Ou continuar com',
      sendMagicLink: 'Enviar Link Mágico',
      noAccount: 'Não tem uma conta?',
      checkEmail: 'Verifique seu e-mail para o link de login',
      errors: {
        failed: 'Falha no Login',
        failedGoogle: 'Falha no Login com Google',
        failedEmail: 'Falha no Login por E-mail',
        noEmail: 'E-mail não encontrado',
      },
      terms: 'Ao entrar, você concorda com:',
    },
    register: {
      title: 'Criar uma Conta',
      haveAccount: 'Já tem uma conta?',
      terms: 'Ao criar uma conta, você concorda com:',
    },
    common: {
      termsOfService: 'Termos de Serviço',
      privacyPolicy: 'Política de Privacidade',
      and: 'e',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['cartas', 'fichas', 'emblemas', 'planeswalkers', 'comandantes', 'terrenos', 'criaturas', 'artefatos', 'encantamentos', 'instantâneos'],
      description: 'O MTG Proxies ajuda você a criar proxies de cartas de Magic: The Gathering para usar em seus decks casuais gratuitamente!',
      search: {
        cards: {
          tab: 'Cartas',
          placeholder: 'Pesquisar carta Magic...',
          error: 'Falha ao carregar cartas aleatórias',
        },
        sets: {
          tab: 'Coleções',
          placeholder: 'Pesquisar coleção Magic...',
        },
        tokens: {
          tab: 'Fichas',
          placeholder: 'Pesquisar ficha Magic...',
        },
        button: 'Pesquisar',
        hint: {
          cards: 'Pressione Enter ou clique em Pesquisar para encontrar suas cartas',
          sets: 'Pressione Enter ou clique em Pesquisar para encontrar suas coleções',
          tokens: 'Pressione Enter ou clique em Pesquisar para encontrar suas fichas',
        },
        aria: {
          searchIcon: 'Ícone de pesquisa',
          searchInput: {
            cards: 'Pesquisar cartas',
            sets: 'Pesquisar coleções',
            tokens: 'Pesquisar fichas',
          },
        },
      },
      images: {
        cardAlt: 'Imagem de carta Magic',
      },
    },
    features: {
      section1: {
        badge: 'Imprimir Proxies',
        title: 'Imprimir Cartas Proxy MTG',
        subtitle: 'Adicione cartas à sua lista de impressão, imprima-as e use-as em seus decks hoje mesmo!',
        features: [
          {
            name: 'Adicione cartas à sua lista de impressão',
            description: 'Pesquise qualquer carta usando a página de Pesquisa ou navegue por coleção e adicione as cartas desejadas à sua lista de impressão.',
          },
          {
            name: 'Imprima suas cartas',
            description: 'Depois de adicionar todas as cartas desejadas à sua lista de impressão, você pode imprimi-las e usá-las em seus decks.',
          },
          {
            name: 'Use-as em seus decks',
            description: 'Depois de imprimir suas cartas, você pode usá-las em seus decks. Recorte-as e cole-as em um terreno básico ou use uma sleeve com um terreno básico dentro.',
          },
        ],
        images: {
          cartScreenshot: 'Captura de tela do carrinho mostrando como adicionar cartas à sua lista de impressão',
        },
      },
      section2: {
        badge: 'Construtor de Deck',
        title: 'Construa e gerencie Decks de Magic: The Gathering',
        subtitle: 'Crie e gerencie seus próprios decks, ou navegue pelos decks públicos criados pela comunidade.',
        features: [
          {
            name: 'Gerencie seus Decks MTG',
            description: 'Todos os usuários podem criar e gerenciar um deck MTG. Membros Pro podem criar um número ilimitado de decks.',
          },
          {
            name: 'Imprima suas cartas',
            description: 'Depois de adicionar todas as cartas desejadas ao seu deck, você pode adicioná-las à sua Lista de Impressão de uma só vez.',
          },
          {
            name: 'Navegue pelos Decks Públicos',
            description: 'Adicione decks completos criados pela comunidade à sua Lista de Impressão para testar facilmente novos decks.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Interface do Construtor de Deck mostrando como gerenciar seus decks',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, Todos os direitos reservados.',
    disclaimer: 'MTG Proxies não é produzido, endossado, apoiado ou afiliado à Wizards of the Coast.',
    affiliate: 'Como parceiro afiliado da TCGplayer, a MTG Proxies pode receber uma comissão em compras qualificadas.',
    builtBy: 'Desenvolvido por',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Política de Privacidade',
    termsOfService: 'Termos de Serviço',
    support: 'Suporte',
  },
  languages: {
    default: 'Padrão',
    any: 'Qualquer',
    english: 'Inglês',
    spanish: 'Espanhol',
    french: 'Francês',
    german: 'Alemão',
    italian: 'Italiano',
    portuguese: 'Português',
    japanese: 'Japonês',
    korean: 'Coreano',
    russian: 'Russo',
    simplified_chinese: 'Chinês simplificado',
    traditional_chinese: 'Chinês tradicional',
    hebrew: 'Hebraico',
    latin: 'Latim',
    ancient_greek: 'Grego antigo',
    arabic: 'Árabe',
    sanskrit: 'Sânscrito',
    phyrexian: 'Phyrexiano',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Mostrar filtros Pro',
    hide: '✨ Ocultar filtros Pro',
  },
  search: {
    showing_start: 'Mostrando',
    showing_to: 'a',
    showing_of: 'de',
    showing_results: 'resultados',
    noResults: 'Nenhum resultado encontrado.',
    useSearchTip: 'Use a pesquisa no topo da página para procurar uma carta.',
  },
  sort: {
    nameAsc: 'Nome (A a Z)',
    nameDesc: 'Nome (Z a A)',
    cmcAsc: 'Valor de Mana (Baixo para Alto)',
    cmcDesc: 'Valor de Mana (Alto para Baixo)',
    usdAsc: 'Preço (Baixo para Alto)',
    usdDesc: 'Preço (Alto para Baixo)',
    rarityAsc: 'Raridade (Comum para Mítica)',
    rarityDesc: 'Raridade (Mítica para Comum)',
    releasedDesc: 'Data de Lançamento (Mais Recente)',
    releasedAsc: 'Data de Lançamento (Mais Antiga)',
  },
  pages: {
    search: {
      heading: 'Pesquisar',
    },
    sets: {
      heading: 'Coleções de Cartas',
      latestSets: 'Coleções Recentes',
      upcomingSets: 'Próximas Coleções',
      allSets: 'Todas as Coleções',
      noResults: 'Nenhum resultado encontrado.',
    },
    set: {
      notFound: 'Coleção não encontrada.',
      noCards: 'Nenhuma carta encontrada nesta coleção.',
      addAll: 'Adicionar {{count}} Cartas à Lista de Impressão',
      addedToList: 'Adicionadas {{count}} cartas à Lista de Impressão',
    },
    tokens: {
      heading: 'Fichas',
    },
    emblems: {
      heading: 'Emblemas',
    },
    import: {
      heading: 'Importar cartas para Lista de Impressão',
      description: 'Digite os nomes de todas as cartas que você deseja adicionar à Lista de Impressão.',
    },
    topProxies: {
      heading: 'Proxies Populares',
      description: 'Aqui estão as 50 cartas proxy de Magic: The Gathering mais populares no MTG Proxies.',
    },
  },
  advancedFilters: {
    colors: 'Cores',
    rarity: 'Raridade',
    cardTypes: 'Tipos de Carta',
    format: 'Formato',
    manaValue: 'Valor de Mana',
    cardName: 'Nome da Carta',
    cardText: 'Texto da Carta',
    specialCharacteristics: 'Características Especiais',
    activeFilters: 'Filtros Ativos',
    clearAll: 'Limpar Tudo',
    applyFilters: 'Aplicar Filtros',
    searchCardName: 'Pesquisar nome da carta...',
    searchCardText: 'Pesquisar texto da carta...',
    namePrefix: 'Nome: ',
    manaPrefix: 'Mana: ',
    textPrefix: 'Texto: ',
    removeFromFilter: '✕',
    selectLanguage: 'Selecionar idioma',
  },
  card: {
    addToPrintList: 'Adicionar à lista de impressão',
    removeFromPrintList: 'Remover da lista de impressão',
    cardsInPrintList: 'carta na lista de impressão',
    cardsInPrintListPlural: 'cartas na lista de impressão',
    buyOn: 'Comprar no TCGplayer',
    buyFor: 'Comprar por ',
    viewDetails: 'Ver detalhes',
    type: 'Tipo',
    manaCost: 'Custo de mana',
    rarity: 'Raridade',
    artist: 'Artista',
    collectorNumber: 'Número do colecionador',
    oracleText: 'Texto Oracle',
    youMightAlsoLike: 'Você também pode gostar...',
    noRecommendations: 'Nenhuma recomendação disponível no momento.',
    details: 'Detalhes',
    rulings: 'Regras',
    legality: 'Legalidade',
    share: 'Compartilhar',
    favorite: 'Favorito',
    noRulings: 'Não há regras disponíveis para esta carta.',
    noLegalityInfo: 'Não há informações de legalidade disponíveis para esta carta.',
    published: 'Publicado',
    legal: 'Legal',
    restricted: 'Restrito',
    banned: 'Banido',
    format: 'Formato',
    status: 'Status',
    viewMore: 'Ver mais',
    viewLess: 'Ver menos',
    customizeWithAI: 'Personalizar com IA',
    otherVersions: 'Impressões únicas',
    showUniqueVersions: 'Mostrar impressões únicas',
    hideUniqueVersions: 'Ocultar impressões únicas',
  },
  cart: {
    title: 'Lista de impressão',
    closePanel: 'Fechar painel',
    empty: 'Nenhum proxy na lista de impressão.',
    addPadding: 'Adicionar preenchimento',
    paperSize: 'Tamanho do papel',
    scale: 'Escala',
    clearCardList: 'Limpar lista',
    print: 'Imprimir',
    success: 'Sucesso!',
  },
  setsList: {
    showing: 'Mostrando',
    to: 'até',
    of: 'de',
    results: 'resultados',
    searchPlaceholder: 'Pesquisar...',
  },
};

export default translations;
