import { useThemeResolver } from '../hooks/useThemeStore';
import { useUserResolver } from '../hooks/useUser';

const StoreResolvers = () => {
  useUserResolver();
  useThemeResolver();

  return <></>;
};

export default StoreResolvers;
