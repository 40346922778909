const translations = {
  common: {
    search: '검색',
    signIn: '로그인',
    register: '회원가입',
    myAccount: '내 계정',
    support: '지원',
    followUs: 'X/Twitter에서 팔로우',
    proMembership: '프로 멤버십',
    new: '신규',
    proMember: '프로 회원',
    upgrade: '업그레이드',
    subscribe: '구독',
    manage: '관리',
    cancel: '취소',
    close: '닫기',
    or: '또는',
    email: '이메일 주소',
    continue: '계속',
    submit: '제출',
    billing: '결제',
    sort: '정렬',
    language: '언어',
    selectLanguage: '언어 선택',
    searchPlaceholder: '검색...',
    cards: '카드',
    previous: '이전',
    next: '다음',
    pagination: '페이지 매김',
    back: '뒤로',
    backToSets: '세트로 돌아가기',
    error: '오류가 발생했습니다. 다시 시도해 주세요.',
    home: '홈',
    sets: '세트',
  },
  theme: {
    light: '라이트',
    dark: '다크',
    toggle: '테마 전환',
  },
  cta: {
    title: '시작할 준비가 되셨나요?',
    subtitle: '오늘 바로 카드 프록시를 시작하세요.',
    searchByCard: '카드로 검색',
    searchBySet: '세트로 검색',
  },
  nav: {
    proxyCards: '프록시 카드',
    sets: '세트',
    tokens: '토큰',
    emblems: '문장',
    import: '가져오기',
    topProxies: '인기 프록시',
    customCards: '커스텀 카드',
    deckBuilder: '덱 빌더',
    buildDeck: '덱 만들기',
    publicDecks: '공개 덱',
    favoriteDeck: '내 즐겨찾기 덱',
    articles: '기사',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Magic: The Gathering 프록시 카드',
      description: 'Magic: The Gathering 프록시 카드의 방대한 라이브러리에 접근하세요. 캐주얼 플레이를 위한 MTG 카드 이미지를 쉽게 보고 인쇄하세요. 지금 당신의 꿈의 덱을 만들어보세요!',
    },
    sets: {
      title: '카드 세트 - MTG Proxies',
      description: '모든 MTG 세트를 둘러보고 덱용 프록시를 인쇄하세요.',
    },
    tokens: {
      title: '토큰 - MTG Proxies',
      description: '다양한 프록시 토큰으로 MTG 게임을 향상시키세요.',
    },
    emblems: {
      title: '문장 - MTG Proxies',
      description: '덱용 MTG 문장 프록시 카드를 인쇄하세요.',
    },
    search: {
      title: 'MTG 카드 검색 - MTG Proxies',
      description: 'MTG 프록시 카드를 쉽게 찾고 인쇄하세요. 검색 도구를 사용하여 방대한 데이터베이스에 접근하고 Magic: The Gathering 덱을 프록시로 향상시키세요.',
    },
    support: {
      title: '지원 - MTG Proxies',
      description: '질문, 피드백이 있거나 토큰/문장 추가를 원하시면 이메일을 보내주세요.',
    },
    articles: {
      title: '기사 - MTG Proxies',
      description: 'Magic: The Gathering과 프록시 카드에 대한 기사.',
    },
    import: {
      title: '가져오기 - MTG Proxies',
      description: 'Magic: The Gathering 프록시를 인쇄하기 위한 카드 목록을 가져오세요.',
    },
    topProxies: {
      title: '인기 프록시 - MTG Proxies',
      description: '가장 인기 있는 Magic: The Gathering 프록시 카드를 보세요.',
    },
    customCards: {
      title: '커스텀 카드 - MTG Proxies',
      description: '나만의 Magic: The Gathering 프록시 카드를 만드세요.',
    },
    login: {
      title: '로그인 - MTG Proxies',
      description: '계정에 로그인하여 커스텀 카드, 덱 목록 등을 관리하세요.',
    },
    register: {
      title: '회원가입 - MTG Proxies',
      description: '계정을 등록하여 커스텀 카드, 덱 목록 등을 관리하세요.',
    },
    termsOfService: {
      title: '서비스 약관 - MTG Proxies',
      description: '사이트의 서비스 약관을 읽어보세요.',
    },
    privacyPolicy: {
      title: '개인정보 처리방침 - MTG Proxies',
      description: '사이트의 개인정보 처리방침을 읽어보세요.',
    },
    account: {
      title: '계정 - MTG Proxies',
      description: '계정을 관리하세요.',
    },
    proMembership: {
      title: 'Pro 멤버십 - MTG Proxies',
      description: 'Pro 멤버십에 가입하여 사이트의 모든 기능에 접근하세요.',
    },
    deckBuilder: {
      title: '덱 빌더 - MTG Proxies',
      description: 'Magic: The Gathering 덱을 만들고 관리하세요.',
    },
    deckEditor: {
      title: '덱 에디터 - MTG Proxies',
      description: 'Magic: The Gathering 덱을 만들고 편집하세요.',
    },
    publicDecks: {
      title: '공개 덱 - MTG Proxies',
      description: '커뮤니티가 공유한 MTG 공개 덱을 둘러보고 탐색하세요.',
    },
  },
  pro: {
    pricing: {
      title: 'Pro MTG 카드 프록시 제작 잠금 해제',
      subtitle: '광고 없는 카드 프록시 제작과 모든 기능 및 혜택에 접근하려면 Pro 멤버십으로 업그레이드하세요.',
      monthlyPlan: '월간 Pro 멤버십',
      yearlyPlan: '연간 Pro 멤버십',
      monthlyPrice: '월 $5.00',
      yearlyPrice: '연 $40.00',
      subscribeMonthly: '월간 구독',
      subscribeYearly: '연간 구독',
      signInToSubscribe: '로그인하여 구독',
      manageSubscription: '구독 관리',
      nextBillingDate: '다음 결제일',
      benefitsEndDate: '혜택 종료일',
      viewInvoice: '청구서 보기',
      changePlan: '플랜 변경',
      reactivate: '재활성화',
      active: '활성',
      cancelled: '취소됨',
    },
    benefits: {
      title: 'MTG Proxies Pro 멤버십이 필요한 이유',
      subtitle: '프리미엄 기능을 잠금 해제하고 Pro 멤버십으로 원활한 카드 프록시 제작 경험을 즐기세요.',
      noAds: {
        title: '광고 없음',
        description: '방해되는 광고, 배너, 팝업 없이 프록시를 탐색하고 제작하세요. 깔끔하고 집중된 경험을 즐기세요.',
      },
      darkMode: {
        title: '다크 모드',
        description: '우아한 다크 인터페이스로 눈의 피로를 줄이세요. 야간 탐색과 저조도 환경에 완벽합니다.',
      },
      advancedSearch: {
        title: '고급 검색 필터',
        description: '필요한 카드를 정확히 찾기 위한 강력한 검색 필터에 접근하세요. 색 정체성, 희귀도, 카드 타입으로 필터링하세요.',
      },
      multipleDecks: {
        title: '여러 덱 관리',
        description: '덱 빌더로 여러 덱을 만들고 관리하세요. 모든 즐겨찾기 덱을 저장하여 빠르게 접근하세요.',
      },
      futureFeatures: {
        title: '향후 Pro 기능',
        description: '새로운 프리미엄 기능이 출시되면 즉시 접근하세요. Pro 회원은 항상 먼저 접근할 수 있습니다.',
      },
      cancelAnytime: {
        title: '언제든지 취소 가능',
        description: '장기 약정이 필요 없습니다. 구독을 언제든지 유연하게 취소할 수 있습니다.',
      },
      prioritySupport: {
        title: '우선 지원',
        description: '전용 우선 지원으로 대기열을 건너뛰세요. 귀하의 질문과 우려사항은 먼저, 특별한 주의를 기울여 처리됩니다.',
      },
    },
    features: {
      unlimitedDecks: '무제한 덱 생성',
      noAds: '광고 없음',
      prioritySupport: '우선 지원',
      darkMode: '다크 모드 접근',
      advancedSearch: '고급 검색 필터',
    },
    promotion: {
      title: '더 많은 덱을 만들기 위해 Pro로 업그레이드',
      description: 'Pro 회원은 무제한으로 덱을 만들 수 있습니다. 지금 업그레이드하여 이 기능을 잠금 해제하세요!',
      customizeTitle: 'Pro 기능: AI 카드 커스터마이제이션',
      customizeDescription: 'AI를 사용하여 커스텀 카드 변형을 만들기 위해 Pro로 업그레이드하세요. Pro 회원은 하루에 최대 5장의 커스텀 카드를 생성할 수 있습니다.',
    },
    account: {
      title: 'Pro 멤버십',
      description: '구독 세부사항 관리',
      subscribeToPro: 'Pro 멤버십 구독',
    },
  },
  auth: {
    signIn: {
      title: '계정에 로그인',
      withGoogle: 'Google로 계속',
      withEmail: '또는 다음으로 계속',
      sendMagicLink: '매직 링크 보내기',
      noAccount: '계정이 없으신가요?',
      checkEmail: '로그인 링크를 이메일에서 확인하세요',
      errors: {
        failed: '로그인 실패',
        failedGoogle: 'Google 로그인 실패',
        failedEmail: '이메일 로그인 실패',
        noEmail: '이메일을 찾을 수 없음',
      },
      terms: '로그인함으로써 다음에 동의합니다:',
    },
    register: {
      title: '계정 만들기',
      haveAccount: '이미 계정이 있으신가요?',
      terms: '계정을 만듦으로써 다음에 동의합니다:',
    },
    common: {
      termsOfService: '서비스 약관',
      privacyPolicy: '개인정보 처리방침',
      and: '및',
    },
  },
  home: {
    hero: {
      title: '프록시',
      rotatingWords: ['카드', '토큰', '엠블럼', '플레인즈워커', '커맨더', '대지', '생물', '마법물체', '부여마법', '순간마법'],
      description: 'MTG Proxies는 캐주얼 덱에서 사용할 수 있는 Magic: The Gathering 프록시 카드를 무료로 만들 수 있도록 도와드립니다!',
      search: {
        cards: {
          tab: '카드',
          placeholder: '매직 카드 검색...',
          error: '무작위 카드 로딩 실패',
        },
        sets: {
          tab: '세트',
          placeholder: '매직 세트 검색...',
        },
        tokens: {
          tab: '토큰',
          placeholder: '매직 토큰 검색...',
        },
        button: '검색',
        hint: {
          cards: 'Enter 키를 누르거나 검색을 클릭하여 카드 찾기',
          sets: 'Enter 키를 누르거나 검색을 클릭하여 세트 찾기',
          tokens: 'Enter 키를 누르거나 검색을 클릭하여 토큰 찾기',
        },
        aria: {
          searchIcon: '검색 아이콘',
          searchInput: {
            cards: '카드 검색',
            sets: '세트 검색',
            tokens: '토큰 검색',
          },
        },
      },
      images: {
        cardAlt: '매직 카드 이미지',
      },
    },
    features: {
      section1: {
        badge: '프록시 인쇄',
        title: 'MTG 프록시 카드 인쇄',
        subtitle: '인쇄 목록에 카드를 추가하고, 인쇄하여 오늘 바로 덱에서 사용하세요!',
        features: [
          {
            name: '인쇄 목록에 카드 추가',
            description: '검색 페이지에서 카드를 찾거나 세트를 탐색하여 원하는 카드를 인쇄 목록에 추가하세요.',
          },
          {
            name: '카드 인쇄',
            description: '인쇄 목록에 원하는 모든 카드를 추가한 후, 인쇄하여 덱에서 사용할 수 있습니다.',
          },
          {
            name: '덱에서 사용',
            description: '카드를 인쇄한 후에는 덱에서 사용할 수 있습니다. 카드를 잘라내어 기본 대지에 붙이거나 기본 대지가 들어있는 슬리브를 사용하세요.',
          },
        ],
        images: {
          cartScreenshot: '인쇄 목록에 카드를 추가하는 방법을 보여주는 장바구니 스크린샷',
        },
      },
      section2: {
        badge: '덱 빌더',
        title: 'Magic: The Gathering 덱 제작 및 관리',
        subtitle: '자신만의 덱을 만들고 관리하거나, 커뮤니티가 만든 공개 덱을 탐색하세요.',
        features: [
          {
            name: 'MTG 덱 관리',
            description: '모든 사용자는 하나의 MTG 덱을 만들고 관리할 수 있습니다. Pro 회원은 무제한으로 덱을 만들 수 있습니다.',
          },
          {
            name: '카드 인쇄',
            description: '덱에 원하는 모든 카드를 추가한 후, 한 번에 인쇄 목록에 추가할 수 있습니다.',
          },
          {
            name: '공개 덱 탐색',
            description: '커뮤니티가 만든 전체 덱을 인쇄 목록에 추가하여 새로운 덱을 쉽게 테스트해보세요.',
          },
        ],
        images: {
          deckBuilderScreenshot: '덱 관리 방법을 보여주는 덱 빌더 인터페이스',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, 모든 권리 보유.',
    disclaimer: 'MTG Proxies는 Wizards of the Coast에서 제작, 승인, 지원 또는 제휴하지 않았습니다.',
    affiliate: 'TCGplayer 제휴 파트너로서 MTG Proxies는 적격 구매에 대한 수수료를 받을 수 있습니다.',
    builtBy: '제작자',
    minnerLabs: 'Minner Labs',
    privacyPolicy: '개인정보 처리방침',
    termsOfService: '서비스 약관',
    support: '지원',
  },
  sort: {
    nameAsc: '이름 (A to Z)',
    nameDesc: '이름 (Z to A)',
    cmcAsc: '마나 비용 (낮은순)',
    cmcDesc: '마나 비용 (높은순)',
    usdAsc: '가격 (낮은순)',
    usdDesc: '가격 (높은순)',
    rarityAsc: '희귀도 (일반 to 신화)',
    rarityDesc: '희귀도 (신화 to 일반)',
    releasedDesc: '출시일 (최신순)',
    releasedAsc: '출시일 (오래된순)',
  },
  pages: {
    search: {
      heading: '검색',
    },
    sets: {
      heading: '카드 세트',
      latestSets: '최신 세트',
      upcomingSets: '출시 예정 세트',
      allSets: '모든 세트',
      noResults: '결과를 찾을 수 없습니다.',
    },
    set: {
      notFound: '세트를 찾을 수 없습니다.',
      noCards: '이 세트에서 카드를 찾을 수 없습니다.',
      addAll: '{{count}}장의 카드를 인쇄 목록에 추가',
      addedToList: '{{count}}장의 카드가 인쇄 목록에 추가되었습니다',
    },
    tokens: {
      heading: '토큰',
    },
    emblems: {
      heading: '문장',
    },
    import: {
      heading: '인쇄 목록에 카드 가져오기',
      description: '인쇄 목록에 추가하려는 모든 카드의 이름을 입력하세요.',
    },
    topProxies: {
      heading: '인기 프록시',
      description: 'MTG Proxies에서 가장 인기 있는 50개의 Magic: The Gathering 프록시 카드입니다.',
    },
  },
  languages: {
    default: '기본',
    any: '모두',
    english: '영어',
    spanish: '스페인어',
    french: '프랑스어',
    german: '독일어',
    italian: '이탈리아어',
    portuguese: '포르투갈어',
    japanese: '일본어',
    korean: '한국어',
    russian: '러시아어',
    simplified_chinese: '중국어 간체',
    traditional_chinese: '중국어 번체',
    hebrew: '히브리어',
    latin: '라틴어',
    ancient_greek: '고대 그리스어',
    arabic: '아랍어',
    sanskrit: '산스크리트어',
    phyrexian: '피렉시아어',
    quenya: '퀘냐어',
  },
  filters: {
    show: '✨ Pro 필터 표시',
    hide: '✨ Pro 필터 숨기기',
  },
  search: {
    showing_start: '표시:',
    showing_to: '~',
    showing_of: '/',
    showing_results: '개',
    noResults: '결과를 찾을 수 없습니다.',
    useSearchTip: '페이지 상단의 검색을 사용하여 카드를 찾으세요.',
  },
  advancedFilters: {
    colors: '색상',
    rarity: '희귀도',
    cardTypes: '카드 유형',
    format: '포맷',
    manaValue: '마나 값',
    cardName: '카드 이름',
    cardText: '카드 텍스트',
    specialCharacteristics: '특별 특성',
    activeFilters: '활성 필터',
    clearAll: '모두 지우기',
    applyFilters: '필터 적용',
    searchCardName: '카드 이름 검색...',
    searchCardText: '카드 텍스트 검색...',
    namePrefix: '이름: ',
    manaPrefix: '마나: ',
    textPrefix: '텍스트: ',
    removeFromFilter: '✕',
    selectLanguage: '언어 선택',
  },
  card: {
    addToPrintList: '인쇄 목록에 추가',
    removeFromPrintList: '인쇄 목록에서 제거',
    cardsInPrintList: '장의 카드가 인쇄 목록에 있습니다',
    cardsInPrintListPlural: '장의 카드가 인쇄 목록에 있습니다',
    buyOn: 'TCGplayer에서 구매',
    buyFor: '구매 가격: ',
    viewDetails: '상세 정보 보기',
    type: '타입',
    manaCost: '마나 비용',
    rarity: '희귀도',
    artist: '아티스트',
    collectorNumber: '수집가 번호',
    oracleText: '오라클 텍스트',
    youMightAlsoLike: '추천 카드...',
    noRecommendations: '현재 추천 카드가 없습니다.',
    details: '상세 정보',
    rulings: '규칙',
    legality: '적법성',
    share: '공유',
    favorite: '즐겨찾기',
    noRulings: '이 카드에 대한 규칙이 없습니다.',
    noLegalityInfo: '이 카드에 대한 적법성 정보가 없습니다.',
    published: '발행',
    legal: '적법',
    restricted: '제한',
    banned: '금지',
    format: '포맷',
    status: '상태',
    viewMore: '더 보기',
    viewLess: '접기',
    customizeWithAI: 'AI로 커스터마이즈',
    otherVersions: '고유 인쇄',
    showUniqueVersions: '고유 인쇄 표시',
    hideUniqueVersions: '고유 인쇄 숨기기',
  },
  cart: {
    title: '인쇄 목록',
    closePanel: '패널 닫기',
    empty: '인쇄 목록에 프록시가 없습니다.',
    addPadding: '여백 추가',
    paperSize: '용지 크기',
    scale: '크기 조정',
    clearCardList: '목록 비우기',
    print: '인쇄',
    success: '성공!',
  },
  setsList: {
    showing: '표시 중:',
    to: '~',
    of: '/',
    results: '개',
    searchPlaceholder: '검색...',
  },
};

export default translations;
