import { Button } from '@/components/ui/button';
import Link from 'next/link';
import React, { useState } from 'react';
import { useCart } from '../hooks/useCart';
import { useTranslation } from '../hooks/useTranslation';
import CardImage from './CardImage';
import CustomizeCardModal from './CustomizeCardModal';
import { Repeat } from 'lucide-react';

export default function Card({ card, showBuyButton = true }) {
  const { addCardToCart, updateQuantity, getQuantityOfItem } = useCart();
  const [customImage, setCustomImage] = useState(null);
  const [faceIndex, setFaceIndex] = useState(0);
  const { t } = useTranslation();
  const cardId = card?.id;

  // Handle dual-faced cards vs regular cards
  const hasTwoFaces = card?.card_faces && card.card_faces.length > 0;
  const currentFace = hasTwoFaces ? card.card_faces[faceIndex] : null;

  // Prioritize image_uris for all cards, only fallback to card_faces if necessary
  const imageSrc = customImage || card?.image_uris?.large || (hasTwoFaces ? currentFace?.image_uris?.large : null);

  // Only show card name from card_faces if there's no main card name
  const cardName = card?.name || (hasTwoFaces ? currentFace?.name : null);
  const setName = card?.set_name;
  const quantity = getQuantityOfItem(cardId);

  const flipCard = () => {
    setFaceIndex(faceIndex === 0 ? 1 : 0);
  };

  const increment = () => {
    if (quantity === 0) {
      addCardToCart({
        ...card,
        image_uris: card?.image_uris && {
          ...card.image_uris,
          large: customImage || card.image_uris.large,
        },
        card_faces:
          !card?.image_uris && hasTwoFaces
            ? card.card_faces.map((face, idx) => ({
                ...face,
                image_uris: {
                  ...face.image_uris,
                  large: idx === 0 && customImage ? customImage : face.image_uris.large,
                },
              }))
            : undefined,
        isCustom: !!customImage,
        customImage,
        originalCardId: card.id,
        id: customImage ? `${card.id}-custom-${Date.now()}` : card.id,
      });
    } else {
      updateQuantity(cardId, 1);
    }
  };

  const handleCustomize = (newImageUrl) => {
    setCustomImage(newImageUrl);
  };

  const formattedUrl = () => {
    const partnerUrl = `https://partner.tcgplayer.com/mtg-proxies`;
    const redirectUrl = card?.purchase_uris?.tcgplayer.split('u=')[1];
    if (!redirectUrl) return null;
    const url = `${partnerUrl}?u=${redirectUrl}`;
    return url;
  };
  const theFormattedUrl = formattedUrl();

  const price = card?.prices?.usd ? `$${card?.prices?.usd}` : '';

  return (
    <>
      <div className='space-y-2'>
        <Link className='flex flex-col gap-1' href={`/card/${cardId}`}>
          <div className='relative'>
            <CardImage src={imageSrc} alt={cardName} allowZoom={false} />

            {hasTwoFaces && card.card_faces.length > 1 && !card.image_uris?.large && (
              <div className='absolute bottom-2 left-0 right-0 flex justify-center'>
                <Button
                  variant='outline'
                  onClick={(e) => {
                    e.preventDefault();
                    flipCard();
                  }}
                >
                  <Repeat className='w-4 h-4 mr-2' />
                  Turn Over
                </Button>
              </div>
            )}
          </div>

          <div className='flex flex-col'>
            <h3 className='text-base truncate'>{cardName}</h3>
            <p className='text-sm text-gray-600 dark:text-gray-400 truncate'>{setName}</p>
          </div>
        </Link>

        <div className='mt-3 w-full flex flex-col gap-2'>
          {quantity > 0 ? (
            <select
              value={quantity}
              onChange={(e) => updateQuantity(cardId, parseInt(e.target.value) - quantity)}
              className='w-full border-0 rounded-md bg-gray-800 text-white hover:bg-gray-900 focus:outline-none text-center text-sm font-medium dark:bg-gray-50 dark:text-gray-900'
            >
              <option value={0}>{t('card.removeFromPrintList')}</option>
              {Array.from({ length: 100 }, (_, i) => i + 1).map((num) => (
                <option key={num} value={num}>
                  {num} {num === 1 ? t('card.cardsInPrintList') : t('card.cardsInPrintListPlural')}
                </option>
              ))}
            </select>
          ) : (
            <Button variant='primary' onClick={increment}>
              {t('card.addToPrintList')}
            </Button>
          )}
          {showBuyButton && process.env.NEXT_PUBLIC_ENABLE_AI === 'true' && <CustomizeCardModal card={card} onCustomize={handleCustomize} />}
          {showBuyButton && theFormattedUrl && (
            <Button asChild variant='outline'>
              <Link href={theFormattedUrl} target='_blank'>
                <img src='/tcgplayer.webp' alt='TCGplayer' className='w-4 h-4 inline-block' /> {price ? `${t('card.buyFor')}${price}` : t('card.buyOn')}
              </Link>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
