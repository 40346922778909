const translations = {
  common: {
    search: 'Suchen',
    signIn: 'Anmelden',
    register: 'Registrieren',
    myAccount: 'Mein Konto',
    support: 'Support',
    followUs: 'Folgen Sie uns auf X/Twitter',
    proMembership: 'Pro-Mitgliedschaft',
    new: 'Neu',
    proMember: 'Pro-Mitglied',
    upgrade: 'Upgrade',
    subscribe: 'Abonnieren',
    manage: 'Verwalten',
    cancel: 'Abbrechen',
    close: 'Schließen',
    or: 'Oder',
    email: 'E-Mail-Adresse',
    continue: 'Fortfahren',
    submit: 'Absenden',
    billing: 'Abrechnung',
    sort: 'Sortieren',
    language: 'Sprache',
    selectLanguage: 'Sprache auswählen',
    searchPlaceholder: 'Suchen...',
    cards: 'Karten',
    previous: 'Zurück',
    next: 'Weiter',
    pagination: 'Seitennummerierung',
    back: 'Zurück',
    backToSets: 'Zurück zu Sets',
    error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    home: 'Startseite',
    sets: 'Sets',
  },
  theme: {
    light: 'Hell',
    dark: 'Dunkel',
    toggle: 'Theme umschalten',
  },
  cta: {
    title: 'Bereit loszulegen?',
    subtitle: 'Beginnen Sie noch heute mit dem Erstellen von Karten-Proxies.',
    searchByCard: 'Nach Karte suchen',
    searchBySet: 'Nach Set suchen',
  },
  nav: {
    proxyCards: 'Proxy-Karten',
    sets: 'Sets',
    tokens: 'Spielmarken',
    emblems: 'Embleme',
    import: 'Importieren',
    topProxies: 'Top-Proxies',
    customCards: 'Eigene Karten',
    deckBuilder: 'Deck-Builder',
    buildDeck: 'Deck erstellen',
    publicDecks: 'Öffentliche Decks',
    favoriteDeck: 'Meine Lieblings-Decks',
    articles: 'Artikel',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Proxy Magic: The Gathering Karten',
      description: 'Zugriff auf eine umfangreiche Bibliothek von Magic: The Gathering Proxy-Karten. Einfach MTG-Kartenbilder für Casual Play ansehen und drucken. Beginnen Sie jetzt mit dem Bau Ihres Traumdecks!',
    },
    sets: {
      title: 'Kartensets - MTG Proxies',
      description: 'Durchsuchen Sie alle MTG-Sets und drucken Sie Proxies für Ihr Deck.',
    },
    tokens: {
      title: 'Spielmarken - MTG Proxies',
      description: 'Verbessern Sie Ihre MTG-Spiele mit verschiedenen Proxy-Spielmarken.',
    },
    emblems: {
      title: 'Embleme - MTG Proxies',
      description: 'Drucken Sie MTG-Emblem-Proxykarten für Ihr Deck.',
    },
    search: {
      title: 'MTG-Karten suchen - MTG Proxies',
      description: 'Finden und drucken Sie MTG-Proxykarten mühelos. Nutzen Sie unser Suchwerkzeug, um auf eine umfangreiche Datenbank zuzugreifen und verbessern Sie Ihr Magic: The Gathering Deck mit Proxies.',
    },
    support: {
      title: 'Support - MTG Proxies',
      description: 'Wenn Sie Fragen, Feedback haben oder eine Spielmarke/ein Emblem hinzufügen möchten, senden Sie uns eine E-Mail.',
    },
    articles: {
      title: 'Artikel - MTG Proxies',
      description: 'Artikel über Magic: The Gathering und Proxy-Karten.',
    },
    import: {
      title: 'Importieren - MTG Proxies',
      description: 'Importieren Sie eine Liste von Karten, um Ihre Magic: The Gathering Proxies zu drucken.',
    },
    topProxies: {
      title: 'Top-Proxies - MTG Proxies',
      description: 'Sehen Sie die beliebtesten Magic: The Gathering Proxy-Karten.',
    },
    customCards: {
      title: 'Eigene Karten - MTG Proxies',
      description: 'Erstellen Sie Ihre eigenen Magic: The Gathering Proxy-Karten.',
    },
    login: {
      title: 'Anmelden - MTG Proxies',
      description: 'Melden Sie sich bei Ihrem Konto an, um Ihre eigenen Karten, Decklisten und mehr zu verwalten.',
    },
    register: {
      title: 'Registrieren - MTG Proxies',
      description: 'Registrieren Sie sich, um Ihre eigenen Karten, Decklisten und mehr zu verwalten.',
    },
    termsOfService: {
      title: 'Nutzungsbedingungen - MTG Proxies',
      description: 'Lesen Sie die Nutzungsbedingungen der Website.',
    },
    privacyPolicy: {
      title: 'Datenschutzerklärung - MTG Proxies',
      description: 'Lesen Sie die Datenschutzerklärung der Website.',
    },
    account: {
      title: 'Konto - MTG Proxies',
      description: 'Verwalten Sie Ihr Konto.',
    },
    proMembership: {
      title: 'Pro-Mitgliedschaft - MTG Proxies',
      description: 'Abonnieren Sie die Pro-Mitgliedschaft, um Zugang zu allen Funktionen der Website zu erhalten.',
    },
    deckBuilder: {
      title: 'Deck-Builder - MTG Proxies',
      description: 'Erstellen und verwalten Sie Ihre Magic: The Gathering Decks.',
    },
    deckEditor: {
      title: 'Deck-Editor - MTG Proxies',
      description: 'Erstellen und bearbeiten Sie Ihre Magic: The Gathering Decks.',
    },
    publicDecks: {
      title: 'Öffentliche Decks - MTG Proxies',
      description: 'Durchsuchen und entdecken Sie öffentliche MTG-Decks, die von der Community geteilt wurden.',
    },
  },
  pro: {
    pricing: {
      title: 'Schalten Sie Pro MTG-Karten-Proxying frei',
      subtitle: 'Upgraden Sie auf eine Pro-Mitgliedschaft für werbefreies Karten-Proxying und Zugang zu allen unseren Funktionen und Vorteilen.',
      monthlyPlan: 'Monatliche Pro-Mitgliedschaft',
      yearlyPlan: 'Jährliche Pro-Mitgliedschaft',
      monthlyPrice: '5,00 $ pro Monat',
      yearlyPrice: '40,00 $ pro Jahr',
      subscribeMonthly: 'Monatlich abonnieren',
      subscribeYearly: 'Jährlich abonnieren',
      signInToSubscribe: 'Anmelden zum Abonnieren',
      manageSubscription: 'Abonnement verwalten',
      nextBillingDate: 'Nächstes Abrechnungsdatum',
      benefitsEndDate: 'Vorteile enden am',
      viewInvoice: 'Rechnung anzeigen',
      changePlan: 'Plan ändern',
      reactivate: 'Reaktivieren',
      active: 'Aktiv',
      cancelled: 'Gekündigt',
    },
    benefits: {
      title: 'Warum MTG Proxies Pro-Mitgliedschaft?',
      subtitle: 'Schalten Sie Premium-Funktionen frei und genießen Sie eine nahtlose Karten-Proxying-Erfahrung mit unserer Pro-Mitgliedschaft.',
      noAds: {
        title: 'Keine Werbung',
        description: 'Surfen und Proxies erstellen ohne störende Anzeigen, Banner oder Pop-ups. Genießen Sie eine saubere, fokussierte Erfahrung.',
      },
      darkMode: {
        title: 'Dunkelmodus',
        description: 'Reduzieren Sie die Augenbelastung mit unserer eleganten dunklen Benutzeroberfläche. Perfekt für nächtliches Surfen und Umgebungen mit wenig Licht.',
      },
      advancedSearch: {
        title: 'Erweiterte Suchfilter',
        description: 'Zugriff auf leistungsstarke Suchfilter, um genau die Karten zu finden, die Sie benötigen. Filtern Sie nach Farbidentität, Seltenheit und Kartentyp.',
      },
      multipleDecks: {
        title: 'Mehrere Decks verwalten',
        description: 'Erstellen und verwalten Sie mehrere Decks mit dem Deck-Builder. Speichern Sie alle Ihre Lieblings-Decks für schnellen Zugriff.',
      },
      futureFeatures: {
        title: 'Zukünftige Pro-Funktionen',
        description: 'Erhalten Sie sofortigen Zugriff auf alle neuen Premium-Funktionen, sobald sie veröffentlicht werden. Pro-Mitglieder haben immer zuerst Zugang.',
      },
      cancelAnytime: {
        title: 'Jederzeit kündbar',
        description: 'Keine langfristige Bindung erforderlich. Sie haben die volle Flexibilität, Ihr Abonnement jederzeit zu kündigen.',
      },
      prioritySupport: {
        title: 'Prioritäts-Support',
        description: 'Überspringen Sie die Warteschlange mit dediziertem Prioritäts-Support. Ihre Fragen und Anliegen werden zuerst und mit besonderer Sorgfalt behandelt.',
      },
    },
    features: {
      unlimitedDecks: 'Unbegrenzte Decks erstellen',
      noAds: 'Keine Werbung',
      prioritySupport: 'Prioritäts-Support',
      darkMode: 'Zugang zum Dunkelmodus',
      advancedSearch: 'Erweiterte Suchfilter',
    },
    promotion: {
      title: 'Upgraden Sie auf Pro, um mehr Decks zu erstellen',
      description: 'Pro-Mitglieder können unbegrenzt Decks erstellen. Upgraden Sie jetzt, um diese Funktion freizuschalten!',
      customizeTitle: 'Pro-Funktion: KI-Kartenanpassung',
      customizeDescription: 'Upgraden Sie auf Pro, um benutzerdefinierte Kartenvariationen mit KI zu erstellen. Pro-Mitglieder können bis zu 5 eigene Karten pro Tag generieren.',
    },
    account: {
      title: 'Pro-Mitgliedschaft',
      description: 'Verwalten Sie Ihre Abonnementdetails',
      subscribeToPro: 'Pro-Mitgliedschaft abonnieren',
    },
  },
  auth: {
    signIn: {
      title: 'Melden Sie sich bei Ihrem Konto an',
      withGoogle: 'Mit Google fortfahren',
      withEmail: 'Oder fortfahren mit',
      sendMagicLink: 'Magic Link senden',
      noAccount: 'Noch kein Konto?',
      checkEmail: 'Überprüfen Sie Ihre E-Mail für einen Anmeldelink',
      errors: {
        failed: 'Anmeldung fehlgeschlagen',
        failedGoogle: 'Anmeldung mit Google fehlgeschlagen',
        failedEmail: 'Anmeldung per E-Mail fehlgeschlagen',
        noEmail: 'Keine E-Mail gefunden',
      },
      terms: 'Mit der Anmeldung bestätige ich, dass ich die',
    },
    register: {
      title: 'Erstellen Sie Ihr Konto',
      haveAccount: 'Haben Sie bereits ein Konto?',
      terms: 'Mit der Erstellung eines Kontos bestätige ich, dass ich die',
    },
    common: {
      termsOfService: 'Nutzungsbedingungen',
      privacyPolicy: 'Datenschutzerklärung',
      and: 'und',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['Karten', 'Spielmarken', 'Embleme', 'Planeswalker', 'Kommandeure', 'Länder', 'Kreaturen', 'Artefakte', 'Verzauberungen', 'Spontanzauber'],
      description: 'MTG Proxies hilft dir dabei, Magic: The Gathering Proxy-Karten für deine Casual-Decks kostenlos zu erstellen!',
      search: {
        cards: {
          tab: 'Karten',
          placeholder: 'Nach Magic-Karten suchen...',
          error: 'Fehler beim Laden zufälliger Karten',
        },
        sets: {
          tab: 'Editionen',
          placeholder: 'Nach Magic-Editionen suchen...',
        },
        tokens: {
          tab: 'Spielmarken',
          placeholder: 'Nach Magic-Spielmarken suchen...',
        },
        button: 'Suchen',
        hint: {
          cards: 'Drücke Enter oder klicke auf Suchen, um deine Karten zu finden',
          sets: 'Drücke Enter oder klicke auf Suchen, um deine Editionen zu finden',
          tokens: 'Drücke Enter oder klicke auf Suchen, um deine Spielmarken zu finden',
        },
        aria: {
          searchIcon: 'Suchsymbol',
          searchInput: {
            cards: 'Nach Karten suchen',
            sets: 'Nach Editionen suchen',
            tokens: 'Nach Spielmarken suchen',
          },
        },
      },
      images: {
        cardAlt: 'Magic-Kartenbild',
      },
    },
    features: {
      section1: {
        badge: 'Proxies Drucken',
        title: 'MTG Proxy-Karten Drucken',
        subtitle: 'Füge Karten deiner Druckliste hinzu, drucke sie aus und verwende sie noch heute in deinen Decks!',
        features: [
          {
            name: 'Füge Karten deiner Druckliste hinzu',
            description: 'Suche nach beliebigen Karten über die Suchseite oder durchsuche die Editionen und füge die gewünschten Karten deiner Druckliste hinzu.',
          },
          {
            name: 'Drucke deine Karten',
            description: 'Sobald du alle gewünschten Karten deiner Druckliste hinzugefügt hast, kannst du sie ausdrucken und in deinen Decks verwenden.',
          },
          {
            name: 'Verwende sie in deinen Decks',
            description: 'Sobald du deine Karten ausgedruckt hast, kannst du sie in deinen Decks verwenden. Schneide sie aus und klebe sie auf ein Standardland oder verwende eine Hülle mit einem Standardland darin.',
          },
        ],
        images: {
          cartScreenshot: 'Warenkorb-Screenshot zeigt, wie man Karten zur Druckliste hinzufügt',
        },
      },
      section2: {
        badge: 'Deck-Builder',
        title: 'Magic: The Gathering Decks Erstellen und Verwalten',
        subtitle: 'Erstelle und verwalte deine eigenen Decks oder durchstöbere öffentliche Decks der Community.',
        features: [
          {
            name: 'Verwalte deine MTG Decks',
            description: 'Alle Benutzer können ein MTG-Deck erstellen und verwalten. Pro-Mitglieder können unbegrenzt viele Decks erstellen.',
          },
          {
            name: 'Drucke deine Karten',
            description: 'Sobald du alle gewünschten Karten deinem Deck hinzugefügt hast, kannst du sie auf einmal deiner Druckliste hinzufügen.',
          },
          {
            name: 'Durchsuche Öffentliche Decks',
            description: 'Füge komplette Decks der Community deiner Druckliste hinzu, um neue Decks einfach zu testen.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Deck-Builder-Oberfläche zeigt, wie man Decks verwaltet',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, Alle Rechte vorbehalten.',
    disclaimer: 'MTG Proxies wird nicht von Wizards of the Coast produziert, unterstützt oder ist damit verbunden.',
    affiliate: 'Als TCGplayer-Affiliate-Partner kann MTG Proxies eine Provision für qualifizierte Käufe erhalten.',
    builtBy: 'Erstellt von',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Datenschutzrichtlinie',
    termsOfService: 'Nutzungsbedingungen',
    support: 'Support',
  },
  sort: {
    nameAsc: 'Name (A bis Z)',
    nameDesc: 'Name (Z bis A)',
    cmcAsc: 'Manawert (Niedrig zu Hoch)',
    cmcDesc: 'Manawert (Hoch zu Niedrig)',
    usdAsc: 'Preis (Niedrig zu Hoch)',
    usdDesc: 'Preis (Hoch zu Niedrig)',
    rarityAsc: 'Seltenheit (Gewöhnlich zu Mythisch)',
    rarityDesc: 'Seltenheit (Mythisch zu Gewöhnlich)',
    releasedDesc: 'Erscheinungsdatum (Neueste zuerst)',
    releasedAsc: 'Erscheinungsdatum (Älteste zuerst)',
  },
  pages: {
    search: {
      heading: 'Suchen',
    },
    sets: {
      heading: 'Kartensets',
      latestSets: 'Neueste Sets',
      upcomingSets: 'Kommende Sets',
      allSets: 'Alle Sets',
      noResults: 'Keine Ergebnisse gefunden.',
    },
    set: {
      notFound: 'Set nicht gefunden.',
      noCards: 'Keine Karten in diesem Set gefunden.',
      addAll: '{{count}} Karten zur Druckliste hinzufügen',
      addedToList: '{{count}} Karten zur Druckliste hinzugefügt',
    },
    tokens: {
      heading: 'Spielmarken',
    },
    emblems: {
      heading: 'Embleme',
    },
    import: {
      heading: 'Karten zur Druckliste importieren',
      description: 'Geben Sie die Namen aller Karten ein, die Sie zur Druckliste hinzufügen möchten.',
    },
    topProxies: {
      heading: 'Top-Proxies',
      description: 'Hier sind die 50 beliebtesten Magic: The Gathering Proxy-Karten auf MTG Proxies.',
    },
  },
  languages: {
    default: 'Standard',
    any: 'Alle',
    english: 'Englisch',
    spanish: 'Spanisch',
    french: 'Französisch',
    german: 'Deutsch',
    italian: 'Italienisch',
    portuguese: 'Portugiesisch',
    japanese: 'Japanisch',
    korean: 'Koreanisch',
    russian: 'Russisch',
    simplified_chinese: 'Vereinfachtes Chinesisch',
    traditional_chinese: 'Traditionelles Chinesisch',
    hebrew: 'Hebräisch',
    latin: 'Latein',
    ancient_greek: 'Altgriechisch',
    arabic: 'Arabisch',
    sanskrit: 'Sanskrit',
    phyrexian: 'Phyrexianisch',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Pro-Filter anzeigen',
    hide: '✨ Pro-Filter ausblenden',
  },
  search: {
    showing_start: 'Zeige',
    showing_to: 'bis',
    showing_of: 'von',
    showing_results: 'Ergebnissen',
    noResults: 'Keine Ergebnisse gefunden.',
    useSearchTip: 'Verwenden Sie die Suche oben auf der Seite, um nach einer Karte zu suchen.',
  },
  advancedFilters: {
    colors: 'Farben',
    rarity: 'Seltenheit',
    cardTypes: 'Kartentypen',
    format: 'Format',
    manaValue: 'Manawert',
    cardName: 'Kartenname',
    cardText: 'Kartentext',
    specialCharacteristics: 'Besondere Eigenschaften',
    activeFilters: 'Aktive Filter',
    clearAll: 'Alle löschen',
    applyFilters: 'Filter anwenden',
    searchCardName: 'Kartenname suchen...',
    searchCardText: 'Kartentext suchen...',
    namePrefix: 'Name: ',
    manaPrefix: 'Mana: ',
    textPrefix: 'Text: ',
    removeFromFilter: '✕',
    selectLanguage: 'Sprache auswählen',
  },
  card: {
    addToPrintList: 'Zur Druckliste hinzufügen',
    removeFromPrintList: 'Von Druckliste entfernen',
    cardsInPrintList: 'Karte in Druckliste',
    cardsInPrintListPlural: 'Karten in Druckliste',
    buyOn: 'Auf TCGplayer kaufen',
    buyFor: 'Kaufen für ',
    viewDetails: 'Details anzeigen',
    type: 'Typ',
    manaCost: 'Manakosten',
    rarity: 'Seltenheit',
    artist: 'Künstler',
    collectorNumber: 'Sammlernummer',
    oracleText: 'Oracle-Text',
    youMightAlsoLike: 'Das könnte dir auch gefallen...',
    noRecommendations: 'Keine Empfehlungen verfügbar.',
    details: 'Details',
    rulings: 'Regeln',
    legality: 'Legalität',
    share: 'Teilen',
    favorite: 'Favorit',
    noRulings: 'Keine Regeln für diese Karte verfügbar.',
    noLegalityInfo: 'Keine Legalitätsinformationen für diese Karte verfügbar.',
    published: 'Veröffentlicht',
    legal: 'Legal',
    restricted: 'Eingeschränkt',
    banned: 'Verboten',
    format: 'Format',
    status: 'Status',
    viewMore: 'Mehr anzeigen',
    viewLess: 'Weniger anzeigen',
    customizeWithAI: 'Mit KI anpassen',
    otherVersions: 'Einzigartige Drucke',
    showUniqueVersions: 'Einzigartige Drucke anzeigen',
    hideUniqueVersions: 'Einzigartige Drucke ausblenden',
  },
  cart: {
    title: 'Druckliste',
    closePanel: 'Panel schließen',
    empty: 'Keine Proxies in der Druckliste.',
    addPadding: 'Abstand hinzufügen',
    paperSize: 'Papiergröße',
    scale: 'Skalierung',
    clearCardList: 'Liste leeren',
    print: 'Drucken',
    success: 'Erfolg!',
  },
  setsList: {
    showing: 'Zeige',
    to: 'bis',
    of: 'von',
    results: 'Ergebnissen',
    searchPlaceholder: 'Suchen...',
  },
};

export default translations;
