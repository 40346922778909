import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { SUPPORTED_LANGUAGES } from '@/config/languages';
import { Globe } from 'lucide-react';
import { useRouter } from 'next/router';
import { useTranslation } from '../hooks/useTranslation';

export const LanguageSwitcher = () => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const activeLocale = router.locale;
  const { t } = useTranslation();

  const handleLanguageChange = (locale) => {
    router.push({ pathname, query }, asPath, { locale });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='ghost'>
          <Globe className='h-5 w-5' />
          <span className='hidden 2xl:block'>{t('common.language')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end'>
        {Object.entries(SUPPORTED_LANGUAGES).map(([code, language]) => (
          <DropdownMenuItem key={code} onClick={() => handleLanguageChange(code)} className={activeLocale === code ? 'bg-accent hover:bg-accent text-accent-foreground' : ''}>
            {language.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
