import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Cart from '../components/Cart';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import StoreResolvers from '../components/StoreResolvers';
import { CartProvider } from '../hooks/useCart';
import { PDFProvider } from '../hooks/usePDF';
import { ScryfallProvider } from '../hooks/useScryfall';
import '../styles/globals.css';
import { Toaster } from 'sonner';
import { TranslationProvider } from '@/hooks/useTranslation';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <TranslationProvider>
      <QueryClientProvider client={queryClient}>
        <ScryfallProvider>
          <PDFProvider>
            <CartProvider>
              <div className='flex flex-col min-h-full'>
                <Navbar />
                <main className='flex-grow'>
                  <Component {...pageProps} />
                </main>
                <Footer />
              </div>
              <Cart />
              <Toaster position='bottom-center' />
              <StoreResolvers />
            </CartProvider>
          </PDFProvider>
        </ScryfallProvider>
      </QueryClientProvider>
    </TranslationProvider>
  );
}

export default MyApp;
