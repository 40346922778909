const CONFIG = {
  title: 'MTG Proxies',
};

export const STORAGE_KEYS = {
  CART: 'Cart',
  PDF_PADDING: 'PDF_Padding',
  PDF_FORMAT: 'PDF_Format',
  PDF_SCALE: 'PDF_Scale',
};

export const MTG_FORMATS = [
  { id: 'standard', name: 'Standard', maxCards: 60, minCards: 60 },
  { id: 'modern', name: 'Modern', maxCards: 60, minCards: 60 },
  { id: 'legacy', name: 'Legacy', maxCards: 60, minCards: 60 },
  { id: 'vintage', name: 'Vintage', maxCards: 60, minCards: 60 },
  { id: 'commander', name: 'Commander', maxCards: 100, minCards: 100 },
  { id: 'pauper', name: 'Pauper', maxCards: 60, minCards: 60 },
  { id: 'pioneer', name: 'Pioneer', maxCards: 60, minCards: 60 },
  { id: 'brawl', name: 'Brawl', maxCards: 60, minCards: 60 },
  { id: 'historic', name: 'Historic', maxCards: 60, minCards: 60 },
  { id: 'custom', name: 'Custom', maxCards: null, minCards: null },
];

export const ARTICLES = [
  {
    id: 13,
    title: 'How to Create Perfect MTG Proxies: Video Tutorial',
    href: '/articles/mtg-proxies-tutorial-video',
    description: 'Learn how to use MTG Proxies to create high-quality proxy cards for casual play and deck testing. This video guide walks you how to create perfect MTG proxies.',
    date: 'Apr 1, 2025',
    datetime: '2025-04-01',
    image: '/articles/proxy-tutorial.png',
  },
  {
    id: 12,
    title: 'Language Switcher: Browse MTG Proxies in Your Language',
    href: '/articles/language-switcher-feature',
    description: 'The new language switcher feature allows you to browse MTG Proxies in multiple languages. This enhancement improves accessibility and provides a more personalized experience for our global community.',
    date: 'Mar 27, 2025',
    datetime: '2025-03-27',
    image: '/articles/translations.png',
  },
  {
    id: 11,
    title: 'Language Select for Card Search',
    href: '/articles/language-select-for-search',
    description: 'Learn how to use the language select feature to find Magic: The Gathering cards in different languages.',
    date: 'Mar 20, 2025',
    datetime: '2025-03-20',
    image: '/articles/language-select.png',
  },
  {
    id: 10,
    title: 'The Ultimate Guide to MTG Proxies Deck Builder: Build, Share, and Discover',
    href: '/articles/deck-builder',
    description: 'Learn all about the powerful features of the MTG Proxies Deck Builder, from creating custom decks to discovering community creations through Public Decks.',
    date: 'Mar 10, 2025',
    datetime: '2025-03-10',
    image: '/deck-builder.png',
  },
  {
    id: 9,
    title: 'Site Updates - Mar 8, 2025',
    href: '/articles/site-updates-mar-8-2025',
    description: 'Introducing the new Advanced Filters for Pro Members.',
    date: 'Mar 8, 2025',
    datetime: '2025-03-08',
    image: '/articles/advanced-filters-2.png',
  },
  {
    id: 8,
    title: 'Printing Proxy Cards on Holographic Sticker Paper',
    href: '/articles/printing-proxy-cards-on-holographic-paper',
    description: 'A guide to printing proxy cards on holographic sticker paper to make your proxies look amazing.',
    date: 'Mar 7, 2025',
    datetime: '2025-03-07',
    image: '/articles/holofoil-lands.jpg',
  },
  {
    id: 7,
    title: 'Site Updates - Mar 6, 2025',
    href: '/articles/site-updates-mar-6-2025',
    description: 'A list of the latest changes and improvements to the site.',
    date: 'Mar 6, 2025',
    datetime: '2025-03-06',
    image: '/cart-3.png',
  },
  {
    id: 6,
    title: 'Site Updates - Feb 28, 2025',
    href: '/articles/site-updates-feb-28-2025',
    description: 'A list of the latest changes and improvements to the site.',
    date: 'Feb 28, 2025',
    datetime: '2025-02-28',
    image: '/articles/elements.png',
  },
  {
    id: 5,
    title: 'The Benefits of Playing with MTG Proxies',
    href: '/articles/benefits-of-playing-with-mtg-proxies',
    description: `MTG Proxies provide a way for players to experience the game without the burden of high card costs. This article dives into the various benefits of using proxies in your casual and playtesting sessions.`,
    date: 'Oct 4, 2024',
    datetime: '2024-10-04',
    image: '/articles/playing.jpg',
  },
  {
    id: 4,
    title: 'Top 10 MTG Proxy Cards for an Unbeatable Deck',
    href: '/articles/top-10-mtg-proxy-cards',
    description: `Proxy cards allow players to experiment with different strategies without the financial burden of collecting rare and expensive cards. In this article, we'll explore the top 10 Magic: The Gathering proxy cards that can give you an edge in building an unbeatable deck.`,
    date: 'Dec 8, 2023',
    datetime: '2023-12-08',
    image: '/articles/jace.jpeg',
  },
  {
    id: 3,
    title: 'The Evolution of MTG Proxies: A Journey from Handmade to High-Quality',
    href: '/articles/evolution-of-mtg-proxies',
    description: 'Explore the fascinating evolution of Magic: The Gathering proxies, tracing their journey from simple handmade versions to advanced, high-quality prints, and uncover the impact on the Magic: The Gathering community and gameplay.',
    date: 'Dec 6, 2023',
    datetime: '2023-12-06',
    image: '/articles/pile-3.jpeg',
  },
  {
    id: 2,
    title: 'Using MTG proxies in Casual Play',
    href: '/articles/using-mtg-proxies-in-casual-play',
    description: 'Explore the ethical aspects of using MTG proxies in casual games. Understand the balance between fun and fairness in using printed proxies.',
    date: 'Nov 19, 2023',
    datetime: '2023-11-19',
    image: '/articles/pile.jpg',
  },
  {
    id: 1,
    title: 'A Step-by-Step Guide to Printing MTG Proxies',
    href: '/articles/step-by-step-guide-to-printing-mtg-proxies',
    description: 'Discover how to print proxies for your MTG decks using MTG Proxies. This guide walks you through the process of searching, selecting, and printing proxies for your MTG deck.',
    date: 'Nov 18, 2023',
    datetime: '2023-11-18',
    image: '/articles/black-lotus.webp',
  },
];
