import { useQuery } from '@tanstack/react-query';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { auth, getUserProfile } from '../utilities/firebase';

export const useUserStore = create(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),

      customerDetails: null,
      setCustomerDetails: (customerDetails) => set({ customerDetails }),

      userProfile: null,
      setUserProfile: (userProfile) => set({ userProfile }),
    }),
    {
      name: 'MTGProxies.User',
    }
  )
);

export const useUserDetailsQuery = () => {
  const { user } = useUserStore();

  return useQuery({
    queryKey: ['user'],
    queryFn: () => fetch(`/api/user?email=${user.email}`).then((res) => res.json()),
    enabled: !!user,
  });
};

export const useUserProfileQuery = () => {
  const { user } = useUserStore();

  return useQuery({
    queryKey: ['userProfile', user?.uid],
    queryFn: () => getUserProfile(user.uid),
    enabled: !!user,
  });
};

export const useUserResolver = () => {
  const { setUser, setCustomerDetails, setUserProfile } = useUserStore();
  const userDetailsQuery = useUserDetailsQuery();
  const userProfileQuery = useUserProfileQuery();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
        setUserProfile(null);
      }
    });
  }, []);

  useEffect(() => {
    if (userDetailsQuery.data) {
      setCustomerDetails(userDetailsQuery.data);
    }
  }, [userDetailsQuery.data]);

  useEffect(() => {
    if (userProfileQuery.data) {
      setUserProfile(userProfileQuery.data);
    }
  }, [userProfileQuery.data]);
};
