const translations = {
  common: {
    search: '検索',
    signIn: 'サインイン',
    register: '登録',
    myAccount: 'マイアカウント',
    support: 'サポート',
    followUs: 'X/Twitterでフォロー',
    proMembership: 'プロメンバーシップ',
    new: '新規',
    proMember: 'プロメンバー',
    upgrade: 'アップグレード',
    subscribe: '購読',
    manage: '管理',
    cancel: 'キャンセル',
    close: '閉じる',
    or: 'または',
    email: 'メールアドレス',
    continue: '続ける',
    submit: '送信',
    billing: '請求',
    sort: '並び替え',
    language: '言語',
    selectLanguage: '言語を選択',
    searchPlaceholder: '検索...',
    cards: 'カード',
    previous: '前へ',
    next: '次へ',
    pagination: 'ページネーション',
    back: '戻る',
    backToSets: 'セットに戻る',
    error: 'エラーが発生しました。もう一度お試しください。',
    home: 'ホーム',
    sets: 'セット',
  },
  theme: {
    light: 'ライト',
    dark: 'ダーク',
    toggle: 'テーマ切替',
  },
  cta: {
    title: '始める準備はできましたか？',
    subtitle: '今日からプロキシカードを作成しましょう。',
    searchByCard: 'カードで検索',
    searchBySet: 'セットで検索',
  },
  nav: {
    proxyCards: 'プロキシカード',
    sets: 'セット',
    tokens: 'トークン',
    emblems: 'エンブレム',
    import: 'インポート',
    topProxies: '人気のプロキシ',
    customCards: 'カスタムカード',
    deckBuilder: 'デッキビルダー',
    buildDeck: 'デッキを作成',
    publicDecks: '公開デッキ',
    favoriteDeck: 'お気に入りデッキ',
    articles: '記事',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Magic: The Gatheringのプロキシカード',
      description: 'Magic: The Gatheringのプロキシカードの広大なライブラリにアクセス。カジュアルプレイ用のMTGカード画像を簡単に表示・印刷。今すぐ夢のデッキを作り始めましょう！',
    },
    sets: {
      title: 'カードセット - MTG Proxies',
      description: 'すべてのMTGセットを閲覧し、デッキ用のプロキシを印刷。',
    },
    tokens: {
      title: 'トークン - MTG Proxies',
      description: '様々なプロキシトークンでMTGゲームを強化。',
    },
    emblems: {
      title: 'エンブレム - MTG Proxies',
      description: 'デッキ用のMTGエンブレムプロキシカードを印刷。',
    },
    search: {
      title: 'MTGカード検索 - MTG Proxies',
      description: 'MTGプロキシカードを簡単に検索・印刷。検索ツールを使用して広大なデータベースにアクセスし、Magic: The Gatheringのデッキをプロキシで強化。',
    },
    support: {
      title: 'サポート - MTG Proxies',
      description: '質問、フィードバック、またはトークン/エンブレムの追加をご希望の場合は、メールでお問い合わせください。',
    },
    articles: {
      title: '記事 - MTG Proxies',
      description: 'Magic: The Gatheringとプロキシカードについての記事。',
    },
    import: {
      title: 'インポート - MTG Proxies',
      description: 'カードリストをインポートしてMagic: The Gatheringのプロキシを印刷。',
    },
    topProxies: {
      title: '人気のプロキシ - MTG Proxies',
      description: '最も人気のあるMagic: The Gatheringプロキシカードを表示。',
    },
    customCards: {
      title: 'カスタムカード - MTG Proxies',
      description: '独自のMagic: The Gatheringプロキシカードを作成。',
    },
    login: {
      title: 'サインイン - MTG Proxies',
      description: 'アカウントにサインインしてカスタムカード、デッキリストなどを管理。',
    },
    register: {
      title: '登録 - MTG Proxies',
      description: 'アカウントを登録してカスタムカード、デッキリストなどを管理。',
    },
    termsOfService: {
      title: '利用規約 - MTG Proxies',
      description: 'サイトの利用規約を読む。',
    },
    privacyPolicy: {
      title: 'プライバシーポリシー - MTG Proxies',
      description: 'サイトのプライバシーポリシーを読む。',
    },
    account: {
      title: 'アカウント - MTG Proxies',
      description: 'アカウントを管理。',
    },
    proMembership: {
      title: 'Proメンバーシップ - MTG Proxies',
      description: 'Proメンバーシップに登録してサイトのすべての機能にアクセス。',
    },
    deckBuilder: {
      title: 'デッキビルダー - MTG Proxies',
      description: 'Magic: The Gatheringのデッキを作成・管理。',
    },
    deckEditor: {
      title: 'デッキエディター - MTG Proxies',
      description: 'Magic: The Gatheringのデッキを作成・編集。',
    },
    publicDecks: {
      title: '公開デッキ - MTG Proxies',
      description: 'コミュニティが共有するMTGの公開デッキを閲覧・探索。',
    },
  },
  pro: {
    pricing: {
      title: 'Pro MTGカードプロキシ作成をアンロック',
      subtitle: '広告なしのカードプロキシ作成とすべての機能・特典へのアクセスを得るためにProメンバーシップにアップグレード。',
      monthlyPlan: '月間Proメンバーシップ',
      yearlyPlan: '年間Proメンバーシップ',
      monthlyPrice: '月額5.00ドル',
      yearlyPrice: '年額40.00ドル',
      subscribeMonthly: '月間購読',
      subscribeYearly: '年間購読',
      signInToSubscribe: 'サインインして購読',
      manageSubscription: '購読を管理',
      nextBillingDate: '次回請求日',
      benefitsEndDate: '特典終了日',
      viewInvoice: '請求書を表示',
      changePlan: 'プランを変更',
      reactivate: '再開',
      active: 'アクティブ',
      cancelled: 'キャンセル済み',
    },
    benefits: {
      title: 'なぜMTG Proxies Proメンバーシップなのか？',
      subtitle: 'プレミアム機能をアンロックし、Proメンバーシップで快適なカードプロキシ作成体験を楽しみましょう。',
      noAds: {
        title: '広告なし',
        description: '邪魔な広告、バナー、ポップアップなしでプロキシを閲覧・作成。クリーンで集中できる体験を楽しめます。',
      },
      darkMode: {
        title: 'ダークモード',
        description: 'エレガントなダークインターフェースで目の疲れを軽減。夜間閲覧や低照度環境に最適です。',
      },
      advancedSearch: {
        title: '高度な検索フィルター',
        description: '必要なカードを正確に見つけるための強力な検索フィルター。色アイデンティティ、レアリティ、カードタイプでフィルタリング。',
      },
      multipleDecks: {
        title: '複数デッキの管理',
        description: 'デッキビルダーで複数のデッキを作成・管理。お気に入りのデッキをすべて保存して素早くアクセス。',
      },
      futureFeatures: {
        title: '今後のPro機能',
        description: '新しいプレミアム機能がリリースされたら即座にアクセス。Proメンバーは常に最初にアクセスできます。',
      },
      cancelAnytime: {
        title: 'いつでもキャンセル可能',
        description: '長期契約の必要なし。購読はいつでも柔軟にキャンセルできます。',
      },
      prioritySupport: {
        title: '優先サポート',
        description: '専用の優先サポートで待ち時間なし。質問や懸念事項は最優先で丁寧に対応します。',
      },
    },
    features: {
      unlimitedDecks: '無制限のデッキ作成',
      noAds: '広告なし',
      prioritySupport: '優先サポート',
      darkMode: 'ダークモードアクセス',
      advancedSearch: '高度な検索フィルター',
    },
    promotion: {
      title: 'より多くのデッキを作成するためにProにアップグレード',
      description: 'Proメンバーは無制限にデッキを作成できます。今すぐアップグレードしてこの機能をアンロック！',
      customizeTitle: 'Pro機能：AIカードカスタマイズ',
      customizeDescription: 'AIを使用してカスタムカードバリエーションを作成するためにProにアップグレード。Proメンバーは1日に最大5枚のカスタムカードを生成できます。',
    },
    account: {
      title: 'Proメンバーシップ',
      description: '購読の詳細を管理',
      subscribeToPro: 'Proメンバーシップに購読',
    },
  },
  auth: {
    signIn: {
      title: 'アカウントにサインイン',
      withGoogle: 'Googleで続ける',
      withEmail: 'または次で続ける',
      sendMagicLink: 'マジックリンクを送信',
      noAccount: 'アカウントをお持ちでない方',
      checkEmail: 'サインインリンクをメールで確認してください',
      errors: {
        failed: 'サインインに失敗しました',
        failedGoogle: 'Googleでのサインインに失敗しました',
        failedEmail: 'メールでのサインインに失敗しました',
        noEmail: 'メールが見つかりません',
      },
      terms: 'サインインすることで、以下に同意したことを確認します：',
    },
    register: {
      title: 'アカウントを作成',
      haveAccount: 'すでにアカウントをお持ちですか？',
      terms: 'アカウントを作成することで、以下に同意したことを確認します：',
    },
    common: {
      termsOfService: '利用規約',
      privacyPolicy: 'プライバシーポリシー',
      and: 'および',
    },
  },
  home: {
    hero: {
      title: 'プロキシ',
      rotatingWords: ['カード', 'トークン', 'エンブレム', 'プレインズウォーカー', '統率者', '土地', 'クリーチャー', 'アーティファクト', 'エンチャント', 'インスタント'],
      description: 'MTG Proxiesは、カジュアルデッキで使用するMagic: The Gatheringのプロキシカードを無料で作成できるサービスです！',
      search: {
        cards: {
          tab: 'カード',
          placeholder: 'マジックカードを検索...',
          error: 'ランダムカードの読み込みに失敗しました',
        },
        sets: {
          tab: 'セット',
          placeholder: 'マジックセットを検索...',
        },
        tokens: {
          tab: 'トークン',
          placeholder: 'マジックトークンを検索...',
        },
        button: '検索',
        hint: {
          cards: 'Enterキーを押すか検索をクリックしてカードを探す',
          sets: 'Enterキーを押すか検索をクリックしてセットを探す',
          tokens: 'Enterキーを押すか検索をクリックしてトークンを探す',
        },
        aria: {
          searchIcon: '検索アイコン',
          searchInput: {
            cards: 'カードを検索',
            sets: 'セットを検索',
            tokens: 'トークンを検索',
          },
        },
      },
      images: {
        cardAlt: 'マジックカードの画像',
      },
    },
    features: {
      section1: {
        badge: 'プロキシを印刷',
        title: 'MTGプロキシカードを印刷',
        subtitle: 'カードを印刷リストに追加し、印刷して今すぐデッキで使用できます！',
        features: [
          {
            name: '印刷リストにカードを追加',
            description: '検索ページでカードを探すか、セットを閲覧して、欲しいカードを印刷リストに追加できます。',
          },
          {
            name: 'カードを印刷',
            description: '印刷リストに追加したいカードをすべて追加したら、印刷してデッキで使用できます。',
          },
          {
            name: 'デッキで使用',
            description: 'カードを印刷したら、デッキで使用できます。カードを切り取って基本土地に貼り付けるか、基本土地を入れたスリーブの中に入れて使用します。',
          },
        ],
        images: {
          cartScreenshot: '印刷リストにカードを追加する方法を示すカート画面のスクリーンショット',
        },
      },
      section2: {
        badge: 'デッキビルダー',
        title: 'Magic: The Gatheringのデッキを作成・管理',
        subtitle: '自分のデッキを作成・管理したり、コミュニティが作成した公開デッキを閲覧したりできます。',
        features: [
          {
            name: 'MTGデッキを管理',
            description: 'すべてのユーザーは1つのMTGデッキを作成・管理できます。Proメンバーは無制限にデッキを作成できます。',
          },
          {
            name: 'カードを印刷',
            description: 'デッキに追加したいカードをすべて追加したら、一度に印刷リストに追加できます。',
          },
          {
            name: '公開デッキを閲覧',
            description: 'コミュニティが作成した完全なデッキを印刷リストに追加して、新しいデッキを簡単にテストできます。',
          },
        ],
        images: {
          deckBuilderScreenshot: 'デッキの管理方法を示すデッキビルダーインターフェース',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies、全著作権所有。',
    disclaimer: 'MTG Proxiesは、Wizards of the Coastによって製作、承認、支援、または提携されていません。',
    affiliate: 'TCGplayerのアフィリエイトパートナーとして、MTG Proxiesは対象となる購入に対して手数料を得ることがあります。',
    builtBy: '製作者',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'プライバシーポリシー',
    termsOfService: '利用規約',
    support: 'サポート',
  },
  sort: {
    nameAsc: '名前（A〜Z）',
    nameDesc: '名前（Z〜A）',
    cmcAsc: 'マナ値（低い順）',
    cmcDesc: 'マナ値（高い順）',
    usdAsc: '価格（安い順）',
    usdDesc: '価格（高い順）',
    rarityAsc: 'レアリティ（コモンからミシック）',
    rarityDesc: 'レアリティ（ミシックからコモン）',
    releasedDesc: '発売日（新しい順）',
    releasedAsc: '発売日（古い順）',
  },
  pages: {
    search: {
      heading: '検索',
    },
    sets: {
      heading: 'カードセット',
      latestSets: '最新のセット',
      upcomingSets: '近日発売のセット',
      allSets: 'すべてのセット',
      noResults: '結果が見つかりません。',
    },
    set: {
      notFound: 'セットが見つかりません。',
      noCards: 'このセットにカードが見つかりません。',
      addAll: '{{count}}枚のカードを印刷リストに追加',
      addedToList: '{{count}}枚のカードを印刷リストに追加しました',
    },
    tokens: {
      heading: 'トークン',
    },
    emblems: {
      heading: 'エンブレム',
    },
    import: {
      heading: '印刷リストにカードをインポート',
      description: '印刷リストに追加したいすべてのカード名を入力してください。',
    },
    topProxies: {
      heading: '人気のプロキシ',
      description: 'MTG Proxiesで最も人気のある50枚のMagic: The Gatheringプロキシカードです。',
    },
  },
  languages: {
    default: 'デフォルト',
    any: 'すべて',
    english: '英語',
    spanish: 'スペイン語',
    french: 'フランス語',
    german: 'ドイツ語',
    italian: 'イタリア語',
    portuguese: 'ポルトガル語',
    japanese: '日本語',
    korean: '韓国語',
    russian: 'ロシア語',
    simplified_chinese: '簡体字中国語',
    traditional_chinese: '繁体字中国語',
    hebrew: 'ヘブライ語',
    latin: 'ラテン語',
    ancient_greek: '古代ギリシャ語',
    arabic: 'アラビア語',
    sanskrit: 'サンスクリット語',
    phyrexian: 'ファイレクシア語',
    quenya: 'クエンヤ語',
  },
  filters: {
    show: '✨ Proフィルターを表示',
    hide: '✨ Proフィルターを非表示',
  },
  search: {
    showing_start: '表示中：',
    showing_to: 'から',
    showing_of: '件中',
    showing_results: '件の結果',
    noResults: '結果が見つかりません。',
    useSearchTip: 'ページ上部の検索を使用してカードを検索してください。',
  },
  advancedFilters: {
    colors: '色',
    rarity: 'レアリティ',
    cardTypes: 'カードタイプ',
    format: 'フォーマット',
    manaValue: 'マナ値',
    cardName: 'カード名',
    cardText: 'カードテキスト',
    specialCharacteristics: '特殊特性',
    activeFilters: 'アクティブフィルター',
    clearAll: 'すべてクリア',
    applyFilters: 'フィルターを適用',
    searchCardName: 'カード名を検索...',
    searchCardText: 'カードテキストを検索...',
    namePrefix: '名前：',
    manaPrefix: 'マナ：',
    textPrefix: 'テキスト：',
    removeFromFilter: '✕',
    selectLanguage: '言語を選択',
  },
  card: {
    addToPrintList: '印刷リストに追加',
    removeFromPrintList: '印刷リストから削除',
    cardsInPrintList: '枚のカードが印刷リストにあります',
    cardsInPrintListPlural: '枚のカードが印刷リストにあります',
    buyOn: 'TCGplayerで購入',
    buyFor: '購入価格：',
    viewDetails: '詳細を表示',
    type: 'タイプ',
    manaCost: 'マナ・コスト',
    rarity: 'レアリティ',
    artist: 'アーティスト',
    collectorNumber: 'コレクター番号',
    oracleText: 'オラクル・テキスト',
    youMightAlsoLike: 'おすすめのカード...',
    noRecommendations: '現在おすすめのカードはありません。',
    details: '詳細',
    rulings: 'ルール',
    legality: '適正性',
    share: '共有',
    favorite: 'お気に入り',
    noRulings: 'このカードのルールはありません。',
    noLegalityInfo: 'このカードの適正性情報はありません。',
    published: '発行',
    legal: '適正',
    restricted: '制限',
    banned: '禁止',
    format: 'フォーマット',
    status: 'ステータス',
    viewMore: 'もっと見る',
    viewLess: '閉じる',
    customizeWithAI: 'AIでカスタマイズ',
    otherVersions: 'ユニークな印刷',
    showUniqueVersions: 'ユニークな印刷を表示',
    hideUniqueVersions: 'ユニークな印刷を非表示',
  },
  cart: {
    title: '印刷リスト',
    closePanel: 'パネルを閉じる',
    empty: '印刷リストにプロキシがありません。',
    addPadding: 'パディングを追加',
    paperSize: '用紙サイズ',
    scale: '拡大縮小',
    clearCardList: 'リストをクリア',
    print: '印刷',
    success: '成功！',
  },
  setsList: {
    showing: '表示中：',
    to: '～',
    of: '/',
    results: '件',
    searchPlaceholder: '検索...',
  },
};

export default translations;
