import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useTranslation } from './useTranslation';
import { getRemainingGenerations } from '../utilities/rate-limit';

interface GenerateCardProxyParams {
  cardId: string;
  originalImage: string;
  customText: string;
  userEmail: string;
}

interface GenerateCardProxyResponse {
  imageUrl: string;
}

export const useGenerateCardProxy = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: GenerateCardProxyParams) => {
      const response = await fetch('/api/generate-card-proxy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Email': params.userEmail,
        },
        body: JSON.stringify({
          cardId: params.cardId,
          originalImage: params.originalImage,
          customText: params.customText,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || t('customizeCardModal.error.failed'));
      }

      return response.json() as Promise<GenerateCardProxyResponse>;
    },
    onSuccess: () => {
      // Invalidate the remaining generations query to trigger a refetch
      queryClient.invalidateQueries({ queryKey: ['remainingGenerations'] });
    },
  });
};

export const useRemainingGenerations = (stripeCustomerId: string | undefined) => {
  return useQuery({
    queryKey: ['remainingGenerations', stripeCustomerId],
    queryFn: () => getRemainingGenerations(stripeCustomerId!),
    enabled: !!stripeCustomerId,
  });
};
