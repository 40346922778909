// @ts-nocheck

import { useFiltersStore } from '@/hooks/useFiltersStore';
import { useTranslation } from '@/hooks/useTranslation';
import { cn } from '@/lib/utils';
import { MenuIcon, SearchIcon } from '@heroicons/react/outline';
import { Printer, UserIcon } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCart } from '../hooks/useCart';
import { useUserDetailsQuery, useUserStore } from '../hooks/useUser';
import Badge from './Badge';
import Container from './Container';
import Form from './Form';
import { LanguageSwitcher } from './LanguageSwitcher';
import Logo from './Logo';
import MobileDrawer from './MobileDrawer';
import ThemeToggle from './ThemeToggle';
import { Button } from './ui/button';
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle } from './ui/navigation-menu';

export default function Navbar() {
  const router = useRouter();
  const { openCart, getNumberOfItemsInCart } = useCart();
  const [mobileDrawerActive, setMobileDrawerActive] = useState(false);
  const userDetailsQuery = useUserDetailsQuery();
  const { user, customerDetails } = useUserStore();
  const { setFilters } = useFiltersStore();
  const { t } = useTranslation();

  const form = useForm();

  const handleSubmit = (data) => {
    setFilters({
      cardName: data.search,
    });

    router.push({
      pathname: '/search',
      query: { s: `name:${data.search}` },
    });

  };

  return (
    <div className='py-4 bg-gray-900 text-gray-50 z-[99]'>
      <Container className='flex justify-between'>
        <div className='flex items-center space-x-6'>
          <div>
            <Link href='/' passHref>
              <Logo />
            </Link>
          </div>

          <nav className='hidden xl:block'>
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger>{t('nav.proxyCards')}</NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className='grid gap-3 p-6 md:w-[400px] lg:w-[600px] lg:grid-cols-[1fr_1fr]'>
                      <ListItem href='/sets' title={t('nav.sets')}>
                        {t('seo.sets.description')}
                      </ListItem>
                      <ListItem href='/tokens' title={t('nav.tokens')}>
                        {t('seo.tokens.description')}
                      </ListItem>
                      <ListItem href='/emblems' title={t('nav.emblems')}>
                        {t('seo.emblems.description')}
                      </ListItem>
                      <ListItem href='/import' title={t('nav.import')}>
                        {t('seo.import.description')}
                      </ListItem>
                      <ListItem href='/top-proxies' title={t('nav.topProxies')}>
                        {t('seo.topProxies.description')}
                      </ListItem>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>

                {process.env.NEXT_PUBLIC_CUSTOM_CARDS_LIVE === 'true' && (
                  <NavigationMenuItem>
                    <Link href='/custom-cards' legacyBehavior passHref>
                      <NavigationMenuLink className={cn(navigationMenuTriggerStyle(), 'flex items-center gap-2')}>
                        {t('nav.customCards')} <Badge>{t('common.new')}</Badge>
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                )}

                {process.env.NEXT_PUBLIC_DECK_LISTS_LIVE === 'true' && (
                  <NavigationMenuItem>
                    <NavigationMenuTrigger className='flex items-center gap-2'>{t('nav.deckBuilder')} <Badge>{t('common.new')}</Badge></NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className='grid gap-3 p-6 md:w-[400px] lg:w-[600px] lg:grid-cols-[1fr_1fr]'>
                        <ListItem href='/deck-builder' title={t('nav.buildDeck')}>
                          {t('seo.deckBuilder.description')}
                        </ListItem>
                        <ListItem href='/deck-builder/decks' title={t('nav.publicDecks')}>
                          {t('seo.publicDecks.description')}
                        </ListItem>
                        {user && (
                          <ListItem href='/deck-builder/favorites' title={t('nav.favoriteDeck')}>
                            {t('seo.deckBuilder.description')}
                          </ListItem>
                        )}
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                )}

                <NavigationMenuItem>
                  <Link href='/articles' legacyBehavior passHref>
                    <NavigationMenuLink className={navigationMenuTriggerStyle()}>{t('nav.articles')}</NavigationMenuLink>
                  </Link>
                </NavigationMenuItem>

                {process.env.NEXT_PUBLIC_PRO_ACCOUNTS_LIVE === 'true' && (
                  <NavigationMenuItem>
                    <Link href='/pro-membership' legacyBehavior passHref>
                      <NavigationMenuLink className={cn(navigationMenuTriggerStyle(), 'flex items-center gap-2')}>
                        {t('common.proMembership')} <Badge>{t('common.new')}</Badge>
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                )}
              </NavigationMenuList>
            </NavigationMenu>
          </nav>

          <div className='hidden md:flex flex-1 justify-center px-2 xl:ml-6 xl:justify-end'>
            <div className='w-full max-w-lg xl:max-w-xs'>
              <label htmlFor='search' className='sr-only'>
                {t('common.search')}
              </label>
              <Form className='relative' form={form} onSubmit={handleSubmit}>
                <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                  <SearchIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                </div>
                <input
                  id='search'
                  name='search'
                  className='block w-full rounded-md border-0 bg-gray-700 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6'
                  placeholder={t('common.search')}
                  type='search'
                  {...form.register('search')}
                />
              </Form>
            </div>
          </div>
        </div>

        <nav className='items-center hidden xl:flex'>
          <ul className='flex items-center gap-1 2xl:gap-3'>
            {process.env.NEXT_PUBLIC_DARK_MODE_LIVE === 'true' && !userDetailsQuery.isLoading && user && customerDetails?.proMemberStatus === 'active' && (
              <li className='flex items-center'>
                <ThemeToggle />
              </li>
            )}
            {process.env.NEXT_PUBLIC_ACCOUNTS_LIVE === 'true' && !userDetailsQuery.isLoading &&
              (user ? (
                <li>
                  <Button variant='ghost' asChild>
                    <Link href='/account' className='group' data-test-id='navbar-link-login'>
                      <UserIcon className='w-5 h-5' />
                      <span className='hidden 2xl:block'>{t('common.myAccount')}</span>
                    </Link>
                  </Button>
                </li>
              ) : (
                <div className='flex items-center gap-2'>
                  <Button variant='secondary'>
                    <Link href='/login'>{t('common.signIn')}</Link>
                  </Button>
                  <Button variant='primary'>
                    <Link href='/register'>{t('common.register')}</Link>
                  </Button>
                </div>
              ))}

            <li className='flex items-center'>
              <LanguageSwitcher />
            </li>
            <li>
              <Button variant='ghost' onClick={openCart} data-test-id='navbar-cart-open' className='px-2'>
                <Printer width={24} />
                <div className='font-semibold text-right tabular-nums'>{getNumberOfItemsInCart()}</div>
              </Button>
            </li>
          </ul>
        </nav>
        <div className='flex items-center gap-1 sm:gap-3 xl:hidden'>
          <LanguageSwitcher />
          {process.env.NEXT_PUBLIC_DARK_MODE_LIVE === 'true' && !userDetailsQuery.isLoading && user && customerDetails?.proMemberStatus === 'active' && (
            <ThemeToggle />
          )}

          {process.env.NEXT_PUBLIC_ACCOUNTS_LIVE === 'true' && (
            <Button variant='ghost' size='icon' asChild>
              <Link href={user ? '/account' : '/login'} data-test-id='navbar-link-login'>
                <UserIcon width={24} className={`${router.pathname == '/account' ? 'text-green-500 group-hover:text-black' : ''}`} />
                <span className='sr-only'>{t('common.myAccount')}</span>
              </Link>
            </Button>
          )}

          <Button variant='ghost' onClick={openCart} className='px-2'>
            <Printer width={20} />
            <div className='font-semibold tabular-nums text-right'>{getNumberOfItemsInCart()}</div>
          </Button>

          <Button variant='ghost' size='icon' onClick={() => setMobileDrawerActive(true)}>
            <MenuIcon width={24} />
          </Button>
        </div>
        <MobileDrawer active={mobileDrawerActive} setActive={setMobileDrawerActive} />
      </Container>
    </div>
  );
}

const ListItem = React.forwardRef<React.ElementRef<'a'>, React.ComponentPropsWithoutRef<'a'>>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link ref={ref} className={cn('block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-800 focus:bg-gray-800', className)} {...props}>
          <div className='text-sm font-medium leading-none'>{title}</div>
          <p className='line-clamp-2 text-sm leading-snug text-gray-400'>{children}</p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = 'ListItem';
