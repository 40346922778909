const translations = {
  common: {
    search: 'Buscar',
    signIn: 'Iniciar sesión',
    register: 'Registrarse',
    myAccount: 'Mi cuenta',
    support: 'Soporte',
    followUs: 'Síguenos en X/Twitter',
    proMembership: 'Membresía Pro',
    new: 'Nuevo',
    proMember: 'Miembro Pro',
    upgrade: 'Mejorar',
    subscribe: 'Suscribirse',
    manage: 'Gestionar',
    cancel: 'Cancelar',
    close: 'Cerrar',
    or: 'O',
    email: 'Correo electrónico',
    continue: 'Continuar',
    submit: 'Enviar',
    billing: 'Facturación',
    sort: 'Ordenar',
    language: 'Idioma',
    selectLanguage: 'Seleccionar Idioma',
    searchPlaceholder: 'Buscar...',
    cards: 'Cartas',
    previous: 'Anterior',
    next: 'Siguiente',
    pagination: 'Paginación',
    back: 'Volver',
    backToSets: 'Volver a Colecciones',
    error: 'Ocurrió un error. Por favor, inténtalo de nuevo.',
    home: 'Inicio',
    sets: 'Colecciones',
  },
  theme: {
    light: 'Claro',
    dark: 'Oscuro',
    toggle: 'Cambiar tema',
  },
  cta: {
    title: '¿Listo para empezar?',
    subtitle: 'Comienza a crear proxies hoy.',
    searchByCard: 'Buscar por carta',
    searchBySet: 'Buscar por colección',
  },
  nav: {
    proxyCards: 'Cartas Proxy',
    sets: 'Colecciones',
    tokens: 'Fichas',
    emblems: 'Emblemas',
    import: 'Importar',
    topProxies: 'Top Proxies',
    customCards: 'Cartas Personalizadas',
    deckBuilder: 'Constructor de Mazos',
    buildDeck: 'Construir Mazo',
    publicDecks: 'Mazos Públicos',
    favoriteDeck: 'Mis Mazos Favoritos',
    articles: 'Artículos',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Cartas proxy de Magic: The Gathering',
      description: 'Accede a una amplia biblioteca de cartas proxy de Magic: The Gathering. Visualiza e imprime imágenes de cartas MTG para juego casual. ¡Comienza a construir el mazo de tus sueños ahora!',
    },
    sets: {
      title: 'Colecciones de Cartas - MTG Proxies',
      description: 'Explora todas las colecciones de MTG e imprime proxies para tu mazo.',
    },
    tokens: {
      title: 'Fichas - MTG Proxies',
      description: 'Mejora tus partidas de MTG con una variedad de fichas proxy.',
    },
    emblems: {
      title: 'Emblemas - MTG Proxies',
      description: 'Imprime cartas proxy de Emblemas MTG para tu mazo.',
    },
    search: {
      title: 'Buscar Cartas MTG - MTG Proxies',
      description: 'Encuentra e imprime cartas proxy de MTG sin esfuerzo. Usa nuestra herramienta de búsqueda para acceder a una extensa base de datos y mejora tu mazo de Magic: The Gathering con proxies.',
    },
    support: {
      title: 'Soporte - MTG Proxies',
      description: 'Si tienes preguntas, comentarios o quieres que agreguemos una ficha/emblema, envíanos un correo electrónico.',
    },
    articles: {
      title: 'Artículos - MTG Proxies',
      description: 'Artículos escritos sobre Magic: The Gathering y cartas proxy.',
    },
    import: {
      title: 'Importar - MTG Proxies',
      description: 'Importa una lista de cartas para imprimir tus proxies de Magic: The Gathering.',
    },
    topProxies: {
      title: 'Top Proxies - MTG Proxies',
      description: 'Ver las cartas proxy más populares de Magic: The Gathering.',
    },
    customCards: {
      title: 'Cartas Personalizadas - MTG Proxies',
      description: 'Crea tus propias cartas proxy personalizadas de Magic: The Gathering.',
    },
    login: {
      title: 'Iniciar Sesión - MTG Proxies',
      description: 'Inicia sesión en tu cuenta para gestionar tus cartas personalizadas, listas de mazos y más.',
    },
    register: {
      title: 'Registrarse - MTG Proxies',
      description: 'Regístrate para gestionar tus cartas personalizadas, listas de mazos y más.',
    },
    termsOfService: {
      title: 'Términos de Servicio - MTG Proxies',
      description: 'Lee los términos de servicio del sitio.',
    },
    privacyPolicy: {
      title: 'Política de Privacidad - MTG Proxies',
      description: 'Lee la política de privacidad del sitio.',
    },
    account: {
      title: 'Cuenta - MTG Proxies',
      description: 'Gestiona tu cuenta.',
    },
    proMembership: {
      title: 'Membresía Pro - MTG Proxies',
      description: 'Suscríbete a la Membresía Pro para acceder a todas las funciones del sitio.',
    },
    deckBuilder: {
      title: 'Constructor de Mazos - MTG Proxies',
      description: 'Crea y gestiona tus mazos de Magic: The Gathering.',
    },
    deckEditor: {
      title: 'Editor de Mazos - MTG Proxies',
      description: 'Crea y edita tus mazos de Magic: The Gathering.',
    },
    publicDecks: {
      title: 'Mazos Públicos - MTG Proxies',
      description: 'Explora y descubre mazos públicos de MTG compartidos por la comunidad.',
    },
  },
  pro: {
    pricing: {
      title: 'Desbloquea el Proxying de Cartas MTG Pro',
      subtitle: 'Mejora a una Membresía Pro para proxying de cartas sin anuncios y acceso a todas nuestras funciones y beneficios.',
      monthlyPlan: 'Membresía Pro Mensual',
      yearlyPlan: 'Membresía Pro Anual',
      monthlyPrice: '$5.00 por mes',
      yearlyPrice: '$40.00 por año',
      subscribeMonthly: 'Suscribirse Mensualmente',
      subscribeYearly: 'Suscribirse Anualmente',
      signInToSubscribe: 'Inicia sesión para Suscribirte',
      manageSubscription: 'Gestionar Suscripción',
      nextBillingDate: 'Próxima fecha de facturación',
      benefitsEndDate: 'Los beneficios terminarán el',
      viewInvoice: 'Ver Factura',
      changePlan: 'Cambiar Plan',
      reactivate: 'Reactivar',
      active: 'Activo',
      cancelled: 'Cancelado',
    },
    benefits: {
      title: '¿Por qué la Membresía Pro de MTG Proxies?',
      subtitle: 'Desbloquea funciones premium y disfruta de una experiencia fluida de proxying de cartas con nuestra membresía Pro.',
      noAds: {
        title: 'Sin Publicidad',
        description: 'Navega y crea proxies sin anuncios, banners o ventanas emergentes molestas. Disfruta de una experiencia limpia y enfocada.',
      },
      darkMode: {
        title: 'Modo Oscuro',
        description: 'Reduce la fatiga visual con nuestra elegante interfaz oscura. Perfecto para navegar de noche y en entornos con poca luz.',
      },
      advancedSearch: {
        title: 'Filtros de Búsqueda Avanzados',
        description: 'Accede a potentes filtros de búsqueda para encontrar exactamente las cartas que necesitas. Filtra por identidad de color, rareza y tipo de carta.',
      },
      multipleDecks: {
        title: 'Gestiona Múltiples Mazos',
        description: 'Crea y gestiona múltiples mazos con el Constructor de Mazos. Guarda todos tus mazos favoritos para acceso rápido.',
      },
      futureFeatures: {
        title: 'Funciones Pro Futuras',
        description: 'Obtén acceso inmediato a todas las nuevas funciones premium cuando se lancen. Los miembros Pro siempre tienen acceso primero.',
      },
      cancelAnytime: {
        title: 'Cancela Cuando Quieras',
        description: 'Sin compromiso a largo plazo. Tienes total flexibilidad para cancelar tu suscripción en cualquier momento.',
      },
      prioritySupport: {
        title: 'Soporte Prioritario',
        description: 'Salta la cola con soporte prioritario dedicado. Tus preguntas e inquietudes son atendidas primero y con especial atención.',
      },
    },
    features: {
      unlimitedDecks: 'Crea mazos ilimitados',
      noAds: 'Sin publicidad',
      prioritySupport: 'Soporte prioritario',
      darkMode: 'Acceso al modo oscuro',
      advancedSearch: 'Filtros de búsqueda avanzados',
    },
    promotion: {
      title: 'Mejora a Pro para Crear Más Mazos',
      description: 'Los miembros Pro pueden crear mazos ilimitados. ¡Mejora ahora para desbloquear esta función!',
      customizeTitle: 'Función Pro: Personalización de Cartas con IA',
      customizeDescription: 'Mejora a Pro para crear variaciones personalizadas de cartas usando IA. Los miembros Pro pueden generar hasta 5 cartas personalizadas por día.',
    },
    account: {
      title: 'Membresía Pro',
      description: 'Gestiona los detalles de tu suscripción',
      subscribeToPro: 'Suscríbete a la Membresía Pro',
    },
  },
  auth: {
    signIn: {
      title: 'Inicia sesión en tu cuenta',
      withGoogle: 'Continuar con Google',
      withEmail: 'O continuar con',
      sendMagicLink: 'Enviar Enlace Mágico',
      noAccount: '¿No tienes una cuenta?',
      checkEmail: 'Revisa tu correo electrónico para obtener un enlace para iniciar sesión',
      errors: {
        failed: 'Error al iniciar sesión',
        failedGoogle: 'Error al iniciar sesión con Google',
        failedEmail: 'Error al iniciar sesión con correo electrónico',
        noEmail: 'No se encontró el correo electrónico',
      },
      terms: 'Al iniciar sesión, confirmo que he leído y acepto los',
    },
    register: {
      title: 'Crea tu cuenta',
      haveAccount: '¿Ya tienes una cuenta?',
      terms: 'Al crear una cuenta, confirmo que he leído y acepto los',
    },
    common: {
      termsOfService: 'Términos de Servicio',
      privacyPolicy: 'Política de Privacidad',
      and: 'y',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['cartas', 'fichas', 'emblemas', 'planeswalkers', 'comandantes', 'tierras', 'criaturas', 'artefactos', 'encantamientos', 'instantáneos'],
      description: '¡MTG Proxies te ayuda a crear proxies de cartas de Magic: The Gathering para usar en tus mazos casuales completamente gratis!',
      search: {
        cards: {
          tab: 'Cartas',
          placeholder: 'Busca cualquier carta de Magic...',
          error: 'Error al obtener cartas aleatorias',
        },
        sets: {
          tab: 'Colecciones',
          placeholder: 'Busca cualquier colección de Magic...',
        },
        tokens: {
          tab: 'Fichas',
          placeholder: 'Busca cualquier ficha de Magic...',
        },
        button: 'Buscar',
        hint: {
          cards: 'Presiona Enter o haz clic en Buscar para encontrar tus cartas',
          sets: 'Presiona Enter o haz clic en Buscar para encontrar tus colecciones',
          tokens: 'Presiona Enter o haz clic en Buscar para encontrar tus fichas',
        },
        aria: {
          searchIcon: 'Icono de búsqueda',
          searchInput: {
            cards: 'Buscar cartas',
            sets: 'Buscar colecciones',
            tokens: 'Buscar fichas',
          },
        },
      },
      images: {
        cardAlt: 'Imagen de carta de Magic',
      },
    },
    features: {
      section1: {
        badge: 'Imprimir Proxies',
        title: 'Imprimir Cartas Proxy de MTG',
        subtitle: '¡Agrega cartas a tu lista de impresión, imprímelas y úsalas en tus mazos hoy!',
        features: [
          {
            name: 'Agrega cartas a tu lista de impresión',
            description: 'Busca cualquier carta usando la página de Búsqueda o navega por colección y agrega las cartas que desees a tu lista de impresión.',
          },
          {
            name: 'Imprime tus cartas',
            description: 'Una vez que hayas agregado todas las cartas que deseas a tu lista de impresión, puedes imprimirlas y usarlas en tus mazos.',
          },
          {
            name: 'Úsalas en tus mazos',
            description: 'Una vez que hayas impreso tus cartas, puedes usarlas en tus mazos. Recórtalas y pégalas a una tierra básica o usa una funda con una tierra básica dentro.',
          },
        ],
        images: {
          cartScreenshot: 'Captura de pantalla del carrito mostrando cómo agregar cartas a tu lista de impresión',
        },
      },
      section2: {
        badge: 'Constructor de Mazos',
        title: 'Construye y gestiona Mazos de Magic: The Gathering',
        subtitle: 'Crea y gestiona tus propios mazos, o explora mazos públicos creados por la comunidad.',
        features: [
          {
            name: 'Gestiona tus Mazos de MTG',
            description: 'Todos los usuarios pueden crear y gestionar un mazo de MTG. Los miembros Pro pueden crear un número ilimitado de mazos.',
          },
          {
            name: 'Imprime tus cartas',
            description: 'Una vez que hayas agregado todas las cartas que deseas a tu mazo, puedes agregarlas a tu Lista de Impresión todas a la vez.',
          },
          {
            name: 'Explora Mazos Públicos',
            description: 'Agrega mazos completos creados por la comunidad a tu Lista de Impresión para poder probar fácilmente nuevos mazos.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Interfaz del Constructor de Mazos mostrando cómo gestionar tus mazos',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, Todos los derechos reservados.',
    disclaimer: 'MTG Proxies no está producido, respaldado, apoyado ni afiliado a Wizards of the Coast.',
    affiliate: 'Como socio afiliado de TCGplayer, MTG Proxies puede ganar una comisión en compras que califiquen.',
    builtBy: 'Construido por',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Política de Privacidad',
    termsOfService: 'Términos de Servicio',
    support: 'Soporte',
  },
  sort: {
    nameAsc: 'Nombre (A a Z)',
    nameDesc: 'Nombre (Z a A)',
    cmcAsc: 'Valor de Maná (Bajo a Alto)',
    cmcDesc: 'Valor de Maná (Alto a Bajo)',
    usdAsc: 'Precio (Bajo a Alto)',
    usdDesc: 'Precio (Alto a Bajo)',
    rarityAsc: 'Rareza (Común a Mítica)',
    rarityDesc: 'Rareza (Mítica a Común)',
    releasedDesc: 'Fecha de Lanzamiento (Más Reciente)',
    releasedAsc: 'Fecha de Lanzamiento (Más Antiguo)',
  },
  pages: {
    search: {
      heading: 'Buscar',
    },
    sets: {
      heading: 'Colecciones de Cartas',
      latestSets: 'Colecciones Recientes',
      upcomingSets: 'Próximas Colecciones',
      allSets: 'Todas las Colecciones',
      noResults: 'No se encontraron resultados.',
    },
    set: {
      notFound: 'Colección no encontrada.',
      noCards: 'No se encontraron cartas en esta colección.',
      addAll: 'Añadir {{count}} cartas a la Lista de Impresión',
      addedToList: 'Se agregaron {{count}} cartas a la Lista de Impresión',
    },
    tokens: {
      heading: 'Fichas',
    },
    emblems: {
      heading: 'Emblemas',
    },
    import: {
      heading: 'Importar cartas a la Lista de Impresión',
      description: 'Ingresa los nombres de todas las cartas que deseas añadir a la Lista de Impresión.',
    },
    topProxies: {
      heading: 'Top Proxies',
      description: 'Aquí están las 50 cartas proxy más populares de Magic: The Gathering en MTG Proxies.',
    },
  },
  languages: {
    default: 'Predeterminado',
    any: 'Cualquiera',
    english: 'Inglés',
    spanish: 'Español',
    french: 'Francés',
    german: 'Alemán',
    italian: 'Italiano',
    portuguese: 'Portugués',
    japanese: 'Japonés',
    korean: 'Coreano',
    russian: 'Ruso',
    simplified_chinese: 'Chino simplificado',
    traditional_chinese: 'Chino tradicional',
    hebrew: 'Hebreo',
    latin: 'Latín',
    ancient_greek: 'Griego antiguo',
    arabic: 'Árabe',
    sanskrit: 'Sánscrito',
    phyrexian: 'Phyrexiano',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Mostrar filtros Pro',
    hide: '✨ Ocultar filtros Pro',
  },
  search: {
    showing_start: 'Mostrando',
    showing_to: 'a',
    showing_of: 'de',
    showing_results: 'resultados',
    noResults: 'No se encontraron resultados.',
    useSearchTip: 'Usa la búsqueda en la parte superior de la página para buscar una carta.',
  },
  advancedFilters: {
    colors: 'Colores',
    rarity: 'Rareza',
    cardTypes: 'Tipos de Carta',
    format: 'Formato',
    manaValue: 'Valor de Maná',
    cardName: 'Nombre de Carta',
    cardText: 'Texto de Carta',
    specialCharacteristics: 'Características Especiales',
    activeFilters: 'Filtros Activos',
    clearAll: 'Borrar Todo',
    applyFilters: 'Aplicar Filtros',
    searchCardName: 'Buscar nombre de carta...',
    searchCardText: 'Buscar texto de carta...',
    namePrefix: 'Nombre: ',
    manaPrefix: 'Maná: ',
    textPrefix: 'Texto: ',
    removeFromFilter: '✕',
    selectLanguage: 'Seleccionar idioma',
  },
  card: {
    addToPrintList: 'Agregar a la lista de impresión',
    removeFromPrintList: 'Quitar de la lista de impresión',
    cardsInPrintList: 'carta en la lista de impresión',
    cardsInPrintListPlural: 'cartas en la lista de impresión',
    buyOn: 'Comprar en TCGplayer',
    buyFor: 'Comprar por ',
    viewDetails: 'Ver detalles',
    type: 'Tipo',
    manaCost: 'Coste de maná',
    rarity: 'Rareza',
    artist: 'Artista',
    collectorNumber: 'Número de colección',
    oracleText: 'Texto Oracle',
    youMightAlsoLike: 'También te puede interesar...',
    noRecommendations: 'No hay recomendaciones disponibles en este momento.',
    details: 'Detalles',
    rulings: 'Reglas',
    legality: 'Legalidad',
    share: 'Compartir',
    favorite: 'Favorito',
    noRulings: 'No hay reglas disponibles para esta carta.',
    noLegalityInfo: 'No hay información de legalidad disponible para esta carta.',
    published: 'Publicado',
    legal: 'Legal',
    restricted: 'Restringido',
    banned: 'Prohibido',
    format: 'Formato',
    status: 'Estado',
    viewMore: 'Ver más',
    viewLess: 'Ver menos',
    customizeWithAI: 'Personalizar con IA',
    otherVersions: 'Impresiones únicas',
    showUniqueVersions: 'Mostrar impresiones únicas',
    hideUniqueVersions: 'Ocultar impresiones únicas',
  },
  cart: {
    title: 'Lista de impresión',
    closePanel: 'Cerrar panel',
    empty: 'No hay proxies en la lista de impresión.',
    addPadding: 'Agregar relleno',
    paperSize: 'Tamaño de papel',
    scale: 'Escala',
    clearCardList: 'Vaciar lista',
    print: 'Imprimir',
    success: '¡Éxito!',
  },
  setsList: {
    showing: 'Mostrando',
    to: 'a',
    of: 'de',
    results: 'resultados',
    searchPlaceholder: 'Buscar...',
  },
};

export default translations;
