const translations = {
  common: {
    search: 'Cerca',
    signIn: 'Accedi',
    register: 'Registrati',
    myAccount: 'Il mio account',
    support: 'Supporto',
    followUs: 'Seguici su X/Twitter',
    proMembership: 'Abbonamento Pro',
    new: 'Nuovo',
    proMember: 'Membro Pro',
    upgrade: 'Aggiorna',
    subscribe: 'Abbonati',
    manage: 'Gestisci',
    cancel: 'Annulla',
    close: 'Chiudi',
    or: 'O',
    email: 'Indirizzo email',
    continue: 'Continua',
    submit: 'Invia',
    billing: 'Fatturazione',
    sort: 'Ordina',
    language: 'Lingua',
    selectLanguage: 'Seleziona lingua',
    searchPlaceholder: 'Cerca...',
    cards: 'Carte',
    previous: 'Precedente',
    next: 'Successivo',
    pagination: 'Paginazione',
    back: 'Indietro',
    backToSets: 'Torna ai Set',
    error: 'Si è verificato un errore. Riprova.',
    home: 'Home',
    sets: 'Set',
  },
  theme: {
    light: 'Chiaro',
    dark: 'Scuro',
    toggle: 'Cambia tema',
  },
  cta: {
    title: 'Pronto a iniziare?',
    subtitle: 'Inizia a creare proxy oggi stesso.',
    searchByCard: 'Cerca per carta',
    searchBySet: 'Cerca per set',
  },
  nav: {
    proxyCards: 'Carte Proxy',
    sets: 'Set',
    tokens: 'Token',
    emblems: 'Emblemi',
    import: 'Importa',
    topProxies: 'Proxy Popolari',
    customCards: 'Carte Personalizzate',
    deckBuilder: 'Costruttore di Mazzi',
    buildDeck: 'Costruisci Mazzo',
    publicDecks: 'Mazzi Pubblici',
    favoriteDeck: 'I Miei Mazzi Preferiti',
    articles: 'Articoli',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Carte Proxy di Magic: The Gathering',
      description: 'Accedi a una vasta libreria di carte proxy di Magic: The Gathering. Visualizza e stampa facilmente immagini di carte MTG per il gioco casual. Inizia a costruire il mazzo dei tuoi sogni oggi!',
    },
    sets: {
      title: 'Set di Carte - MTG Proxies',
      description: 'Esplora tutti i set MTG e stampa proxy per il tuo mazzo.',
    },
    tokens: {
      title: 'Token - MTG Proxies',
      description: 'Migliora il tuo gioco MTG con vari token proxy.',
    },
    emblems: {
      title: 'Emblemi - MTG Proxies',
      description: 'Stampa carte proxy emblema MTG per il tuo mazzo.',
    },
    search: {
      title: 'Ricerca Carte MTG - MTG Proxies',
      description: 'Trova e stampa facilmente carte proxy MTG. Usa gli strumenti di ricerca per accedere a un vasto database e migliora i tuoi mazzi di Magic: The Gathering con le proxy.',
    },
    support: {
      title: 'Supporto - MTG Proxies',
      description: 'Inviaci una email se hai domande, feedback o vuoi aggiungere token/emblemi.',
    },
    articles: {
      title: 'Articoli - MTG Proxies',
      description: 'Articoli su Magic: The Gathering e carte proxy.',
    },
    import: {
      title: 'Importa - MTG Proxies',
      description: 'Importa una lista di carte per stampare proxy di Magic: The Gathering.',
    },
    topProxies: {
      title: 'Proxy Popolari - MTG Proxies',
      description: 'Visualizza le carte proxy più popolari di Magic: The Gathering.',
    },
    customCards: {
      title: 'Carte Personalizzate - MTG Proxies',
      description: 'Crea le tue carte proxy personalizzate di Magic: The Gathering.',
    },
    login: {
      title: 'Accedi - MTG Proxies',
      description: 'Accedi al tuo account per gestire carte personalizzate, liste dei mazzi e altro.',
    },
    register: {
      title: 'Registrati - MTG Proxies',
      description: 'Registra un account per gestire carte personalizzate, liste dei mazzi e altro.',
    },
    termsOfService: {
      title: 'Termini di Servizio - MTG Proxies',
      description: 'Leggi i termini di servizio del sito.',
    },
    privacyPolicy: {
      title: 'Informativa sulla Privacy - MTG Proxies',
      description: "Leggi l'informativa sulla privacy del sito.",
    },
    account: {
      title: 'Account - MTG Proxies',
      description: 'Gestisci il tuo account.',
    },
    proMembership: {
      title: 'Abbonamento Pro - MTG Proxies',
      description: "Abbonati all'abbonamento Pro per accedere a tutte le funzionalità del sito.",
    },
    deckBuilder: {
      title: 'Costruttore di Mazzi - MTG Proxies',
      description: 'Crea e gestisci i tuoi mazzi di Magic: The Gathering.',
    },
    deckEditor: {
      title: 'Editor di Mazzi - MTG Proxies',
      description: 'Crea e modifica i tuoi mazzi di Magic: The Gathering.',
    },
    publicDecks: {
      title: 'Mazzi Pubblici - MTG Proxies',
      description: 'Sfoglia ed esplora i mazzi pubblici MTG condivisi dalla comunità.',
    },
  },
  pro: {
    pricing: {
      title: 'Sblocca la Creazione di Carte Proxy Pro MTG',
      subtitle: "Aggiorna all'abbonamento Pro per la creazione di carte proxy senza pubblicità e l'accesso a tutte le funzionalità e i benefici.",
      monthlyPlan: 'Abbonamento Pro Mensile',
      yearlyPlan: 'Abbonamento Pro Annuale',
      monthlyPrice: '€5,00/mese',
      yearlyPrice: '€40,00/anno',
      subscribeMonthly: 'Abbonati Mensile',
      subscribeYearly: 'Abbonati Annuale',
      signInToSubscribe: 'Accedi per Abbonarti',
      manageSubscription: 'Gestisci Abbonamento',
      nextBillingDate: 'Prossima Data di Fatturazione',
      benefitsEndDate: 'Data di Fine Benefici',
      viewInvoice: 'Visualizza Fattura',
      changePlan: 'Cambia Piano',
      reactivate: 'Riattiva',
      active: 'Attivo',
      cancelled: 'Annullato',
    },
    benefits: {
      title: "Perché Hai Bisogno dell'Abbonamento Pro MTG Proxies",
      subtitle: "Sblocca funzionalità premium e goditi un'esperienza fluida di creazione di carte proxy con l'abbonamento Pro.",
      noAds: {
        title: 'Nessuna Pubblicità',
        description: "Naviga e crea proxy senza pubblicità fastidiose, banner e pop-up. Goditi un'esperienza pulita e concentrata.",
      },
      darkMode: {
        title: 'Modalità Scura',
        description: "Riduci l'affaticamento degli occhi con un'elegante interfaccia scura. Perfetta per la navigazione notturna e gli ambienti con poca luce.",
      },
      advancedSearch: {
        title: 'Filtri di Ricerca Avanzati',
        description: 'Accedi a potenti filtri di ricerca per trovare esattamente le carte di cui hai bisogno. Filtra per identità di colore, rarità e tipi di carta.',
      },
      multipleDecks: {
        title: 'Gestione di Più Mazzi',
        description: 'Crea e gestisci più mazzi con il costruttore di mazzi. Salva tutti i tuoi mazzi preferiti per un accesso rapido.',
      },
      futureFeatures: {
        title: 'Future Funzionalità Pro',
        description: 'Ottieni accesso istantaneo alle nuove funzionalità premium quando vengono rilasciate. I membri Pro hanno sempre accesso per primi.',
      },
      cancelAnytime: {
        title: 'Annulla in Qualsiasi Momento',
        description: 'Nessun impegno a lungo termine. Puoi annullare il tuo abbonamento in modo flessibile in qualsiasi momento.',
      },
      prioritySupport: {
        title: 'Supporto Prioritario',
        description: 'Salta la coda con supporto prioritario dedicato. Le tue domande e preoccupazioni vengono gestite per prime, con attenzione speciale.',
      },
    },
    features: {
      unlimitedDecks: 'Creazione Illimitata di Mazzi',
      noAds: 'Nessuna Pubblicità',
      prioritySupport: 'Supporto Prioritario',
      darkMode: 'Accesso alla Modalità Scura',
      advancedSearch: 'Filtri di Ricerca Avanzati',
    },
    promotion: {
      title: 'Aggiorna a Pro per Creare Più Mazzi',
      description: 'I membri Pro possono creare un numero illimitato di mazzi. Aggiorna ora per sbloccare questa funzionalità!',
      customizeTitle: 'Funzionalità Pro: Personalizzazione Carte con IA',
      customizeDescription: "Aggiorna a Pro per creare varianti personalizzate delle carte usando l'IA. I membri Pro possono generare fino a 5 carte personalizzate al giorno.",
    },
    account: {
      title: 'Abbonamento Pro',
      description: 'Gestisci Dettagli Abbonamento',
      subscribeToPro: 'Abbonati a Pro',
    },
  },
  auth: {
    signIn: {
      title: 'Accedi al Tuo Account',
      withGoogle: 'Continua con Google',
      withEmail: 'O continua con',
      sendMagicLink: 'Invia Link Magico',
      noAccount: 'Non hai un account?',
      checkEmail: 'Controlla la tua email per il link di accesso',
      errors: {
        failed: 'Accesso Fallito',
        failedGoogle: 'Accesso Google Fallito',
        failedEmail: 'Accesso Email Fallito',
        noEmail: 'Email non trovata',
      },
      terms: 'Accedendo, accetti:',
    },
    register: {
      title: 'Crea un Account',
      haveAccount: 'Hai già un account?',
      terms: 'Creando un account, accetti:',
    },
    common: {
      termsOfService: 'Termini di Servizio',
      privacyPolicy: 'Informativa sulla Privacy',
      and: 'e',
    },
  },
  home: {
    hero: {
      title: 'Proxy',
      rotatingWords: ['carte', 'pedine', 'emblemi', 'planeswalker', 'comandanti', 'terre', 'creature', 'artefatti', 'incantesimi', 'istantanei'],
      description: 'MTG Proxies ti aiuta a creare proxy di carte Magic: The Gathering da utilizzare nei tuoi mazzi casual gratuitamente!',
      search: {
        cards: {
          tab: 'Carte',
          placeholder: 'Cerca una carta Magic...',
          error: 'Errore nel caricamento delle carte casuali',
        },
        sets: {
          tab: 'Set',
          placeholder: 'Cerca un set Magic...',
        },
        tokens: {
          tab: 'Pedine',
          placeholder: 'Cerca una pedina Magic...',
        },
        button: 'Cerca',
        hint: {
          cards: 'Premi Invio o clicca su Cerca per trovare le tue carte',
          sets: 'Premi Invio o clicca su Cerca per trovare i tuoi set',
          tokens: 'Premi Invio o clicca su Cerca per trovare le tue pedine',
        },
        aria: {
          searchIcon: 'Icona di ricerca',
          searchInput: {
            cards: 'Cerca carte',
            sets: 'Cerca set',
            tokens: 'Cerca pedine',
          },
        },
      },
      images: {
        cardAlt: 'Immagine carta Magic',
      },
    },
    features: {
      section1: {
        badge: 'Stampa Proxy',
        title: 'Stampa Carte Proxy MTG',
        subtitle: 'Aggiungi carte alla tua lista di stampa, stampale e utilizzale nei tuoi mazzi oggi stesso!',
        features: [
          {
            name: 'Aggiungi carte alla tua lista di stampa',
            description: 'Cerca qualsiasi carta usando la pagina di Ricerca o sfoglia per set e aggiungi le carte che desideri alla tua lista di stampa.',
          },
          {
            name: 'Stampa le tue carte',
            description: 'Una volta aggiunte tutte le carte che desideri alla tua lista di stampa, puoi stamparle e utilizzarle nei tuoi mazzi.',
          },
          {
            name: 'Utilizzale nei tuoi mazzi',
            description: "Una volta stampate le tue carte, puoi utilizzarle nei tuoi mazzi. Ritagliale e incollale su una terra base o usa una bustina con una terra base all'interno.",
          },
        ],
        images: {
          cartScreenshot: 'Screenshot del carrello che mostra come aggiungere carte alla tua lista di stampa',
        },
      },
      section2: {
        badge: 'Costruttore di Mazzi',
        title: 'Costruisci e gestisci Mazzi Magic: The Gathering',
        subtitle: 'Crea e gestisci i tuoi mazzi, o sfoglia i mazzi pubblici creati dalla community.',
        features: [
          {
            name: 'Gestisci i tuoi Mazzi MTG',
            description: 'Tutti gli utenti possono creare e gestire un mazzo MTG. I membri Pro possono creare un numero illimitato di mazzi.',
          },
          {
            name: 'Stampa le tue carte',
            description: 'Una volta aggiunte tutte le carte che desideri al tuo mazzo, puoi aggiungerle alla tua Lista di Stampa tutte in una volta.',
          },
          {
            name: 'Sfoglia i Mazzi Pubblici',
            description: 'Aggiungi mazzi completi creati dalla community alla tua Lista di Stampa per testare facilmente nuovi mazzi.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Interfaccia del Costruttore di Mazzi che mostra come gestire i tuoi mazzi',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, Tutti i diritti riservati.',
    disclaimer: 'MTG Proxies non è prodotto, approvato, supportato o affiliato a Wizards of the Coast.',
    affiliate: 'Come partner affiliato di TCGplayer, MTG Proxies può guadagnare una commissione sugli acquisti idonei.',
    builtBy: 'Creato da',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Politica sulla Privacy',
    termsOfService: 'Termini di Servizio',
    support: 'Supporto',
  },
  sort: {
    nameAsc: 'Nome (A a Z)',
    nameDesc: 'Nome (Z a A)',
    cmcAsc: 'Valore di Mana (Basso a Alto)',
    cmcDesc: 'Valore di Mana (Alto a Basso)',
    usdAsc: 'Prezzo (Basso a Alto)',
    usdDesc: 'Prezzo (Alto a Basso)',
    rarityAsc: 'Rarità (Comune a Mitica)',
    rarityDesc: 'Rarità (Mitica a Comune)',
    releasedDesc: 'Data di Pubblicazione (Prima i Nuovi)',
    releasedAsc: 'Data di Pubblicazione (Prima i Vecchi)',
  },
  pages: {
    search: {
      heading: 'Cerca',
    },
    sets: {
      heading: 'Set di Carte',
      latestSets: 'Set Recenti',
      upcomingSets: 'Set in Arrivo',
      allSets: 'Tutti i Set',
      noResults: 'Nessun risultato trovato.',
    },
    set: {
      notFound: 'Set non trovato.',
      noCards: 'Nessuna carta trovata in questo set.',
      addAll: 'Aggiungi {{count}} Carte alla Lista di Stampa',
      addedToList: 'Aggiunte {{count}} carte alla Lista di Stampa',
    },
    tokens: {
      heading: 'Token',
    },
    emblems: {
      heading: 'Emblemi',
    },
    import: {
      heading: 'Importa carte nella Lista di Stampa',
      description: 'Inserisci i nomi di tutte le carte che vuoi aggiungere alla Lista di Stampa.',
    },
    topProxies: {
      heading: 'Proxy Popolari',
      description: 'Ecco le 50 carte proxy più popolari di Magic: The Gathering su MTG Proxies.',
    },
  },
  languages: {
    default: 'Predefinito',
    any: 'Qualsiasi',
    english: 'Inglese',
    spanish: 'Spagnolo',
    french: 'Francese',
    german: 'Tedesco',
    italian: 'Italiano',
    portuguese: 'Portoghese',
    japanese: 'Giapponese',
    korean: 'Coreano',
    russian: 'Russo',
    simplified_chinese: 'Cinese semplificato',
    traditional_chinese: 'Cinese tradizionale',
    hebrew: 'Ebraico',
    latin: 'Latino',
    ancient_greek: 'Greco antico',
    arabic: 'Arabo',
    sanskrit: 'Sanscrito',
    phyrexian: 'Phyrexiano',
    quenya: 'Quenya',
  },
  filters: {
    show: '✨ Mostra filtri Pro',
    hide: '✨ Nascondi filtri Pro',
  },
  search: {
    showing_start: 'Mostrando',
    showing_to: 'a',
    showing_of: 'di',
    showing_results: 'risultati',
    noResults: 'Nessun risultato trovato.',
    useSearchTip: 'Usa la ricerca in cima alla pagina per cercare una carta.',
  },
  advancedFilters: {
    colors: 'Colori',
    rarity: 'Rarità',
    cardTypes: 'Tipi di Carta',
    format: 'Formato',
    manaValue: 'Valore di Mana',
    cardName: 'Nome Carta',
    cardText: 'Testo Carta',
    specialCharacteristics: 'Caratteristiche Speciali',
    activeFilters: 'Filtri Attivi',
    clearAll: 'Cancella Tutto',
    applyFilters: 'Applica Filtri',
    searchCardName: 'Cerca nome carta...',
    searchCardText: 'Cerca testo carta...',
    namePrefix: 'Nome: ',
    manaPrefix: 'Mana: ',
    textPrefix: 'Testo: ',
    removeFromFilter: '✕',
    selectLanguage: 'Seleziona lingua',
  },
  card: {
    addToPrintList: 'Aggiungi alla lista di stampa',
    removeFromPrintList: 'Rimuovi dalla lista di stampa',
    cardsInPrintList: 'carta nella lista di stampa',
    cardsInPrintListPlural: 'carte nella lista di stampa',
    buyOn: 'Compra su TCGplayer',
    buyFor: 'Compra per ',
    viewDetails: 'Visualizza dettagli',
    type: 'Tipo',
    manaCost: 'Costo di mana',
    rarity: 'Rarità',
    artist: 'Artista',
    collectorNumber: 'Numero del collezionista',
    oracleText: 'Testo Oracle',
    youMightAlsoLike: 'Potrebbe interessarti anche...',
    noRecommendations: 'Nessuna raccomandazione disponibile al momento.',
    details: 'Dettagli',
    rulings: 'Regole',
    legality: 'Legalità',
    share: 'Condividi',
    favorite: 'Preferito',
    noRulings: 'Nessuna regola disponibile per questa carta.',
    noLegalityInfo: 'Nessuna informazione sulla legalità disponibile per questa carta.',
    published: 'Pubblicato',
    legal: 'Legale',
    restricted: 'Limitato',
    banned: 'Bandito',
    format: 'Formato',
    status: 'Stato',
    viewMore: 'Mostra di più',
    viewLess: 'Mostra di meno',
    customizeWithAI: 'Personalizza con IA',
    otherVersions: 'Stampe uniche',
    showUniqueVersions: 'Mostra stampe uniche',
    hideUniqueVersions: 'Nascondi stampe uniche',
  },
  cart: {
    title: 'Lista di stampa',
    closePanel: 'Chiudi pannello',
    empty: 'Nessun proxy nella lista di stampa.',
    addPadding: 'Aggiungi spaziatura',
    paperSize: 'Dimensione carta',
    scale: 'Scala',
    clearCardList: 'Svuota lista',
    print: 'Stampa',
    success: 'Successo!',
  },
  setsList: {
    showing: 'Mostrando',
    to: 'a',
    of: 'di',
    results: 'risultati',
    searchPlaceholder: 'Cerca...',
  },
};

export default translations;
