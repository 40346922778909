export const SUPPORTED_LANGUAGES = {
  en: {
    name: 'English',
    locale: 'en',
    default: true,
    languageCode: 'en-US',
  },
  es: {
    name: 'Español',
    locale: 'es',
    languageCode: 'es',
  },
  pt: {
    name: 'Português',
    locale: 'pt',
    languageCode: 'pt',
  },
  fr: {
    name: 'Français',
    locale: 'fr',
    languageCode: 'fr',
  },
  de: {
    name: 'Deutsch',
    locale: 'de',
    languageCode: 'de',
  },
  it: {
    name: 'Italiano',
    locale: 'it',
    languageCode: 'it',
  },
  ja: {
    name: '日本語',
    locale: 'ja',
    languageCode: 'ja',
  },
  ko: {
    name: '한국어',
    locale: 'ko',
    languageCode: 'ko',
  },
  ru: {
    name: 'Русский',
    locale: 'ru',
    languageCode: 'ru',
  },
  zhs: {
    name: '中文简体',
    locale: 'zhs',
    languageCode: 'zh-CN',
  },
};

export const DEFAULT_LANGUAGE = 'en';

export const getLanguageFromLocale = (locale) => {
  return SUPPORTED_LANGUAGES[locale] || SUPPORTED_LANGUAGES[DEFAULT_LANGUAGE];
};

export const getAlternateLanguageLinks = (path) => {
  return Object.entries(SUPPORTED_LANGUAGES).map(([locale, lang]) => ({
    href: `https://www.mtg-proxies.com/${locale}${path}`,
    hrefLang: lang.languageCode,
  }));
};
