import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { MoonIcon, SunIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useThemeStore } from '../hooks/useThemeStore';
import { useTranslation } from '../hooks/useTranslation';
import { Button } from './ui/button';

const ThemeToggle = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useThemeStore();
  const { t } = useTranslation();

  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);

  if (!mounted || process.env.NEXT_PUBLIC_DARK_MODE_LIVE !== 'true') return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='ghost' className='px-2' aria-label={t('theme.toggle')}>
          {theme === 'light' ? (
            <>
              <SunIcon className='w-6 h-6' /> <span className='hidden 2xl:inline'>{t('theme.light')}</span>
            </>
          ) : (
            <>
              <MoonIcon className='w-6 h-6' /> <span className='hidden 2xl:inline'>{t('theme.dark')}</span>
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => setTheme('light')}>
          <SunIcon className='w-4 h-4 mr-2' />
          {t('theme.light')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('dark')}>
          <MoonIcon className='w-4 h-4 mr-2' />
          {t('theme.dark')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ThemeToggle;
