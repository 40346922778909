import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useFiltersStore = create(
  persist(
    (set) => ({
      filters: {
        s: '',
        colors: [],
        colorIdentity: 'exact',
        rarity: [],
        types: [],
        format: 'any',
        cmc: [0, 16],
        power: '',
        toughness: '',
        oracleText: '',
        artist: '',
        cardName: '',
        flavorText: '',
        language: 'default',
        isLegendary: false,
        isReserved: false,
        isFullArt: false,
        isPromo: false,
        isExtendedArt: false,
      },
      setFilters: (filters) => set({ filters }),
      updateFilter: (key, value) =>
        set((state) => ({
          filters: {
            ...state.filters,
            [key]: value,
          },
        })),
      resetFilters: () =>
        set({
          filters: {
            s: '',
            colors: [],
            colorIdentity: 'exact',
            rarity: [],
            types: [],
            format: 'any',
            cmc: [0, 16],
            power: '',
            toughness: '',
            oracleText: '',
            artist: '',
            cardName: '',
            flavorText: '',
            language: 'default',
            isLegendary: false,
            isReserved: false,
            isFullArt: false,
            isPromo: false,
            isExtendedArt: false,
          },
        }),
    }),
    {
      name: 'MTGProxies.Filters',
    }
  )
);
