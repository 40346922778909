const toDataUrl = (src) => {
  return new Promise((resolve, reject) => {
    // Create an Image object
    var img = new Image();
    // Add CORS approval to prevent a tainted canvas
    img.crossOrigin = 'Anonymous';

    // Set a timeout to reject the promise if the image takes too long to load
    const timeoutId = setTimeout(() => {
      reject(new Error(`Timeout loading image from ${src}`));
    }, 10000); // 10 second timeout

    // Handle successful image load
    img.onload = function () {
      clearTimeout(timeoutId);
      try {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
        canvas = null;
      } catch (error) {
        reject(error);
      }
    };

    // Handle errors like CORS issues or network failures
    img.onerror = function () {
      clearTimeout(timeoutId);
      reject(new Error(`Failed to load image from ${src}`));
    };

    // Load the image
    img.src = src;
  });
};

export default toDataUrl;
