import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { cn } from '../lib/utils';

export default function CardImage({ src, alt, className, allowZoom = true }) {
  if (!src) return <></>;

  return (
    <div>
      {allowZoom ? (
        <Zoom zoomMargin={40}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={src} alt={alt} width='427px' height='595px' loading='lazy' className={cn('rounded-xl', className)} />
        </Zoom>
      ) : (
        <img src={src} alt={alt} width='427px' height='595px' loading='lazy' className={cn('rounded-xl', className)} />
      )}
    </div>
  );
}
