import { db } from './firebase';
import { doc, getDoc, setDoc, increment } from 'firebase/firestore';

const DAILY_LIMIT = 5;

export async function checkAndIncrementGenerationLimit(userId: string, checkOnly: boolean = false): Promise<boolean> {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const docId = `${userId}-${today.toISOString().split('T')[0]}`;

  const rateRef = doc(db, 'aiGenerationLimits', docId);
  const rateDoc = await getDoc(rateRef);

  if (!rateDoc.exists()) {
    // First generation of the day
    if (!checkOnly) {
      await setDoc(rateRef, {
        userId,
        date: today,
        count: 1,
      });
    }
    return true;
  }

  const data = rateDoc.data();
  if (data.count >= DAILY_LIMIT) {
    return false;
  }

  // Increment the counter if not just checking
  if (!checkOnly) {
    await setDoc(
      rateRef,
      {
        userId,
        date: today,
        count: increment(1),
      },
      { merge: true }
    );
  }

  return true;
}

export async function getRemainingGenerations(userId: string): Promise<number> {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const docId = `${userId}-${today.toISOString().split('T')[0]}`;

  const rateRef = doc(db, 'aiGenerationLimits', docId);
  const rateDoc = await getDoc(rateRef);

  if (!rateDoc.exists()) {
    return DAILY_LIMIT;
  }

  const data = rateDoc.data();
  return Math.max(0, DAILY_LIMIT - data.count);
}
