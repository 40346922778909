import { createContext, useContext, useCallback } from 'react';
import { useRouter } from 'next/router';
import en from '@/config/i18n/translations/en';

const TranslationContext = createContext();

export function TranslationProvider({ children }) {
  const router = useRouter();
  const { locale } = router;

  const t = useCallback(
    (key, variables = {}) => {
      const keys = key.split('.');
      let value;
      let fallbackValue;

      // Get value from English translations as fallback
      fallbackValue = en;
      for (const k of keys) {
        if (fallbackValue && typeof fallbackValue === 'object') {
          fallbackValue = fallbackValue[k];
        } else {
          fallbackValue = undefined;
          break;
        }
      }

      // Try to load the current locale's translations
      if (locale !== 'en') {
        try {
          const translations = require(`@/config/i18n/translations/${locale}`).default;
          value = translations;

          // Navigate through the nested keys for current locale
          for (const k of keys) {
            if (value && typeof value === 'object') {
              value = value[k];
            } else {
              value = undefined;
              break;
            }
          }
        } catch (error) {
          console.warn(`Translation file for locale ${locale} not found, falling back to English`);
        }
      }

      let result = value || fallbackValue || key;

      // Replace any {{variableName}} with the value from variables
      if (typeof result === 'string' && Object.keys(variables).length > 0) {
        Object.keys(variables).forEach((varName) => {
          const regex = new RegExp(`{{${varName}}}`, 'g');
          result = result.replace(regex, variables[varName]);
        });
      }

      return result;
    },
    [locale]
  );

  return <TranslationContext.Provider value={{ t, locale }}>{children}</TranslationContext.Provider>;
}

export function useTranslation() {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
}
