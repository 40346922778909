import axios from 'axios';
import jsPDF from 'jspdf';
import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { STORAGE_KEYS } from '../config';
import { logEvent } from '../utilities/ga';
import toDataUrl from '../utilities/toDataUrl';
import useStorage from './useStorage';

const CARD_WIDTH_DEFAULT = 66;
const CARD_HEIGHT_DEFAULT = 92;
const MAX_CARDS_PER_PDF = 150;

const PDFContext = createContext(null);
export const usePDF = () => useContext(PDFContext);

export const PDFProvider = ({ children }) => {
  const [padding, setPadding] = useState(false);
  const [format, setFormat] = useState('letter');
  const [scale, setScale] = useState('100%');
  const { storage } = useStorage();
  const [cardWidth, setCardWidth] = useState(CARD_WIDTH_DEFAULT);
  const [cardHeight, setCardHeight] = useState(CARD_HEIGHT_DEFAULT);
  const [rows, setRows] = useState(2);
  const [columns, setColumns] = useState(3);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState({ current: 0, total: 0 });

  const getPaddingSettingFromStorage = async () => {
    const r = await storage.getItem(STORAGE_KEYS.PDF_PADDING);
    if (r) {
      setPadding(r);
    }
  };
  const savePaddingSettingToStorage = async (input) => {
    await storage.setItem(STORAGE_KEYS.PDF_PADDING, input);
  };

  const getFormatSettingFromStorage = async () => {
    const r = await storage.getItem(STORAGE_KEYS.PDF_FORMAT);
    if (r) {
      setFormat(r);
    }
  };
  const saveFormatSettingToStorage = async (input) => {
    await storage.setItem(STORAGE_KEYS.PDF_FORMAT, input);
  };

  const getScaleSettingFromStorage = async () => {
    const r = await storage.getItem(STORAGE_KEYS.PDF_SCALE);
    if (r) {
      setScale(r);
    }
  };
  const saveScaleSettingToStorage = async (input) => {
    await storage.setItem(STORAGE_KEYS.PDF_SCALE, input);
  };

  useEffect(() => {
    if (!storage) return;
    getPaddingSettingFromStorage();
    getFormatSettingFromStorage();
    getScaleSettingFromStorage();
  }, [storage]);

  useEffect(() => {
    if (!storage) return;
    savePaddingSettingToStorage(padding);
  }, [storage, padding]);

  useEffect(() => {
    if (!storage) return;
    saveFormatSettingToStorage(format);
  }, [storage, format]);

  useEffect(() => {
    if (!storage) return;
    if (scale === '100%') {
      setCardWidth(CARD_WIDTH_DEFAULT);
      setCardHeight(CARD_HEIGHT_DEFAULT);
      if (padding) {
        setRows(2);
        setColumns(2);
      } else {
        setRows(3);
        setColumns(3);
      }
    } else if (scale === '110%') {
      setCardWidth(CARD_WIDTH_DEFAULT * 1.1);
      setCardHeight(CARD_HEIGHT_DEFAULT * 1.1);
      setRows(2);
      setColumns(2);
    } else if (scale === '90%') {
      setCardWidth(CARD_WIDTH_DEFAULT * 0.9);
      setCardHeight(CARD_HEIGHT_DEFAULT * 0.9);
      setRows(3);
      setColumns(3);
    }
    saveScaleSettingToStorage(scale);
  }, [storage, scale, padding]);

  const processCardBatch = async (cardBatch, batchIndex, totalBatches) => {
    const images = cardBatch.map((card) => {
      // Handle dual-faced cards
      if (card?.details?.card_faces && card.details.card_faces.length > 0 && card.details.card_faces[0]?.image_uris?.normal) {
        // Return both faces of the card with the same quantity
        return card.details.card_faces.map((face) => ({
          quantity: card?.quantity || 1,
          image: face?.image_uris?.normal,
        }));
      }
      // Handle regular cards
      return {
        quantity: card?.quantity || 1,
        image: card?.details?.image_uris?.normal,
      };
    });

    // Flatten the array since dual-faced cards create nested arrays
    const flattenedImages = images.flat();

    const imagesToPrint = [];
    for (const image of flattenedImages) {
      for (let index = 0; index < image.quantity; index++) {
        imagesToPrint.push(image.image);
      }
    }

    const pdf = new jsPDF({
      unit: 'mm',
      format: format.toLowerCase(),
    });
    const outerPadding = padding ? 6 : 0;
    const imagesPerPage = rows * columns;

    const pagesNeeded = Math.ceil(imagesToPrint.length / imagesPerPage);

    let u = 0;

    for (let p = 0; p < pagesNeeded; p++) {
      if (p > 0) pdf.addPage();
      for (let j = 0; j < rows; j++) {
        for (let i = 0; i < columns; i++) {
          if (u < imagesToPrint.length) {
            try {
              const proxyUrl = `/api/image-proxy?url=${encodeURIComponent(imagesToPrint[u])}`;
              const image = await toDataUrl(proxyUrl);
              const x = i * (cardWidth + outerPadding) + outerPadding;
              const y = j * (cardHeight + outerPadding) + outerPadding;
              pdf.addImage(image, 'PNG', x, y, cardWidth, cardHeight);
              u++;
            } catch (error) {
              u++; // Increment counter even when image fails to load
            }
          }
        }
      }
    }

    const filename = totalBatches > 1 ? `mtg-proxies-part-${batchIndex + 1}-of-${totalBatches}.pdf` : 'mtg-proxies.pdf';

    await pdf.save(filename, {
      returnPromise: true,
    });
  };

  const downloadPDF = async (cards) => {
    setDownloadStatus('pending');

    logEvent({
      action: 'startDownloadPDF',
      params: {
        scale,
        padding,
        format,
      },
    });

    try {
      // Split cards into batches of MAX_CARDS_PER_PDF
      const cardBatches = [];
      for (let i = 0; i < cards.length; i += MAX_CARDS_PER_PDF) {
        cardBatches.push(cards.slice(i, i + MAX_CARDS_PER_PDF));
      }

      setDownloadProgress({ current: 0, total: cardBatches.length });

      // Process each batch
      for (let i = 0; i < cardBatches.length; i++) {
        setDownloadProgress({ current: i + 1, total: cardBatches.length });

        if (i > 0 && cardBatches.length > 1) {
          toast.info(`Generating PDF ${i + 1} of ${cardBatches.length}...`);
        }

        await processCardBatch(cardBatches[i], i, cardBatches.length);
      }

      if (cardBatches.length > 1) {
        toast.success(`Successfully generated ${cardBatches.length} PDF files`);
      } else {
        toast.success('PDF generated successfully');
      }

      logEvent({
        action: 'successDownloadPDF',
        params: {
          scale,
          padding,
          format,
          batches: cardBatches.length,
        },
      });
      setDownloadStatus('success');

      try {
        await axios.post(
          '/api/top-proxies/update',
          {
            cards,
          },
          {
            headers: {
              Authorization: process.env.NEXT_PUBLIC_API_KEY,
            },
          }
        );
      } catch (error) {
        console.error('Error updating top proxies:', error);
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
      toast.error(`Unexpected error. Try again later.`);

      logEvent({
        action: 'errorDownloadPDF',
        params: {
          error,
        },
      });
      setDownloadStatus('error');
      throw error;
    }
  };

  return (
    <PDFContext.Provider
      value={{
        downloadPDF,
        setPadding,
        padding,
        format,
        setFormat,
        scale,
        setScale,
        downloadStatus,
        setDownloadStatus,
        downloadProgress,
      }}
    >
      {children}
    </PDFContext.Provider>
  );
};

export default usePDF;
