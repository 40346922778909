const translations = {
  common: {
    search: 'Поиск',
    signIn: 'Войти',
    register: 'Регистрация',
    myAccount: 'Мой аккаунт',
    support: 'Поддержка',
    followUs: 'Подписывайтесь на нас в X/Twitter',
    proMembership: 'Pro-подписка',
    new: 'Новое',
    proMember: 'Pro-участник',
    upgrade: 'Улучшить',
    subscribe: 'Подписаться',
    manage: 'Управлять',
    cancel: 'Отмена',
    close: 'Закрыть',
    or: 'Или',
    email: 'Электронная почта',
    continue: 'Продолжить',
    submit: 'Отправить',
    billing: 'Оплата',
    sort: 'Сортировка',
    language: 'Язык',
    selectLanguage: 'Выбрать язык',
    searchPlaceholder: 'Поиск...',
    cards: 'Карты',
    previous: 'Предыдущая',
    next: 'Следующая',
    pagination: 'Пагинация',
    back: 'Назад',
    backToSets: 'Назад к сетам',
    error: 'Произошла ошибка. Пожалуйста, попробуйте снова.',
    home: 'Главная',
    sets: 'Сеты',
  },
  theme: {
    light: 'Светлая',
    dark: 'Тёмная',
    toggle: 'Переключить тему',
  },
  cta: {
    title: 'Готовы начать?',
    subtitle: 'Начните создавать прокси карты уже сегодня.',
    searchByCard: 'Поиск по карте',
    searchBySet: 'Поиск по набору',
  },
  nav: {
    proxyCards: 'Прокси-карты',
    sets: 'Сеты',
    tokens: 'Токены',
    emblems: 'Эмблемы',
    import: 'Импорт',
    topProxies: 'Популярные прокси',
    customCards: 'Пользовательские карты',
    deckBuilder: 'Конструктор колод',
    buildDeck: 'Создать колоду',
    publicDecks: 'Публичные колоды',
    favoriteDeck: 'Мои избранные колоды',
    articles: 'Статьи',
  },
  seo: {
    home: {
      title: 'MTG Proxies - Прокси-карты Magic: The Gathering',
      description: 'Получите доступ к обширной библиотеке прокси-карт Magic: The Gathering. Легко просматривайте и печатайте изображения карт MTG для казуальной игры. Создайте колоду своей мечты уже сегодня!',
    },
    sets: {
      title: 'Сеты карт - MTG Proxies',
      description: 'Просматривайте все сеты MTG и печатайте прокси для своей колоды.',
    },
    tokens: {
      title: 'Токены - MTG Proxies',
      description: 'Улучшите свою игру в MTG с различными прокси-токенами.',
    },
    emblems: {
      title: 'Эмблемы - MTG Proxies',
      description: 'Печатайте прокси-карты эмблем MTG для своей колоды.',
    },
    search: {
      title: 'Поиск карт MTG - MTG Proxies',
      description: 'Легко находите и печатайте прокси-карты MTG. Используйте инструменты поиска для доступа к обширной базе данных и улучшайте свои колоды Magic: The Gathering с помощью прокси.',
    },
    support: {
      title: 'Поддержка - MTG Proxies',
      description: 'Отправьте нам письмо, если у вас есть вопросы, отзывы или вы хотите добавить токены/эмблемы.',
    },
    articles: {
      title: 'Статьи - MTG Proxies',
      description: 'Статьи о Magic: The Gathering и прокси-картах.',
    },
    import: {
      title: 'Импорт - MTG Proxies',
      description: 'Импортируйте список карт для печати прокси Magic: The Gathering.',
    },
    topProxies: {
      title: 'Популярные прокси - MTG Proxies',
      description: 'Смотрите самые популярные прокси-карты Magic: The Gathering.',
    },
    customCards: {
      title: 'Пользовательские карты - MTG Proxies',
      description: 'Создавайте свои собственные прокси-карты Magic: The Gathering.',
    },
    login: {
      title: 'Вход - MTG Proxies',
      description: 'Войдите в свой аккаунт для управления пользовательскими картами, списками колод и другим.',
    },
    register: {
      title: 'Регистрация - MTG Proxies',
      description: 'Зарегистрируйте аккаунт для управления пользовательскими картами, списками колод и другим.',
    },
    termsOfService: {
      title: 'Условия использования - MTG Proxies',
      description: 'Прочитайте условия использования сайта.',
    },
    privacyPolicy: {
      title: 'Политика конфиденциальности - MTG Proxies',
      description: 'Прочитайте политику конфиденциальности сайта.',
    },
    account: {
      title: 'Аккаунт - MTG Proxies',
      description: 'Управляйте своим аккаунтом.',
    },
    proMembership: {
      title: 'Pro-подписка - MTG Proxies',
      description: 'Оформите Pro-подписку для доступа ко всем функциям сайта.',
    },
    deckBuilder: {
      title: 'Конструктор колод - MTG Proxies',
      description: 'Создавайте и управляйте своими колодами Magic: The Gathering.',
    },
    deckEditor: {
      title: 'Редактор колод - MTG Proxies',
      description: 'Создавайте и редактируйте свои колоды Magic: The Gathering.',
    },
    publicDecks: {
      title: 'Публичные колоды - MTG Proxies',
      description: 'Просматривайте и исследуйте публичные колоды MTG, которыми поделилось сообщество.',
    },
  },
  pro: {
    pricing: {
      title: 'Разблокируйте Pro создание прокси-карт MTG',
      subtitle: 'Улучшите до Pro-подписки для создания прокси-карт без рекламы и доступа ко всем функциям и преимуществам.',
      monthlyPlan: 'Месячная Pro-подписка',
      yearlyPlan: 'Годовая Pro-подписка',
      monthlyPrice: '$5.00/месяц',
      yearlyPrice: '$40.00/год',
      subscribeMonthly: 'Подписаться на месяц',
      subscribeYearly: 'Подписаться на год',
      signInToSubscribe: 'Войдите для подписки',
      manageSubscription: 'Управление подпиской',
      nextBillingDate: 'Следующая дата оплаты',
      benefitsEndDate: 'Дата окончания преимуществ',
      viewInvoice: 'Посмотреть счёт',
      changePlan: 'Изменить план',
      reactivate: 'Возобновить',
      active: 'Активна',
      cancelled: 'Отменена',
    },
    benefits: {
      title: 'Почему вам нужна Pro-подписка MTG Proxies',
      subtitle: 'Разблокируйте премиум-функции и наслаждайтесь плавным опытом создания прокси-карт с Pro-подпиской.',
      noAds: {
        title: 'Без рекламы',
        description: 'Просматривайте и создавайте прокси без раздражающей рекламы, баннеров и всплывающих окон. Наслаждайтесь чистым, сфокусированным опытом.',
      },
      darkMode: {
        title: 'Тёмная тема',
        description: 'Уменьшите нагрузку на глаза с элегантным тёмным интерфейсом. Идеально подходит для ночного просмотра и условий низкой освещённости.',
      },
      advancedSearch: {
        title: 'Расширенные фильтры поиска',
        description: 'Получите доступ к мощным фильтрам поиска для точного нахождения нужных карт. Фильтруйте по цветовой идентичности, редкости и типам карт.',
      },
      multipleDecks: {
        title: 'Управление несколькими колодами',
        description: 'Создавайте и управляйте несколькими колодами с конструктором колод. Сохраняйте все свои любимые колоды для быстрого доступа.',
      },
      futureFeatures: {
        title: 'Будущие Pro-функции',
        description: 'Получайте мгновенный доступ к новым премиум-функциям при их выпуске. Pro-участники всегда получают доступ первыми.',
      },
      cancelAnytime: {
        title: 'Отмена в любое время',
        description: 'Никаких долгосрочных обязательств. Вы можете гибко отменить подписку в любое время.',
      },
      prioritySupport: {
        title: 'Приоритетная поддержка',
        description: 'Пропустите очередь с выделенной приоритетной поддержкой. Ваши вопросы и проблемы будут рассмотрены первыми с особым вниманием.',
      },
    },
    features: {
      unlimitedDecks: 'Неограниченное создание колод',
      noAds: 'Без рекламы',
      prioritySupport: 'Приоритетная поддержка',
      darkMode: 'Доступ к тёмной теме',
      advancedSearch: 'Расширенные фильтры поиска',
    },
    promotion: {
      title: 'Улучшите до Pro для создания большего количества колод',
      description: 'Pro-участники могут создавать неограниченное количество колод. Улучшите сейчас, чтобы разблокировать эту функцию!',
      customizeTitle: 'Pro-функция: AI кастомизация карт',
      customizeDescription: 'Улучшите до Pro для создания пользовательских вариантов карт с помощью AI. Pro-участники могут генерировать до 5 пользовательских карт в день.',
    },
    account: {
      title: 'Pro-подписка',
      description: 'Управление деталями подписки',
      subscribeToPro: 'Подписаться на Pro',
    },
  },
  auth: {
    signIn: {
      title: 'Войти в свой аккаунт',
      withGoogle: 'Продолжить с Google',
      withEmail: 'Или продолжить с',
      sendMagicLink: 'Отправить магическую ссылку',
      noAccount: 'Нет аккаунта?',
      checkEmail: 'Проверьте свою почту на наличие ссылки для входа',
      errors: {
        failed: 'Ошибка входа',
        failedGoogle: 'Ошибка входа через Google',
        failedEmail: 'Ошибка входа по email',
        noEmail: 'Email не найден',
      },
      terms: 'Входя, вы соглашаетесь с:',
    },
    register: {
      title: 'Создать аккаунт',
      haveAccount: 'Уже есть аккаунт?',
      terms: 'Создавая аккаунт, вы соглашаетесь с:',
    },
    common: {
      termsOfService: 'Условия использования',
      privacyPolicy: 'Политика конфиденциальности',
      and: 'и',
    },
  },
  home: {
    hero: {
      title: 'Прокси',
      rotatingWords: ['карты', 'жетоны', 'эмблемы', 'мироходцы', 'командиры', 'земли', 'существа', 'артефакты', 'чары', 'мгновенные заклинания'],
      description: 'MTG Proxies помогает создавать прокси-карты Magic: The Gathering для использования в ваших казуальных колодах совершенно бесплатно!',
      search: {
        cards: {
          tab: 'Карты',
          placeholder: 'Поиск карты Magic...',
          error: 'Не удалось загрузить случайные карты',
        },
        sets: {
          tab: 'Выпуски',
          placeholder: 'Поиск выпуска Magic...',
        },
        tokens: {
          tab: 'Жетоны',
          placeholder: 'Поиск жетона Magic...',
        },
        button: 'Поиск',
        hint: {
          cards: 'Нажмите Enter или кнопку Поиск, чтобы найти карты',
          sets: 'Нажмите Enter или кнопку Поиск, чтобы найти выпуски',
          tokens: 'Нажмите Enter или кнопку Поиск, чтобы найти жетоны',
        },
        aria: {
          searchIcon: 'Значок поиска',
          searchInput: {
            cards: 'Поиск карт',
            sets: 'Поиск выпусков',
            tokens: 'Поиск жетонов',
          },
        },
      },
      images: {
        cardAlt: 'Изображение карты Magic',
      },
    },
    features: {
      section1: {
        badge: 'Печать Прокси',
        title: 'Печать Прокси-карт MTG',
        subtitle: 'Добавляйте карты в список печати, распечатывайте их и используйте в своих колодах уже сегодня!',
        features: [
          {
            name: 'Добавление карт в список печати',
            description: 'Ищите любую карту через страницу Поиска или просматривайте выпуски и добавляйте нужные карты в список печати.',
          },
          {
            name: 'Печать карт',
            description: 'После добавления всех нужных карт в список печати вы можете распечатать их и использовать в своих колодах.',
          },
          {
            name: 'Использование в колодах',
            description: 'После печати карт вы можете использовать их в своих колодах. Вырежьте их и приклейте на базовую землю или используйте протектор с базовой землей внутри.',
          },
        ],
        images: {
          cartScreenshot: 'Скриншот корзины, показывающий как добавлять карты в список печати',
        },
      },
      section2: {
        badge: 'Конструктор Колод',
        title: 'Создание и управление колодами Magic: The Gathering',
        subtitle: 'Создавайте и управляйте своими колодами или просматривайте публичные колоды, созданные сообществом.',
        features: [
          {
            name: 'Управление колодами MTG',
            description: 'Все пользователи могут создать и управлять одной колодой MTG. Pro-пользователи могут создавать неограниченное количество колод.',
          },
          {
            name: 'Печать карт',
            description: 'После добавления всех нужных карт в вашу колоду вы можете добавить их в Список Печати все сразу.',
          },
          {
            name: 'Просмотр публичных колод',
            description: 'Добавляйте целые колоды, созданные сообществом, в ваш Список Печати для легкого тестирования новых колод.',
          },
        ],
        images: {
          deckBuilderScreenshot: 'Интерфейс Конструктора Колод, показывающий как управлять колодами',
        },
      },
    },
  },
  footer: {
    copyright: 'MTG Proxies, Все права защищены.',
    disclaimer: 'MTG Proxies не производится, не одобряется, не поддерживается и не связан с Wizards of the Coast.',
    affiliate: 'Как партнер-аффилиат TCGplayer, MTG Proxies может получать комиссию за соответствующие покупки.',
    builtBy: 'Создано',
    minnerLabs: 'Minner Labs',
    privacyPolicy: 'Политика конфиденциальности',
    termsOfService: 'Условия использования',
    support: 'Поддержка',
  },
  sort: {
    nameAsc: 'Имя (А до Я)',
    nameDesc: 'Имя (Я до А)',
    cmcAsc: 'Мана-стоимость (по возрастанию)',
    cmcDesc: 'Мана-стоимость (по убыванию)',
    usdAsc: 'Цена (по возрастанию)',
    usdDesc: 'Цена (по убыванию)',
    rarityAsc: 'Редкость (от обычной до мифической)',
    rarityDesc: 'Редкость (от мифической до обычной)',
    releasedDesc: 'Дата выпуска (сначала новые)',
    releasedAsc: 'Дата выпуска (сначала старые)',
  },
  pages: {
    search: {
      heading: 'Поиск',
    },
    sets: {
      heading: 'Сеты карт',
      latestSets: 'Последние сеты',
      upcomingSets: 'Предстоящие сеты',
      allSets: 'Все сеты',
      noResults: 'Результаты не найдены.',
    },
    set: {
      notFound: 'Сет не найден.',
      noCards: 'В этом сете не найдено карт.',
      addAll: 'Добавить {{count}} карт в список печати',
      addedToList: 'Добавлено {{count}} карт в список печати',
    },
    tokens: {
      heading: 'Токены',
    },
    emblems: {
      heading: 'Эмблемы',
    },
    import: {
      heading: 'Импорт карт в список печати',
      description: 'Введите названия всех карт, которые вы хотите добавить в список печати.',
    },
    topProxies: {
      heading: 'Популярные прокси',
      description: 'Вот 50 самых популярных прокси-карт Magic: The Gathering на MTG Proxies.',
    },
  },
  languages: {
    default: 'По умолчанию',
    any: 'Любой',
    english: 'Английский',
    spanish: 'Испанский',
    french: 'Французский',
    german: 'Немецкий',
    italian: 'Итальянский',
    portuguese: 'Португальский',
    japanese: 'Японский',
    korean: 'Корейский',
    russian: 'Русский',
    simplified_chinese: 'Упрощенный китайский',
    traditional_chinese: 'Традиционный китайский',
    hebrew: 'Иврит',
    latin: 'Латинский',
    ancient_greek: 'Древнегреческий',
    arabic: 'Арабский',
    sanskrit: 'Санскрит',
    phyrexian: 'Фирексийский',
    quenya: 'Квенья',
  },
  filters: {
    show: '✨ Показать Pro фильтры',
    hide: '✨ Скрыть Pro фильтры',
  },
  search: {
    showing_start: 'Показано',
    showing_to: 'до',
    showing_of: 'из',
    showing_results: 'результатов',
    noResults: 'Результаты не найдены.',
    useSearchTip: 'Используйте поиск вверху страницы, чтобы найти карту.',
  },
  advancedFilters: {
    colors: 'Цвета',
    rarity: 'Редкость',
    cardTypes: 'Типы карт',
    format: 'Формат',
    manaValue: 'Мана-стоимость',
    cardName: 'Название карты',
    cardText: 'Текст карты',
    specialCharacteristics: 'Особые характеристики',
    activeFilters: 'Активные фильтры',
    clearAll: 'Очистить все',
    applyFilters: 'Применить фильтры',
    searchCardName: 'Поиск по названию карты...',
    searchCardText: 'Поиск по тексту карты...',
    namePrefix: 'Название: ',
    manaPrefix: 'Мана: ',
    textPrefix: 'Текст: ',
    removeFromFilter: '✕',
    selectLanguage: 'Выбрать язык',
  },
  card: {
    addToPrintList: 'Добавить в список печати',
    removeFromPrintList: 'Удалить из списка печати',
    cardsInPrintList: 'карта в списке печати',
    cardsInPrintListPlural: 'карт в списке печати',
    buyOn: 'Купить на TCGplayer',
    buyFor: 'Купить за ',
    viewDetails: 'Подробности',
    type: 'Тип',
    manaCost: 'Стоимость маны',
    rarity: 'Редкость',
    artist: 'Художник',
    collectorNumber: 'Номер коллекционера',
    oracleText: 'Текст оракула',
    youMightAlsoLike: 'Вам также может понравиться...',
    noRecommendations: 'В данный момент нет рекомендаций.',
    details: 'Подробности',
    rulings: 'Правила',
    legality: 'Легальность',
    share: 'Поделиться',
    favorite: 'Избранное',
    noRulings: 'Для этой карты нет правил.',
    noLegalityInfo: 'Для этой карты нет информации о легальности.',
    published: 'Опубликовано',
    legal: 'Легально',
    restricted: 'Ограничено',
    banned: 'Запрещено',
    format: 'Формат',
    status: 'Статус',
    viewMore: 'Показать больше',
    viewLess: 'Показать меньше',
    customizeWithAI: 'Настроить с помощью ИИ',
    otherVersions: 'Уникальные печати',
    showUniqueVersions: 'Показать уникальные печати',
    hideUniqueVersions: 'Скрыть уникальные печати',
  },
  cart: {
    title: 'Список печати',
    closePanel: 'Закрыть панель',
    empty: 'В списке печати нет прокси.',
    addPadding: 'Добавить отступы',
    paperSize: 'Размер бумаги',
    scale: 'Масштаб',
    clearCardList: 'Очистить список',
    print: 'Печать',
    success: 'Успех!',
  },
  setsList: {
    showing: 'Показано',
    to: 'до',
    of: 'из',
    results: 'результатов',
    searchPlaceholder: 'Поиск...',
  },
};

export default translations;
