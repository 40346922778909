import Link from 'next/link';
import React from 'react';
import Container from './Container';
import Logo from './Logo';
import { usePathname } from 'next/navigation';
import { cn } from '../lib/utils';
import { Button } from './ui/button';
import { useTranslation } from '../hooks/useTranslation';

// X/Twitter Icon component
const XIcon = ({ className = '', width = 24 }) => (
  <svg viewBox='0 0 1200 1227' xmlns='http://www.w3.org/2000/svg' width={width} className={className} fill='currentColor'>
    <path
      d='M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z'
      fill='currentColor'
    ></path>
  </svg>
);

export default function Footer() {
  const pathname = usePathname();
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className='bg-gray-900 relative pt-12'>
      <div className='flex justify-center text-gray-50'>
        <Link href='/' passHref>
          <Logo />
        </Link>
      </div>

      <div className='pt-6 pb-8 text-center text-gray-400 text-sm'>
        <Container>
          <p>
            © {currentYear} {t('footer.copyright')}
          </p>
          <p>{t('footer.disclaimer')}</p>
          <p>{t('footer.affiliate')}</p>
          <p>
            {t('footer.builtBy')}{' '}
            <a href='https://minnerlabs.com' className='hover:text-gray-300 transition-colors duration-150'>
              {t('footer.minnerLabs')}
            </a>
            .
          </p>

          <div className='flex justify-center gap-4 mt-4'>
            <div>
              <Button variant='ghost' size='icon' asChild>
                <a href='https://x.com/MTGProxies_' target='_blank' rel='noopener noreferrer' className='group' data-test-id='navbar-link-twitter'>
                  <span className='sr-only'>{t('common.followUs')}</span>
                  <XIcon width={20} />
                </a>
              </Button>
            </div>
          </div>

          <ul className='flex justify-center gap-4 mt-4'>
            <li>
              <Link href='/support' className={cn('hover:text-gray-300 transition-colors duration-150', pathname === '/support' && 'text-gray-300')}>
                {t('footer.support')}
              </Link>
            </li>
            <li>
              <Link href='/privacy-policy' className={cn('hover:text-gray-300 transition-colors duration-150', pathname === '/privacy-policy' && 'text-gray-300')}>
                {t('footer.privacyPolicy')}
              </Link>
            </li>
            <li>
              <Link href='/terms-of-service' className={cn('hover:text-gray-300 transition-colors duration-150', pathname === '/terms-of-service' && 'text-gray-300')}>
                {t('footer.termsOfService')}
              </Link>
            </li>
          </ul>
        </Container>
      </div>
    </div>
  );
}
