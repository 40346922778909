import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, sendSignInLinkToEmail, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, serverTimestamp, deleteDoc, collection, query, orderBy, getDocs, collectionGroup, where, updateDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.appspot.com`,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

export const handleGoogleSignIn = async () => {
  const result = await signInWithPopup(auth, googleProvider);
  const token = await result.user.getIdToken();
  return token;
};

export const handleEmailSignIn = async (email) => {
  const actionCodeSettings = {
    url: `${process.env.NEXT_PUBLIC_APP_URL}/login?finishSignUp=true&email=${email}`,
    handleCodeInApp: true,
  };
  const resp = await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  return resp;
};

export const updateUserProfile = async (userId, data) => {
  try {
    const userRef = doc(db, 'userProfiles', userId);
    await setDoc(userRef, data, { merge: true });
    return true;
  } catch (error) {
    console.error('Error updating user profile:', error);
    return false;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const userRef = doc(db, 'userProfiles', userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error getting user profile:', error);
    return null;
  }
};

// Favorites functionality
export const addDeckToFavorites = async (userId, deckId, deckData) => {
  try {
    // Create a document in the userFavorites collection
    const favoriteRef = doc(db, 'userFavorites', userId, 'favoriteDecks', deckId);
    await setDoc(favoriteRef, {
      addedAt: serverTimestamp(),
      deckId,
      deckName: deckData.name,
      deckFormat: deckData.format,
      authorId: deckData.userId,
      authorName: deckData.createdBy || 'Anon',
    });

    // Also update the deck document with a counter
    const deckRef = doc(db, 'decks', deckId);
    const deckSnap = await getDoc(deckRef);

    if (deckSnap.exists()) {
      // Increment the favoritesCount field if it exists, or set it to 1 if it doesn't
      const currentCount = deckSnap.data().favoritesCount || 0;
      await updateDoc(deckRef, {
        favoritesCount: currentCount + 1,
      });
    }

    return true;
  } catch (error) {
    console.error('Error adding deck to favorites:', error);
    return false;
  }
};

export const removeDeckFromFavorites = async (userId, deckId) => {
  try {
    const favoriteRef = doc(db, 'userFavorites', userId, 'favoriteDecks', deckId);
    await deleteDoc(favoriteRef);

    // Also update the deck document with a counter
    const deckRef = doc(db, 'decks', deckId);
    const deckSnap = await getDoc(deckRef);

    if (deckSnap.exists()) {
      // Decrement the favoritesCount field, ensuring it doesn't go below 0
      const currentCount = deckSnap.data().favoritesCount || 0;
      await updateDoc(deckRef, {
        favoritesCount: Math.max(0, currentCount - 1),
      });
    }

    return true;
  } catch (error) {
    console.error('Error removing deck from favorites:', error);
    return false;
  }
};

export const checkIfDeckIsFavorite = async (userId, deckId) => {
  try {
    const favoriteRef = doc(db, 'userFavorites', userId, 'favoriteDecks', deckId);
    const favoriteSnap = await getDoc(favoriteRef);
    return favoriteSnap.exists();
  } catch (error) {
    console.error('Error checking if deck is favorite:', error);
    return false;
  }
};

export const getUserFavoriteDecks = async (userId) => {
  try {
    const favoritesRef = collection(db, 'userFavorites', userId, 'favoriteDecks');
    const favoritesQuery = query(favoritesRef, orderBy('addedAt', 'desc'));
    const querySnapshot = await getDocs(favoritesQuery);

    const favoriteDecks = [];
    querySnapshot.forEach((doc) => {
      favoriteDecks.push({
        id: doc.id,
        ...doc.data(),
        addedAt: doc.data().addedAt?.toDate() || new Date(),
      });
    });

    return favoriteDecks;
  } catch (error) {
    console.error('Error getting user favorite decks:', error);
    return [];
  }
};

// Get the total favorites count for a deck
export const getDeckFavoritesCount = async (deckId) => {
  try {
    // Get the deck document which has the count stored directly
    const deckRef = doc(db, 'decks', deckId);
    const deckSnap = await getDoc(deckRef);

    if (deckSnap.exists()) {
      // Return the favoritesCount field or 0 if it doesn't exist
      return deckSnap.data().favoritesCount || 0;
    }

    return 0;
  } catch (error) {
    console.error('Error counting deck favorites:', error);
    return 0;
  }
};
