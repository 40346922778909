export default function FormSelect({ label, options, value, setValue }) {
  const id = label?.toLowerCase().replace(' ', '-');

  return (
    <div className='flex items-center space-x-3'>
      <label htmlFor={id} className='block text-sm font-medium text-gray-700 dark:text-gray-300 whitespace-nowrap'>
        {label}
      </label>
      <select
        id={id}
        name={id}
        className='block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm dark:bg-gray-900 dark:text-gray-400 dark:border-gray-700'
        defaultValue={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      >
        {options.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      </select>
    </div>
  );
}
