import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import Badge from './Badge';
import { Button } from './ui/button';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useTranslation } from '@/hooks/useTranslation';

export default function MobileDrawer({ active, setActive }) {
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    setActive(false);
  }, [router]);

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 overflow-hidden z-[1000]' onClose={setActive}>
        <div className='absolute inset-0 overflow-hidden'>
          <Dialog.Overlay className='absolute inset-0' />

          <div className='fixed inset-y-0 right-0 flex max-w-full'>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className='w-screen max-w-md'>
                <div className='flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl dark:bg-gray-900'>
                  <div className='px-4 sm:px-6'>
                    <div className='flex items-start justify-between'>
                      <Dialog.Title className='text-lg font-medium text-gray-900'></Dialog.Title>
                      <div className='flex items-center ml-3 h-7'>
                        <Button type='button' variant='ghost' size='icon' onClick={() => setActive(false)}>
                          <span className='sr-only'>{t('common.close')}</span>
                          <XIcon className='w-6 h-6' aria-hidden='true' />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='relative'>
                    <nav className='px-8'>
                      <ul className='flex flex-col space-y-8'>
                        <li>
                          <Link href='/search' className={`${router.pathname == '/search' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('common.search')}
                          </Link>
                        </li>
                        <li>
                          <Link href='/sets' className={`${router.pathname == '/sets' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('nav.sets')}
                          </Link>
                        </li>
                        <li>
                          <Link href='/tokens' className={`${router.pathname == '/tokens' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('nav.tokens')}
                          </Link>
                        </li>
                        <li>
                          <Link href='/emblems' className={`${router.pathname == '/emblems' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('nav.emblems')}
                          </Link>
                        </li>
                        <li>
                          <Link href='/articles' className={`${router.pathname == '/articles' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('nav.articles')}
                          </Link>
                        </li>
                        <li className='flex items-centers gap-2'>
                          <Link href='/import' className={`${router.pathname == '/import' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('nav.import')}
                          </Link>
                        </li>
                        <li className='flex items-centers gap-2'>
                          <Link href='/pro-membership' className={`${router.pathname == '/pro-membership' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('common.proMembership')}
                          </Link>
                          <Badge>{t('common.new')}</Badge>
                        </li>
                        {process.env.NEXT_PUBLIC_DECK_LISTS_LIVE === 'true' && (
                          <li className='flex items-centers gap-2'>
                            <Link href='/deck-builder' className={`${router.pathname == '/deck-builder' ? 'text-green-700' : ''} font-medium text-xl`}>
                              {t('nav.deckBuilder')}
                            </Link>
                            <Badge>{t('common.new')}</Badge>
                          </li>
                        )}
                        {process.env.NEXT_PUBLIC_CUSTOM_CARDS_LIVE === 'true' && (
                          <li className='flex items-centers gap-2'>
                            <Link href='/custom-cards' className={`${router.pathname == '/custom-cards' ? 'text-green-700' : ''} font-medium text-xl`}>
                              {t('nav.customCards')}
                            </Link>
                            <Badge>{t('common.new')}</Badge>
                          </li>
                        )}
                        <li className='flex items-centers gap-2'>
                          <Link href='/top-proxies' className={`${router.pathname == '/top-proxies' ? 'text-green-700' : ''} font-medium text-xl`}>
                            {t('nav.topProxies')}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
