import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export default function ActivityIndicator({ size = 75 }) {
  // Convert string sizes to numbers
  let sizeValue = size;
  if (size === 'small') {
    sizeValue = 24;
  } else if (size === 'medium') {
    sizeValue = 50;
  }

  return <ClipLoader color={'#A8F3D1'} loading={true} size={sizeValue} />;
}
